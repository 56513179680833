import React from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const DynamicCanonical: React.FC = () => {
  const location = useLocation();
  // Construct the canonical URL. Adjust if you have query parameters you wish to omit.
  const canonicalUrl = `https://yournidra.com${location.pathname}`;
  
  return (
    <Helmet>
      <link rel="canonical" href={canonicalUrl} />
    </Helmet>
  );
};

export default DynamicCanonical;