import React, { useState } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import { format } from 'date-fns';
import { cancelSleepStudy } from '../../../api/user/SleepStudyApi';
import { toast } from 'react-toastify';
import { uploadSleepStudyReport } from '../services/SleepCenterService';

interface UpdateStatusModalProps {
    id: string;
    isOpen: boolean;
    onClose: () => void;
    currentStatus: string;
    currentDate?: string;
    onStatusUpdate: (newStatus: string, newDate?: string, reportUrl?: string) => Promise<void>;
    onCancel?: () => Promise<void>;
}

const UpdateStatusModal: React.FC<UpdateStatusModalProps> = ({
    id,
    isOpen,
    onClose,
    currentStatus,
    currentDate,
    onStatusUpdate,
    onCancel,
}) => {
    const [selectedDate, setSelectedDate] = useState<Date | null>(() => {
        if (!currentDate) return null;
        try {
            const date = new Date(currentDate);
            return isNaN(date.getTime()) ? null : date;
        } catch {
            return null;
        }
    });
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [isLoading, setIsLoading] = useState(false);

    const handleCancel = async () => {
        if (!window.confirm('Are you sure you want to cancel this sleep study?')) {
            return;
        }

        try {
            setIsLoading(true);
            await cancelSleepStudy(id);
            onClose();
            toast.success('Sleep study cancelled successfully');
        } catch (error) {
            console.error('Error canceling study:', error);
            toast.error('Failed to cancel sleep study');
        } finally {
            setIsLoading(false);
        }
    };

    const handleUpload = async () => {
        if (!selectedFile) {
            toast.error('Please select a report to upload.');
            return;
        }
        try {
            setIsLoading(true);
            const response = await uploadSleepStudyReport(id, selectedFile);
            if (response.status === 200) {
                await onStatusUpdate('Completed');
                onClose();
                toast.success('Sleep study report uploaded successfully');
            } else {
                toast.error('Failed to upload study report');
            }
        } catch (error: any) {
            toast.error(error.response?.data?.message || 'Failed to upload report');
            console.error('Error uploading report:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const formatDisplayDate = (dateString: string) => {
        if (!dateString) return 'To be finalised';
        try {
            return format(new Date(dateString), 'do MMMM yyyy');
        } catch {
            return 'To be finalised';
        }
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white rounded-lg p-6 w-full max-w-md">
                <h3 className="text-lg font-semibold text-gray-900 mb-4">
                    {currentStatus === 'Request for Schedule' ? 'Schedule Study' : 'Upload Report'}
                </h3>

                <div className="space-y-4">
                    {currentStatus === 'Request for Schedule' && (
                        <div className="space-y-4">
                            <div className="bg-gray-50 p-4 rounded-lg border border-gray-200">
                                <div className="flex items-center gap-4">
                                    <label className="text-sm font-medium text-gray-700 min-w-[120px]">
                                        Requested Date:
                                    </label>
                                    {currentDate && currentDate !== 'No date' ? (
                                        <div className="text-sm text-gray-900">
                                            {formatDisplayDate(currentDate)}
                                        </div>
                                    ) : (
                                        <input
                                            type="date"
                                            value={selectedDate ? format(selectedDate, 'yyyy-MM-dd') : ''}
                                            onChange={(e) => setSelectedDate(e.target.value ? new Date(e.target.value) : null)}
                                            className="border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-darkGreen"
                                            min={format(new Date(), 'yyyy-MM-dd')}
                                        />
                                    )}
                                </div>
                            </div>
                            <div className="flex justify-end gap-3 mt-6">
                                <button onClick={handleCancel} className="px-4 py-2 text-red-600 border border-red-600 rounded-md hover:bg-red-50" disabled={isLoading}>Cancel Study</button>
                                <button onClick={onClose} className="px-4 py-2 text-gray-700 border border-gray-300 rounded-md hover:bg-gray-50" disabled={isLoading}>Close</button>
                                <button onClick={async () => {
                                    if (!selectedDate) return;
                                    try {
                                        setIsLoading(true);
                                        await onStatusUpdate('Scheduled', selectedDate.toISOString());
                                        onClose();
                                    } catch (error) {
                                        console.error('Error scheduling study:', error);
                                    } finally {
                                        setIsLoading(false);
                                    }
                                }} className="px-4 py-2 bg-darkGreen text-white rounded-md hover:bg-darkGreen-light disabled:opacity-50" disabled={isLoading || (!currentDate && !selectedDate)}>Schedule</button>
                            </div>
                        </div>
                    )}
                    {currentStatus === 'Scheduled' && (
                        <div className="space-y-4">
                            <div className="bg-gray-50 p-4 rounded-lg border border-gray-200">
                                <label className="block text-sm font-medium text-gray-700 mb-3">Upload Sleep Study Report</label>
                                <input type="file" className="border border-gray-300 rounded-md px-3 py-2 w-full" onChange={(e) => setSelectedFile(e.target.files?.[0] || null)} accept=".pdf,.doc,.docx" />
                            </div>
                            <div className="flex justify-end gap-3 mt-6">
                                <button onClick={handleCancel} className="px-4 py-2 text-red-600 border border-red-600 rounded-md hover:bg-red-50" disabled={isLoading}>Cancel Study</button>
                                <button onClick={onClose} className="px-4 py-2 text-gray-700 border border-gray-300 rounded-md hover:bg-gray-50" disabled={isLoading}>Close</button>
                                <button onClick={handleUpload} className="px-4 py-2 bg-darkGreen text-white rounded-md hover:bg-darkGreen-light disabled:opacity-50" disabled={isLoading || !selectedFile}>Complete</button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default UpdateStatusModal;
