// frontend/features/distributor/components/OrderDetail.tsx
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getOrderById } from '../services/OrderService';
import { Order } from '../types/OrderTypes';
import DistributorLayout from './DistributorLayout';
import { format } from 'date-fns';

const OrderDetail: React.FC = () => {
    const { orderId } = useParams<{ orderId: string }>();
    const [order, setOrder] = useState<Order | null>(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchOrder = async () => {
            try {
                const orderData = await getOrderById(orderId!); // '!' asserts orderId is not undefined
                setOrder(orderData);
            } catch (err: any) {
                setError(err.message || 'Failed to fetch order details');
            } finally {
                setLoading(false);
            }
        };

        if (orderId) {
            fetchOrder();
        }
    }, [orderId]);

    if (loading) {
        return <DistributorLayout><div>Loading order details...</div></DistributorLayout>;
    }

    if (error) {
        return <DistributorLayout><div>Error: {error}</div></DistributorLayout>;
    }

    if (!order) {
        return <DistributorLayout><div>Order not found.</div></DistributorLayout>;
    }

    return (
        <DistributorLayout>
            <div className="p-6 bg-white rounded-lg shadow-md">
                <h2 className="text-2xl font-bold mb-4">Order Details</h2>
                <p className="mb-4">Order Number: {order.orderNumber}</p>

                <div className="grid grid-cols-2 gap-4">
                    <div>
                        <h3 className="font-semibold">Customer ID:</h3>
                        <p>{order.userId}</p> {/*  Display user ID.  Ideally, fetch and show user details */}
                    </div>
                    <div>
                        <h3 className="font-semibold">Order Date:</h3>
                        <p>{format(new Date(order.createdAt), 'PPP')}</p>
                    </div>
                    <div>
                        <h3 className="font-semibold">Status:</h3>
                        <p>{order.status}</p>
                    </div>
                    <div>
                        <h3 className="font-semibold">Total Amount:</h3>
                        <p>₹{order.totalAmount.toFixed(2)}</p>
                    </div>
                    <div>
                        <h3 className="font-semibold">Shipping Address:</h3>
                        <p>
                            {order.shippingAddress.street}, {order.shippingAddress.city},{' '}
                            {order.shippingAddress.state} {order.shippingAddress.postalCode},{' '}
                            {order.shippingAddress.country}
                        </p>
                    </div>
                    {order.paymentMethod && (<div>
                        <h3 className="font-semibold">Payment Method:</h3>
                        <p>{order.paymentMethod}</p>
                    </div>
                    )}
                    {order.paymentStatus && (<div>
                        <h3 className="font-semibold">Payment Status:</h3>
                        <p>{order.paymentStatus}</p>
                    </div>
                    )}
                    {order.transactionId && (<div>
                        <h3 className="font-semibold">Transaction ID:</h3>
                        <p>{order.transactionId}</p>
                    </div>
                    )}

                    {order.trackingNumber && (<div>
                        <h3 className="font-semibold">Tracking Number:</h3>
                        <p>{order.trackingNumber}</p>
                    </div>
                    )}
                </div>

                <h3 className="font-semibold mt-6 mb-2">Items:</h3>
                <ul>
                    {order.items.map((item, index) => (
                        <li key={index} className="mb-2">
                            {item.quantity} x {item.productName} - ₹{item.price.toFixed(2)}
                        </li>
                    ))}
                </ul>
                {order.notes && (<>
                    <h3 className='font-semibold mt-6 mb-2'>Notes:</h3>
                    <p>{order.notes}</p>
                </>)}
            </div>
        </DistributorLayout>
    );
};

export default OrderDetail;