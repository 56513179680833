// src/api/admin/AdminBlogApi.ts (New or existing admin-specific API file)
import axiosInstance from '../AxiosInstance';
import { BlogListResponse, BlogPost } from '../../features/blogs/types/BlogTypes'; // Use existing types

interface AdminBlogListParams {
    page?: number;
    limit?: number;
    status?: 'draft' | 'published' | 'archived'; // Optional status filter
    sortBy?: string; // e.g., 'createdAt', 'publishedDate'
    sortOrder?: 'asc' | 'desc';
}

/**
 * Fetches a list of all blog posts (including drafts) for admin view.
 * Supports pagination and filtering.
 */
export const getAdminBlogsList = (params: AdminBlogListParams = {}) => {
    // Note: The endpoint '/blogs/admin/all' is an assumption. Adjust if needed.
    return axiosInstance.get<BlogListResponse>('/blogs/admin/all', { params });
};

/**
 * Fetches a single blog post by its ID (regardless of status) for editing.
 */
export const getAdminBlogPostById = (blogId: string) => {
    // Note: The endpoint '/blogs/admin/:id' is an assumption. Adjust if needed.
    return axiosInstance.get<BlogPost>(`/blogs/admin/${blogId}`);
};

// Add functions for create, update, delete if keeping them separate
export const postAdminBlogPost = (blogFormData: FormData) => {
     return axiosInstance.post('/blogs', blogFormData); // Uses the main create endpoint
}

export const putAdminBlogPost = (blogId: string, blogFormData: FormData) => {
     return axiosInstance.put(`/blogs/${blogId}`, blogFormData); // Uses the main update endpoint
}

export const deleteAdminBlogPost = (blogId: string) => {
     return axiosInstance.delete(`/blogs/${blogId}`); // Uses the main delete endpoint
}