// features/sleepCenter/components/SleepCenterPatients.tsx
import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import SleepCenterLayout from '../SleepCenterLayout';
import { fetchSleepCenterAllSleepStudies, unassignPatient } from '../services/SleepCenterService';
import { SleepStudyData } from '../../user/sleepStudy/types/UserSleepStudyTypes';
import { format } from 'date-fns';
import AddPatientModal from './SleepCenterPatientModal';
import { toast } from 'react-toastify';
import ConfirmationModal from '../../../components/ConfirmationModal';

interface Patient {
    _id: string;
    firstName: string;
    lastName: string;
    sleepStudies: SleepStudyData[];
}

const SleepCenterPatients: React.FC = () => {
    const [patients, setPatients] = useState<Patient[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [showAddPatientModal, setShowAddPatientModal] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [selectedPatientId, setSelectedPatientId] = useState<string | null>(null);
    const navigate = useNavigate();
    const sleepCenterId = localStorage.getItem('sleepCenterId') || '';

    const fetchPatients = useCallback(async () => {
        try {
            setLoading(true);
            const response = await fetchSleepCenterAllSleepStudies(sleepCenterId);

            // Check if response is an array directly
            const sleepStudies = Array.isArray(response) ? response : [];
            
            if (sleepStudies.length === 0) {
                setPatients([]);
                return;
            }

            // Get all unique patients from sleep studies
            const uniquePatients = new Map();
            sleepStudies.forEach((study: SleepStudyData) => {
                if (study.userId && study.userId._id) {
                    uniquePatients.set(study.userId._id, {
                        _id: study.userId._id,
                        firstName: study.userId.firstName,
                        lastName: study.userId.lastName,
                        sleepStudies: []
                    });
                }
            });

            // Add sleep studies to each patient
            sleepStudies.forEach((study: SleepStudyData) => {
                if (study.userId && study.userId._id) {
                    const patient = uniquePatients.get(study.userId._id);
                    if (patient) {
                        patient.sleepStudies.push(study);
                    }
                }
            });

            const formattedPatients = Array.from(uniquePatients.values())
                .map(patient => ({
                    ...patient,
                    sleepStudies: patient.sleepStudies.sort((a: SleepStudyData, b: SleepStudyData) => 
                        new Date(b.date).getTime() - new Date(a.date).getTime()
                    )
                }));
            setPatients(formattedPatients);
            setError(null);
        } catch (err: any) {
            console.error('Error fetching patients:', err);
            setError(err.message || 'Failed to fetch patients.');
            setPatients([]);
        } finally {
            setLoading(false);
        }
    }, [sleepCenterId]);

    useEffect(() => {
        fetchPatients();
    }, [fetchPatients]);

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(e.target.value);
    };

    const filteredPatients = patients.filter(patient => {
        const fullName = `${patient.firstName} ${patient.lastName}`.toLowerCase();
        return fullName.includes(searchQuery.toLowerCase());
    });

    const handleUnassignClick = (patientId: string) => {
        setSelectedPatientId(patientId);
        setShowConfirmModal(true);
    };

    const handleUnassignConfirm = async () => {
        if (!selectedPatientId) return;
        
        try {
            await unassignPatient(sleepCenterId, selectedPatientId);
            setPatients(prevPatients => 
                prevPatients.filter(patient => patient._id !== selectedPatientId)
            );
            toast.success('Patient unassigned successfully');
        } catch (error) {
            console.error('Error unassigning patient:', error);
            toast.error('Failed to unassign patient');
        }
        setSelectedPatientId(null);
    };

    const formatDate = (dateString: string | undefined) => {
        if (!dateString) return 'No date';
        try {
            const date = new Date(dateString);
            if (isNaN(date.getTime())) return 'Invalid date';
            return format(date, 'MMM dd, yyyy');
        } catch {
            return 'Invalid date';
        }
    };

    if (loading) {
        return (
            <SleepCenterLayout>
                <div className="flex justify-center items-center h-full">
                    <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-darkGreen"></div>
                </div>
            </SleepCenterLayout>
        );
    }

    if (error) {
        return (
            <SleepCenterLayout>
                <div className="text-center text-red-600 p-4">
                    Error: {error}
                </div>
            </SleepCenterLayout>
        );
    }

    return (
        <SleepCenterLayout>
            <div className="p-6 bg-white rounded-lg shadow-md">
                <div className="mb-6">
                    <div className="flex justify-between items-center">
                        <h2 className="text-2xl font-bold text-gray-800">
                            Manage Patients
                        </h2>
                        <button
                            onClick={() => setShowAddPatientModal(true)}
                            className="bg-darkGreen hover:bg-darkGreen-light text-white px-4 py-2 rounded-lg transition-colors duration-200 flex items-center">
                            Add Patient
                        </button>
                    </div>
                    
                    <div className="mt-4">
                        <input
                            type="text"
                            placeholder="Search patients by name"
                            value={searchQuery}
                            onChange={handleSearchChange}
                            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-darkGreen"
                        />
                    </div>
                </div>

                {filteredPatients.length > 0 ? (
                    <div className="overflow-x-auto">
                        <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                                <tr>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Name
                                    </th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Last Study
                                    </th>
                                    <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Actions
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                                {filteredPatients.map(patient => (
                                    <tr 
                                        key={patient._id} 
                                        className="hover:bg-gray-50 cursor-pointer"
                                        onClick={() => navigate(`/sleep-center/patient/${patient._id}`)}
                                    >
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            <div className="text-sm font-medium text-gray-900">
                                                {patient.firstName} {patient.lastName}
                                            </div>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                            {formatDate(patient.sleepStudies.length > 0 ? patient.sleepStudies[0].date : undefined)}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-right">
                                            <button
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleUnassignClick(patient._id);
                                                }}
                                                className="text-sm text-red-600 hover:text-red-800 font-medium"
                                            >
                                                Unassign
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                ) : (
                    <div className="text-center py-8">
                        <p className="text-gray-500 text-lg">
                            No patients found
                        </p>
                    </div>
                )}
            </div>

            {showAddPatientModal && (
                <AddPatientModal
                    onClose={() => setShowAddPatientModal(false)}
                    onPatientAdded={() => {
                        setShowAddPatientModal(false);
                        fetchPatients();
                    }}
                />
            )}

            <ConfirmationModal
                isOpen={showConfirmModal}
                onClose={() => setShowConfirmModal(false)}
                onConfirm={handleUnassignConfirm}
                title="Unassign Patient"
                message="Are you sure you want to unassign this patient?"
            />
        </SleepCenterLayout>
    );
};

export default SleepCenterPatients;