// features/auth/sleepCenter/services/SleepCenterService.ts
import axiosInstance from '../../../api/AxiosInstance';
import { SleepCenterLoginFormData, SleepCenterRegisterFormData, SleepCenter } from '../types/SleepCenterTypes';

export const registerSleepCenter = async (formData: SleepCenterRegisterFormData) => {
    try {
        const response = await axiosInstance.post('/sleep-center/register', formData);
        return response;
    } catch (error: any) {
        throw error;
    }
};

export const loginSleepCenter = async (formData: SleepCenterLoginFormData) => {
    try {
        const response = await axiosInstance.post('/sleep-center/login', formData);
        return response;
    } catch (error: any) {
        throw error;
    }
};

export const fetchSleepCenterDetails = async (sleepCenterId: string): Promise<SleepCenter> => {
    try {
        const response = await axiosInstance.get(`/sleep-center/${sleepCenterId}`);
        return response.data.sleepCenter;
    } catch (error) {
        console.error('Error fetching sleep center details:', error);
        throw error;
    }
};

export const updateSleepCenterDetails = async (sleepCenterId: string, updatedData: any) => {
    try {
        const response = await axiosInstance.put(`/sleep-center/update/${sleepCenterId}`, updatedData);
        return response;
    } catch (error: any) {
        throw error;
    }
};

export const addOfferings = async (sleepCenterId: string, offerings: any) => {
    const token = localStorage.getItem('sleepCenterToken');
    try {
        const response = await axiosInstance.put(`/sleep-center/offerings/${sleepCenterId}`, { offerings }, {
            headers: { Authorization: `Bearer ${token}` }
        });
        return response;
    } catch (error: any) {
        throw error;
    }
};

export const fetchSleepCenterAllSleepStudies = async (sleepCenterId: string) => {
    try {
        const response = await axiosInstance.get(`/sleep-center/sleep-studies/${sleepCenterId}`);
        return response.data;
    } catch (error: any) {
        throw error;
    }
};

export const uploadSleepStudyReport = async (sleepStudyId: string, report: File) => {
    try {
         const formData = new FormData();
          formData.append('report', report);
        const response = await axiosInstance.post(`/sleep-center/${sleepStudyId}/upload`, formData, {
            headers: {
             'Content-Type': 'multipart/form-data',
            }
          });
        return response;
    } catch (error) {
        throw error;
    }
};

export const forgotPasswordRequest = async (email: string) => {
    try {
        const response = await axiosInstance.post('/sleep-center/forgot-password', { email });
        return response;
    } catch (error: any) {
        throw error;
    }
};

export const fetchAvailableSleepCenters = async () => {
    try {
        const response = await axiosInstance.get('/sleep-center/all');
        return response.data;
    } catch (error: any) {
        console.error('Error fetching sleep centers:', error);
        throw error;
    }
};

export const addUser = async (patientData: any) => {
    const sleepCenterId = localStorage.getItem('sleepCenterId');
    try {
        const response = await axiosInstance.post(
            `/sleep-center/${sleepCenterId}/user`,
            patientData,
        );
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const checkExistingUser = async (mobile: string) => {
    try {
        const response = await axiosInstance.get(`/user/check-mobile/${mobile}`);
        return response;
    } catch (error) {
        throw error;
    }
};

export const createUser = async (userData: any) => {

    try {
        const response = await axiosInstance.post('/user/create', userData);
        return response;
    } catch (error) {
        throw error;
    }
};

export const assignUserToSleepCenter = async (userId: string, sleepCenterId: string) => {
    try {
        const response = await axiosInstance.put(
            `/sleep-center/${sleepCenterId}/assign-patient/${userId}`,
        );
        return response;
    } catch (error) {
        throw error;
    }
};

export const addSleepStudy = async (studyData: any) => {
    const sleepCenterId = localStorage.getItem('sleepCenterId');

    try {
        const response = await axiosInstance.post(
            `/sleep-center/${sleepCenterId}/sleep-study`,
            studyData,
        );
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const updateSleepCenterOfferings = async (sleepCenterId: string, offerings: any[]) => {
    try {
        const response = await axiosInstance.put(
            `/sleep-center/${sleepCenterId}/offerings`,
            { offerings },
        );
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const checkUserExists = async (phoneNumber: string) => {
    try {
        const response = await axiosInstance.get(`/user/check-mobile/${phoneNumber}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const unassignPatient = async (sleepCenterId: string, userId: string) => {
    try {
        const response = await axiosInstance.post(
            '/sleep-center/unassign-patient',
            {
                sleepCenterId,
                userId
            },
        );
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const fetchPatientDetails = async (userId: string) => {
    try {
        const response = await axiosInstance.get(`/sleep-center/user/${userId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};