// Admin User Service: features/admin/services/AdminUserService.ts
import { RegisterFormData, LoginFormData, Admin } from '../types/AdminUserTypes';
import { login, register, forgotPassword, resetPassword } from '../../../api/auth/AdminAuthApi';
import axiosInstance from '../../../api/AxiosInstance';

export const loginAdmin = async (formData: LoginFormData) => {
    const response = await login(formData);
    
    const { token, admin } = response.data;
    // Perform localStorage operations or other post-API logic
    localStorage.setItem('token', token);
    localStorage.setItem('id', admin._id);
    localStorage.setItem('isAdminLoggedIn', 'true');
    // ... other storage operations
    
    return response;
};

export const registerAdmin = async (formData: RegisterFormData) => {
    const response = await register(formData);
    return response;
};


export const forgotPasswordRequest = async (email: string) => {
    return forgotPassword(email);
};

export const requestPasswordReset = async (token: string, password: string) => {
    return resetPassword(token, password);
};

export const getAllUsers = async (): Promise<Admin[]> => {
  // Simulate API call
  return [
    { id: '1', firstName: 'John Doe', lastName: 'Doe', email: 'john@example.com', phoneNumber: '1234567890' },
    { id: '2', firstName: 'Jane Smith', lastName: 'Smith', email: 'jane@example.com', phoneNumber: '0987654321' },
  ];
};

export const updateUser = async (userId: string, data: Admin): Promise<void> => {
  // Simulate API call to update user
  console.log(`Updating user ${userId}`, data);
};

export const deleteUser = async (userId: string): Promise<void> => {
  // Simulate API call to delete user
  console.log(`Deleting user ${userId}`);
};

export const createUser = async (userData: Admin) => {
    const response = await axiosInstance.post('/users', userData);
    return response;
};