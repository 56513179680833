import React from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
// Remove the JSON import as it's deleted
// import blogEntries from './features/blogs/components/BlogEntries.json';
import FAQStructuredData from './features/user/faq/components/FAQStructuredData';
import OrganizationStructuredData from './features/shared/OrganizationStructuredData';
import MedicalConditionStructuredData from './features/shared/MedicalConditionStructuredData';

// Keep WebSite type definition if needed elsewhere, or remove if only used for blogs
interface WebSite {
  '@context': 'https://schema.org';
  '@type': 'WebSite';
  name: string;
  url: string;
  potentialAction: {
    '@type': 'SearchAction';
    target: string;
    'query-input': string;
  };
}



// --- Union Type - Can be simplified if only WebSite is generated here ---
// type StructuredDataType = WebSite | BlogPosting;
type StructuredDataType = WebSite; // Simplified

const StructuredData: React.FC = () => {
  const location = useLocation();
  const { pathname } = location;

  // Default to WebSite schema
  let structuredData: StructuredDataType = {
    '@context': 'https://schema.org',
    '@type': 'WebSite',
    name: 'YourNidra',
    url: 'https://yournidra.com',
    potentialAction: {
      '@type': 'SearchAction',
      target: 'https://yournidra.com/search?q={search_term_string}', // Update if you have search
      'query-input': 'required name=search_term_string',
    },
  };



  return (
    <Helmet>
      {/* Always render the generic WebSite schema */}
      <script type="application/ld+json">{JSON.stringify(structuredData)}</script>

      {/* Keep FAQ schema generation for the FAQ page */}
      {pathname === '/user/faq' && (
        <FAQStructuredData
          faqData={[
            {
              question: 'What is sleep apnea?',
              answer:
                'Sleep apnea is a common sleep disorder characterized by repeated interruptions in breathing during sleep.',
            },
            {
              question: 'What are the common symptoms of sleep apnea?',
              answer:
                'Common symptoms include loud snoring, gasping for air during sleep, daytime fatigue, and morning headaches.',
            },
            {
              question: 'How is sleep apnea diagnosed?',
              answer:
                'Sleep apnea is typically diagnosed through a sleep study, either at home or in a sleep lab.',
            },
            {
              question: 'What are the treatment options for sleep apnea?',
              answer:
                'Treatment options include lifestyle changes, CPAP therapy, oral appliances, and in some cases, surgery.',
            },
            {
              question: 'How can I schedule a consultation with a doctor?',
              answer:
                "You can schedule a consultation through the 'Consult' section of your user dashboard.",
            },
            // Add more relevant FAQs if needed
          ]}
        />
      )}

      {/* Keep Organization schema */}
      <OrganizationStructuredData
        name="YourNidra"
        url="https://yournidra.com"
        logo="https://yournidra.com/logo.png"
        address={{
          streetAddress: 'Landmark Avenue',
          addressLocality: 'Gurugram',
          addressRegion: 'HR',
          postalCode: '122002',
          addressCountry: 'IN',
        }}
        email="hello@yournidra.com"
        telephone="+91 9643727987"
        sameAs={[
          'https://www.facebook.com/yournidra/',
          'https://x.com/yournidra',
          'https://www.instagram.com/yournidra/',
          'https://www.linkedin.com/company/yournidra/',
        ]}
      />

      {/* Keep MedicalCondition schema if desired */}
      <MedicalConditionStructuredData
        name="Obstructive Sleep Apnea"
        description="Obstructive sleep apnea (OSA) is a sleep disorder characterized by repeated pauses in breathing during sleep, leading to disrupted sleep and low blood oxygen levels."
        possibleSymptoms="Loud snoring, gasping for air during sleep, excessive daytime sleepiness, morning headaches, difficulty concentrating"
        typicalAgeRange="40-60"
      />
    </Helmet>
  );
};

export default StructuredData;