import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import DistributorLayout from './DistributorLayout';
import { toast, ToastContainer } from 'react-toastify';
import {
  getServicePackageDetails,
  removeServicePackageFromDistributor,
} from '../services/DistributorService';
import 'react-toastify/dist/ReactToastify.css';

const ViewServicePackagePage: React.FC = () => {
  const { packageId } = useParams<{ packageId: string }>();
  const navigate = useNavigate();
  const [packageData, setPackageData] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);
  const distributorId = localStorage.getItem('distributorId');

  useEffect(() => {
    const fetchPackage = async () => {
      try {
        if (!packageId) {
          toast.error('Service package not found.');
          return;
        }
        const response = await getServicePackageDetails(packageId);
        // Adjust based on your API structure
        setPackageData(response?.servicePackage);
      } catch (error) {
        console.error('Error fetching service package details:', error);
        toast.error('Failed to fetch service package details.');
      } finally {
        setIsLoading(false);
      }
    };
    fetchPackage();
  }, [packageId]);

  const totalPrice =
    packageData?.price ??
    packageData?.distributorServices?.reduce(
      (acc: number, svc: any) => acc + (svc.price || 0),
      0
    );

  // For controlling the Delete button
  const canDelete = packageData && !packageData.hasActiveOrder;

  const handleDeletePackage = async () => {
    if (!packageId || !distributorId) return;
    try {
      await removeServicePackageFromDistributor(packageId, distributorId);
      toast.success('Service package deleted successfully.');
      navigate('/distributor/service-packages');
    } catch (error) {
      console.error('Error deleting service package:', error);
      toast.error('Failed to delete service package.');
    }
  };

  if (isLoading) {
    return (
      <DistributorLayout>
        <ToastContainer />
        <div className="p-6 text-center">Loading service package details...</div>
      </DistributorLayout>
    );
  }

  if (!packageData) {
    return (
      <DistributorLayout>
        <ToastContainer />
        <div className="p-6 text-center">No service package details found.</div>
      </DistributorLayout>
    );
  }

  return (
    <DistributorLayout>
      <ToastContainer />
      <div className="p-6">
        <h2 className="text-2xl font-bold mb-4">View Service Package</h2>

        {/* Package Name & Description Card */}
        <div className="bg-white rounded-lg shadow-md p-4 mb-6">
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Package Name
            </label>
            <input
              type="text"
              value={packageData.packageName || ''}
              disabled
              className="block w-full border border-gray-300 rounded-md p-2 bg-gray-100"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Package Description
            </label>
            <textarea
              value={packageData.description || ''}
              disabled
              rows={3}
              className="block w-full border border-gray-300 rounded-md p-2 bg-gray-100"
            />
          </div>
        </div>

        {/* Services Header */}
        <h3 className="text-lg font-semibold mb-2">Services</h3>

        {/* Services Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-6">
          {packageData.distributorServices && packageData.distributorServices.length > 0 ? (
            packageData.distributorServices.map((service: any) => (
              <div
                key={service._id || service.id}
                className="relative bg-white rounded-lg shadow-md p-4 border"
              >
                <h3 className="text-lg font-semibold mb-1">{service.name}</h3>
                <p className="text-sm text-gray-600 mb-2">{service.description}</p>
                <p className="text-sm text-gray-500 mb-2">Duration: {service.duration}</p>
                <p className="text-sm text-gray-800 font-semibold">₹{service.price}</p>
              </div>
            ))
          ) : (
            <div className="col-span-full text-center py-4">
              No services associated.
            </div>
          )}
        </div>

        {/* Total Price */}
        <div className="bg-white rounded-lg shadow-md p-4 mb-6">
          <p className="text-lg font-medium">Total Price: ₹{totalPrice}</p>
        </div>

        {/* Delete Button (bottom-right) */}
        <div className="flex justify-end">
          <button
            onClick={handleDeletePackage}
            disabled={!canDelete}
            title={
              !canDelete
                ? 'This package is associated with an active service package order'
                : ''
            }
            className={`px-4 py-2 rounded-md transition-colors ${
              canDelete
                ? 'bg-red-500 hover:bg-red-600 text-white'
                : 'bg-red-300 cursor-not-allowed'
            }`}
          >
            Delete
          </button>
        </div>
      </div>
    </DistributorLayout>
  );
};

export default ViewServicePackagePage;