import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Logout: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Clear localStorage
    localStorage.removeItem('userToken');
    localStorage.removeItem('userId');
    localStorage.removeItem('isUserLoggedIn');
    localStorage.removeItem('userFirstName');
    localStorage.removeItem('userLastName');
    localStorage.removeItem('userEmail');
    localStorage.removeItem('userPhoneNumber');
    localStorage.removeItem('userGender');
    localStorage.removeItem('userDateOfBirth');
    localStorage.removeItem('userAddress');
    localStorage.removeItem('userName');

    // Redirect to login page
    navigate('auth/user/login');
  }, [navigate]);

  return null; // No need to render anything
};

export default Logout;