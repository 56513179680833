import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import DistributorLayout from './DistributorLayout';
import { toast, ToastContainer } from 'react-toastify';
import { 
  createServicePackage, 
  getDistributorServices, 
  createService, 
  removeServiceFromDistributor, 
  getDistributorServicePackages 
} from '../services/DistributorService';
import 'react-toastify/dist/ReactToastify.css';

interface Service {
  id: string;
  distributorId: string;
  name: string;
  description: string;
  duration: string; // e.g., "6 months", "1 year"
  price: number;
}

const AddServicePackagePage: React.FC = () => {
  const navigate = useNavigate();
  const [packageName, setPackageName] = useState('');
  const [packageDescription, setPackageDescription] = useState('');
  const [selectedServices, setSelectedServices] = useState<string[]>([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [services, setServices] = useState<Service[]>([]);
  
  // For the "Add New Service" modal
  const [showNewServiceModal, setShowNewServiceModal] = useState(false);
  const [newServiceName, setNewServiceName] = useState('');
  const [newServiceDescription, setNewServiceDescription] = useState('');
  const [newServiceDuration, setNewServiceDuration] = useState('');
  const [newServicePrice, setNewServicePrice] = useState<string>('');

  // For the Service Details Modal
  const [showServiceModal, setShowServiceModal] = useState(false);
  const [serviceForModal, setServiceForModal] = useState<Service | null>(null);

  // For active service packages (to check if a service is used)
  const [activePackages, setActivePackages] = useState<any[]>([]);

  const distributorId = localStorage.getItem('distributorId') || '';

  const fetchServices = useCallback(async () => {
    try {
      if (!distributorId) {
        toast.error('Distributor ID not found.');
        return;
      }
      const response = await getDistributorServices(distributorId);
      const fetchedServices = Array.isArray(response)
        ? response
        : Array.isArray(response.distributorServices)
        ? response.distributorServices
        : [];
      const formattedServices = fetchedServices.map((service: any) => ({
        id: service._id || service.id,
        distributorId: service.distributorId,
        name: service.name,
        description: service.description,
        duration: service.duration,
        price: service.price,
      }));
      setServices(formattedServices);
    } catch (error) {
      if (error instanceof Error && error.message === 'Request failed with status code 404') {
        setServices([]);
      } else {
        console.error('Error fetching services:', error);
        toast.error('Failed to fetch services.');
      }
    }
  }, [distributorId]);

  const fetchActivePackages = useCallback(async () => {
    try {
      if (!distributorId) return;
      // Using the same API that lists service packages; assuming it returns active packages
      const response = await getDistributorServicePackages(distributorId);
      setActivePackages(response?.distributorServicePackages || []);
    } catch (error) {
      console.error('Error fetching active packages:', error);
    }
  }, [distributorId]);

  useEffect(() => {
    fetchServices();
    fetchActivePackages();
  }, [fetchServices, fetchActivePackages]);

  // Recalculate total price whenever selectedServices or services change
  useEffect(() => {
    const selected = services.filter(service => selectedServices.includes(service.id));
    const sum = selected.reduce((acc, curr) => acc + curr.price, 0);
    setTotalPrice(sum);
  }, [selectedServices, services]);

  // Open modal to show service details
  const handleOpenServiceModal = (service: Service) => {
    setServiceForModal(service);
    setShowServiceModal(true);
  };

  const handleCloseServiceModal = () => {
    setShowServiceModal(false);
    setServiceForModal(null);
  };

  // Toggle service selection via plus button
  const handleToggleService = (id: string) => {
    setSelectedServices(prev =>
      prev.includes(id) ? prev.filter(item => item !== id) : [...prev, id]
    );
  };

  // Handle new service form submission and create it in the database
  const handleAddNewService = async (e: React.FormEvent) => {
    e.preventDefault();
    if (
      !newServiceName.trim() ||
      !newServiceDescription.trim() ||
      !newServiceDuration.trim() ||
      !newServicePrice.trim()
    ) {
      toast.error('Please fill out all fields for the new service.');
      return;
    }
    try {
      const payload = {
        name: newServiceName,
        description: newServiceDescription,
        duration: newServiceDuration,
        price: Number(newServicePrice),
      };
      const data = await createService(distributorId, payload);
      const newService: Service = {
        id: data.service._id,
        distributorId: data.service.distributorId,
        name: data.service.name,
        description: data.service.description,
        duration: data.service.duration,
        price: data.service.price,
      };
      setServices(prev => [...prev, newService]);
      toast.success('New service added successfully!');
      setNewServiceName('');
      setNewServiceDescription('');
      setNewServiceDuration('');
      setNewServicePrice('');
      setShowNewServiceModal(false);
      // Refresh active packages in case the new service is used (unlikely on creation, but just in case)
      fetchActivePackages();
    } catch (error: any) {
      console.error('Error creating service:', error);
      toast.error(error.response?.data?.message || 'Failed to add new service.');
    }
  };

  // Handle final package creation
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!packageName.trim()) {
      toast.error('Please enter a package name');
      return;
    }
    if (selectedServices.length === 0) {
      toast.error('Please select at least one service');
      return;
    }
    setIsSubmitting(true);
    try {
      const payload = {
        packageName,
        packageDescription,
        services: selectedServices, // array of service IDs
        price: totalPrice,
        distributorId,
      };
      await createServicePackage(distributorId, payload);
      toast.success('Service Package added successfully!');
      navigate('/distributor/service-packages'); // Redirect back to the list
    } catch (error: any) {
      console.error('Error adding service package:', error);
      toast.error(error.response?.data?.message || 'Failed to add service package.');
    } finally {
      setIsSubmitting(false);
    }
  };

  // Handle deletion of a service from the distributor
  const handleDeleteService = async () => {
    if (!serviceForModal) return;
    try {
      await removeServiceFromDistributor(distributorId, serviceForModal.id);
      toast.success('Service deleted successfully!');
      setServices(prev => prev.filter(service => service.id !== serviceForModal.id));
      setSelectedServices(prev => prev.filter(id => id !== serviceForModal.id));
      handleCloseServiceModal();
      fetchActivePackages();
    } catch (error: any) {
      console.error('Error deleting service:', error);
      toast.error(error.response?.data?.message || 'Failed to delete service.');
    }
  };

  // Compute whether the service shown in the modal is used in any active package
  const isServiceUsed = serviceForModal 
    ? activePackages.some(pkg => pkg.services && pkg.services.includes(serviceForModal.id))
    : false;

  return (
    <DistributorLayout>
      <ToastContainer />
      <div className="p-6">
        <h2 className="text-2xl font-bold mb-4">Create Service Package</h2>

        {/* Package Name & Description Card */}
        <div className="bg-white rounded-lg shadow-md p-4 mb-6">
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-2">Package Name</label>
            <input
              type="text"
              value={packageName}
              onChange={(e) => setPackageName(e.target.value)}
              required
              className="block w-full border border-gray-300 rounded-md p-2"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Package Description</label>
            <textarea
              value={packageDescription}
              onChange={(e) => setPackageDescription(e.target.value)}
              required
              rows={3}
              className="block w-full border border-gray-300 rounded-md p-2"
            />
          </div>
        </div>

        {/* Services Header */}
        <h3 className="text-lg font-semibold mb-2">Services</h3>

        {/* Services Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-6">
          {services.map(service => (
            <div
              key={service.id}
              className={`relative bg-white rounded-lg shadow-md p-4 border cursor-pointer transition-colors ${
                selectedServices.includes(service.id) ? 'border-darkGreen' : 'border-transparent'
              }`}
              onClick={() => handleOpenServiceModal(service)}
            >
              <h3 className="text-lg font-semibold mb-1">{service.name}</h3>
              <p className="text-sm text-gray-600 mb-2">{service.description}</p>
              <p className="text-sm text-gray-500 mb-2">Duration: {service.duration}</p>
              <p className="text-sm text-gray-800 font-semibold">₹{service.price}</p>
              {selectedServices.includes(service.id) && (
                <div className="absolute top-2 right-2 text-darkGreen font-bold text-xl">✓</div>
              )}
              {/* Plus button for selecting the service */}
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  handleToggleService(service.id);
                }}
                className="absolute bottom-2 right-2 bg-blue-500 hover:bg-blue-600 text-white p-1 rounded-full"
                title="Select Service"
              >
                +
              </button>
            </div>
          ))}

          {/* "Add New Service" Card */}
          <div
            key="add-new-service"
            className="flex flex-col items-center justify-center bg-white rounded-lg shadow-md border border-dashed border-gray-300 p-4 cursor-pointer hover:bg-gray-50 transition-colors"
            onClick={() => setShowNewServiceModal(true)}
          >
            <div className="text-gray-500 text-4xl font-bold mb-2">+</div>
            <p className="text-gray-600">Add New Service</p>
          </div>
        </div>

        {/* Total Price */}
        <div className="bg-white rounded-lg shadow-md p-4 mb-6">
          <p className="text-lg font-medium">Total Price: ₹{totalPrice}</p>
        </div>

        {/* Save Button */}
        <div className="flex justify-end">
          <button
            onClick={handleSubmit}
            disabled={isSubmitting}
            className="bg-darkGreen hover:bg-darkGreen-light text-white px-4 py-2 rounded-md transition-colors disabled:opacity-50"
          >
            {isSubmitting ? 'Creating...' : 'Create Service Package'}
          </button>
        </div>
      </div>

      {/* Modal for Adding a New Service */}
      {showNewServiceModal && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="bg-white rounded-lg p-6 w-full max-w-md">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-semibold">Add New Service</h3>
              <button
                onClick={() => setShowNewServiceModal(false)}
                className="text-gray-500 hover:text-gray-700"
              >
                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path 
                    strokeLinecap="round" 
                    strokeLinejoin="round" 
                    strokeWidth="2" 
                    d="M6 18L18 6M6 6l12 12" 
                  />
                </svg>
              </button>
            </div>
            <form onSubmit={handleAddNewService}>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">Service Name</label>
                <input
                  type="text"
                  value={newServiceName}
                  onChange={(e) => setNewServiceName(e.target.value)}
                  required
                  className="mt-1 block w-full border border-gray-300 rounded-md p-2"
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">Description</label>
                <textarea
                  value={newServiceDescription}
                  onChange={(e) => setNewServiceDescription(e.target.value)}
                  rows={3}
                  required
                  className="mt-1 block w-full border border-gray-300 rounded-md p-2"
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">Duration</label>
                <input
                  type="text"
                  value={newServiceDuration}
                  onChange={(e) => setNewServiceDuration(e.target.value)}
                  required
                  placeholder="e.g. '6 months' or '1 year'"
                  className="mt-1 block w-full border border-gray-300 rounded-md p-2"
                />
              </div>
              <div className="mb-6">
                <label className="block text-sm font-medium text-gray-700">Price (₹)</label>
                <input
                  type="number"
                  value={newServicePrice}
                  onChange={(e) => setNewServicePrice(e.target.value)}
                  required
                  className="mt-1 block w-full border border-gray-300 rounded-md p-2"
                />
              </div>
              <div className="flex justify-end">
                <button
                  type="submit"
                  className="bg-darkGreen hover:bg-darkGreen-light text-white px-4 py-2 rounded-md transition-colors"
                >
                  Add Service
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* Modal for Viewing Service Details */}
      {showServiceModal && serviceForModal && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="bg-white rounded-lg p-6 w-full max-w-md relative">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-semibold">Service Details</h3>
              <button
                onClick={handleCloseServiceModal}
                className="text-gray-500 hover:text-gray-700"
              >
                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path 
                    strokeLinecap="round" 
                    strokeLinejoin="round" 
                    strokeWidth="2" 
                    d="M6 18L18 6M6 6l12 12" 
                  />
                </svg>
              </button>
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Service Name</label>
              <input
                type="text"
                value={serviceForModal.name}
                disabled
                className="block w-full border border-gray-300 rounded-md p-2 bg-gray-100"
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Description</label>
              <textarea
                value={serviceForModal.description}
                disabled
                rows={3}
                className="block w-full border border-gray-300 rounded-md p-2 bg-gray-100"
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Duration</label>
              <input
                type="text"
                value={serviceForModal.duration}
                disabled
                className="block w-full border border-gray-300 rounded-md p-2 bg-gray-100"
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Price (₹)</label>
              <input
                type="number"
                value={serviceForModal.price}
                disabled
                className="block w-full border border-gray-300 rounded-md p-2 bg-gray-100"
              />
            </div>
            <div className="flex justify-end">
              <button
                onClick={handleDeleteService}
                disabled={isServiceUsed}
                title={
                  isServiceUsed
                    ? "This service is part of an active service package and cannot be deleted"
                    : ""
                }
                className={`px-4 py-2 rounded-md transition-colors ${
                  isServiceUsed
                    ? "bg-red-300 cursor-not-allowed"
                    : "bg-red-500 hover:bg-red-600 text-white"
                }`}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </DistributorLayout>
  );
};

export default AddServicePackagePage;