// src/api/dashboardApi.ts
import axiosInstance from '../AxiosInstance';

import { UserData } from '../../features/user/dashboard/types/UserDashboardTypes';

export const getQuizData = async (userId: string, token: string) => {
  const response = await axiosInstance.get(`/user/${userId}/quiz/all`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return response.data.quizzes;
};

export const getSleepStudyData = async (userId: string, token: string) => {
  const response = await axiosInstance.get(`/user/${userId}/sleep-study/all`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return response.data.sleepStudies;
};

export const getConsultationData = async (userId: string, token: string) => {
    const response = await axiosInstance.get(`/user/${userId}/consultation/all`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return response.data.consultations;
  };

export const updateUser = (userId: string, userData: UserData, token: string) => {
  return axiosInstance.put(`/user/${userId}/update`, userData, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};