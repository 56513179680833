import React from 'react';
import DistributorLayout from './DistributorLayout';
import { FaClipboardList, FaTruck, FaChartBar, FaClock } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';

const DistributorDashboard: React.FC = () => {
    // Dummy data for now. This will be replaced with API calls.
    const dashboardData = {
        totalOrders: 125,
        totalRevenue: 254567,
        pendingOrders: 15,
        shippedOrders: 75,
        recentOrders: [
            { id: 'order-1', orderNumber: 'ORD-2023-1001', customerName: 'John Doe', orderDate: '2024-03-15', totalAmount: 120, status: 'Shipped' },
            { id: 'order-2', orderNumber: 'ORD-2023-1002', customerName: 'Jane Smith', orderDate: '2024-03-14', totalAmount: 245, status: 'Pending' },
            { id: 'order-3', orderNumber: 'ORD-2023-1003', customerName: 'Bob Johnson', orderDate: '2024-03-14', totalAmount: 75, status: 'Delivered' },
            { id: 'order-4', orderNumber: 'ORD-2023-1004', customerName: 'Alice Brown', orderDate: '2024-03-13', totalAmount: 310, status: 'Processing' },
            { id: 'order-5', orderNumber: 'ORD-2023-1005', customerName: 'Charlie Green', orderDate: '2024-03-12', totalAmount: 95, status: 'Shipped' },
        ],
    };

    return (
        <DistributorLayout>
            <div className="p-6">
                <h1 className="text-2xl font-bold mb-6">Distributor Dashboard</h1>

                {/* Cards Section */}
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-6">
                    {/* Total Orders Card */}
                    <div className="bg-white rounded-lg shadow-md p-4 flex items-center">
                        <div className="p-3 bg-blue-100 rounded-full mr-4">
                            <FaClipboardList className="text-blue-600 text-2xl" />
                        </div>
                        <div>
                            <p className="text-gray-600">Total Orders</p>
                            <p className="text-2xl font-bold">{dashboardData.totalOrders}</p>
                        </div>
                    </div>

                    {/* Total Revenue Card */}
                    <div className="bg-white rounded-lg shadow-md p-4 flex items-center">
                        <div className="p-3 bg-green-100 rounded-full mr-4">
                            <FaChartBar className="text-green-600 text-2xl" />
                        </div>
                        <div>
                            <p className="text-gray-600">Total Revenue</p>
                            <p className="text-2xl font-bold">₹{dashboardData.totalRevenue}</p>
                        </div>
                    </div>

                    {/* Pending Orders Card */}
                    <div className="bg-white rounded-lg shadow-md p-4 flex items-center">
                        <div className="p-3 bg-yellow-100 rounded-full mr-4">
                            <FaClock className="text-yellow-600 text-2xl" />
                        </div>
                        <div>
                            <p className="text-gray-600">Pending Orders</p>
                            <p className="text-2xl font-bold">{dashboardData.pendingOrders}</p>
                        </div>
                    </div>

                    {/* Shipped Orders Card */}
                    <div className="bg-white rounded-lg shadow-md p-4 flex items-center">
                        <div className="p-3 bg-purple-100 rounded-full mr-4">
                            <FaTruck className="text-purple-600 text-2xl" />
                        </div>
                        <div>
                            <p className="text-gray-600">Shipped Orders</p>
                            <p className="text-2xl font-bold">{dashboardData.shippedOrders}</p>
                        </div>
                    </div>
                </div>

                {/* Recent Orders Table */}
                <div className="bg-white rounded-lg shadow-md p-6">
                    <h2 className="text-xl font-semibold mb-4">Recent Orders</h2>
                    <div className="overflow-x-auto">
                        <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                                <tr>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Order Number</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Customer Name</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Order Date</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Total Amount</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                                {dashboardData.recentOrders.map((order) => (
                                    <tr key={order.id} className="hover:bg-gray-50">
                                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{order.orderNumber}</td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{order.customerName}</td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{format(new Date(order.orderDate), 'PPP')}</td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">₹{order.totalAmount.toFixed(2)}</td>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                                                order.status === 'Shipped' ? 'bg-green-100 text-green-800' :
                                                order.status === 'Pending' ? 'bg-yellow-100 text-yellow-800' :
                                                order.status === 'Delivered' ? 'bg-blue-100 text-blue-800' :
                                                'bg-gray-100 text-gray-800'}`}> {order.status}
                                            </span>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                            <Link to={`/distributor/orders/${order.id}`} className="text-darkGreen hover:text-darkGreen-light" title="View Details">
                                                View
                                            </Link>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </DistributorLayout>
    );
};

export default DistributorDashboard;
