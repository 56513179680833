// src/features/blogs/components/Blogs.tsx
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
// Remove the direct JSON import:
// import blogEntries from './BlogEntries.json';
import { BlogListItem, BlogListResponse } from '../types/BlogTypes'; // Import updated types
import BlogCard from './BlogCard';
import { fetchBlogsList } from '../services/BlogService'; // Import the updated service
import { toast } from 'react-toastify'; // Optional: for error notifications

const Blogs: React.FC = () => {
  // State for blogs, loading, error, and pagination
  const [blogData, setBlogData] = useState<BlogListResponse>({
      blogs: [],
      currentPage: 1,
      totalPages: 1,
      totalBlogs: 0
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const blogsPerPage = 9; // Adjust as needed, or get from API response limit

  // Fetch blogs when the component mounts or page changes
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top on mount/page change
    loadBlogs(blogData.currentPage); // Load blogs for the current page
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blogData.currentPage]); // Dependency on currentPage to refetch on page change

  const loadBlogs = async (page: number) => {
    setLoading(true);
    setError(null);
    try {
      // Fetch blogs using the service
      const response = await fetchBlogsList(page, blogsPerPage);
      setBlogData(response); // Update state with fetched data and pagination info
    } catch (err) {
      setError('Failed to load blogs. Please try again later.');
      toast.error('Failed to load blogs.'); // Optional user feedback
      console.error("Error fetching blogs:", err);
      // Set empty state on error to prevent potential rendering issues
      setBlogData({ blogs: [], currentPage: 1, totalPages: 1, totalBlogs: 0 });
    } finally {
      setLoading(false);
    }
  };

  // --- Pagination Handlers ---
  const handleNextPage = () => {
    if (blogData.currentPage < blogData.totalPages) {
      setBlogData(prev => ({ ...prev, currentPage: prev.currentPage + 1 }));
    }
  };

  const handlePreviousPage = () => {
    if (blogData.currentPage > 1) {
      setBlogData(prev => ({ ...prev, currentPage: prev.currentPage - 1 }));
    }
  };
  // --- End Pagination Handlers ---

  return (
    <div className="min-h-screen bg-gray-50 py-20">
      <div className="max-w-7xl mx-auto px-4">
        <h1 className="text-4xl font-bold mb-12 text-center">Learn More About Sleep Apnea and Sleep Disorders</h1>

        {loading && <div className="text-center">Loading blogs...</div>}
        {error && <div className="text-center text-red-600">{error}</div>}

        {!loading && !error && (
          <>
            {blogData.blogs.length > 0 ? (
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
                {/* Map over fetched blog data */}
                {blogData.blogs.map((blog: BlogListItem) => (
                  // Use slug for the link
                  <Link to={`/blog/${blog.slug}`} key={blog._id} className="bg-white rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-shadow duration-200">
                    <BlogCard
                      title={blog.title}
                      image={blog.featuredImage || '/images/placeholder.jpg'} // Use featuredImage or a fallback
                      link={`/blog/${blog.slug}`} // Use slug
                      excerpt={blog.excerpt} // Pass excerpt if available
                    />
                  </Link>
                ))}
              </div>
            ) : (
              <p className="text-center text-gray-500">No blog posts found.</p>
            )}

            {/* Pagination Controls */}
            {blogData.totalPages > 1 && (
              <div className="flex justify-center items-center mt-12 space-x-4">
                <button
                  onClick={handlePreviousPage}
                  disabled={blogData.currentPage === 1 || loading}
                  className="px-4 py-2 bg-darkGreen text-white rounded-md disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  Previous
                </button>
                <span className="text-gray-700">
                  Page {blogData.currentPage} of {blogData.totalPages}
                </span>
                <button
                  onClick={handleNextPage}
                  disabled={blogData.currentPage === blogData.totalPages || loading}
                  className="px-4 py-2 bg-darkGreen text-white rounded-md disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  Next
                </button>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Blogs;