import React from 'react';
import { Helmet } from 'react-helmet';

interface OrganizationStructuredDataProps {
    name: string;
    url: string;
    logo: string;
    address: {
        streetAddress: string;
        addressLocality: string; // City
        addressRegion?: string;  // State/Province (Optional)
        postalCode: string;
        addressCountry: string;
    };
    email: string;
    telephone: string;
    sameAs?: string[]; // Array of social media URLs (optional)
}

const OrganizationStructuredData: React.FC<OrganizationStructuredDataProps> = ({
    name,
    url,
    logo,
    address,
    email,
    telephone,
    sameAs = [] // Default to empty array if not provided
}) => {

    const organizationSchema = {
        "@context": "https://schema.org",
        "@type": "Organization",
        "name": "YourNidra",
        "url": "https://yournidra.com",
        "logo": "https://yournidra.com/logo.png",
        "address": {
            "@type": "PostalAddress",
            "streetAddress": address.streetAddress,
            "addressLocality": address.addressLocality,
            "addressRegion": address.addressRegion,  // Use addressRegion
            "postalCode": address.postalCode,
            "addressCountry": address.addressCountry
        },
        "email": "hello@yournidra.com",
        "telephone": "+91 9643727987",
        "sameAs": [
            "https://www.facebook.com/yournidra", 
            "https://www.instagram.com/yournidra", 
            "https://www.linkedin.com/company/yournidra", 
            "https://www.x.com/yournidra"
            ]};

    return (
        <Helmet>
            <script type="application/ld+json">
                {JSON.stringify(organizationSchema)}
            </script>
        </Helmet>
    );
};

export default OrganizationStructuredData;