// src/api/consultationApi.ts

import axiosInstance from "../AxiosInstance";


export const getUserConsultations = async (userId: string, token: string) => {
    const response = await axiosInstance.get(`/user/${userId}/consultation/all`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return response.data.consultations;
};

export const scheduleConsultation = async (userId: string, date: string, time: string, token: string) => {
  const response = await axiosInstance.post('/consultation/schedule', {
    userId,
    date,
    time,
  }, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data.consultations;
};

export const cancelConsultationApi = async (consultationId: string, token: string) => {
    const response = await axiosInstance.patch(`/consultation/${consultationId}/cancel`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data.consultations;
  };