// features/distributor/components/DistributorLogout.tsx
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const DistributorLogout: React.FC = () => {
    const navigate = useNavigate();

    useEffect(() => {
        // Clear localStorage
        localStorage.removeItem('distributorToken');
        localStorage.removeItem('distributorId');
        localStorage.removeItem('distributorName');
        localStorage.removeItem('isDistributorLoggedIn');
        localStorage.removeItem('userRole');

        // Redirect to login page
        navigate('/auth/distributor/login'); // Correct route to distributor login
    }, [navigate]);

    return null; // No need to render anything
};

export default DistributorLogout;