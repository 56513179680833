// frontend/features/distributor/components/OrderList.tsx
import React, { useState, useEffect } from 'react';
import { getAllOrders, cancelOrder } from '../services/OrderService';
import { Order } from '../types/OrderTypes';
import DistributorLayout from './DistributorLayout';
import { format } from 'date-fns';
import { FaEye, FaTimes } from 'react-icons/fa'; 
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

const OrderList: React.FC = () => {
    const [orders, setOrders] = useState<Order[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchOrders = async () => {
            try {
                const ordersData = await getAllOrders();
                setOrders(ordersData);
            } catch (err: any) {
                setError(err.message || 'Failed to fetch orders');
            } finally {
                setLoading(false);
            }
        };

        fetchOrders();
    }, []);

    const handleCancelOrder = async (orderId: string) => {
        if (window.confirm('Are you sure you want to cancel this order?')) {
          try {
              await cancelOrder(orderId);
              // Update the local state to remove the cancelled order
              setOrders(prevOrders => prevOrders.filter(order => order._id !== orderId));
              toast.success("Order cancelled successfully.");
          }
          catch (error: any) {
            toast.error(error.response?.data?.message || 'Failed to cancel Order');
          }
        }
      };

    if (loading) {
        return <DistributorLayout><div>Loading orders...</div></DistributorLayout>;
    }

    if (error) {
        return <DistributorLayout><div>Error: {error}</div></DistributorLayout>;
    }

    return (
        <DistributorLayout>
            <div className="p-6 bg-white rounded-lg shadow-md">
                <h2 className="text-2xl font-bold mb-6">Order List</h2>

                {orders.length === 0 ? (
                    <p>No orders found.</p>
                ) : (
                    <div className="overflow-x-auto">
                        <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                                <tr>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Order Number</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Customer ID</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Total Amount</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Created At</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                                {orders.map(order => (
                                    <tr key={order._id} className="hover:bg-gray-100 transition-colors">
                                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{order.orderNumber}</td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{order.userId}</td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">₹{order.totalAmount.toFixed(2)}</td>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${order.status === 'Pending' ? 'bg-yellow-100 text-yellow-800' :
                                                    order.status === 'Processing' ? 'bg-blue-100 text-blue-800' :
                                                        order.status === 'Shipped' ? 'bg-indigo-100 text-indigo-800' :
                                                            order.status === 'Delivered' ? 'bg-green-100 text-green-800' :
                                                                order.status === 'Cancelled' ? 'bg-red-100 text-red-800' :
                                                                    order.status === 'Refunded' ? 'bg-pink-100 text-pink-800' :
                                                                        'bg-gray-100 text-gray-800' // Default, e.g., for 'Partially Shipped'
                                                }`}>
                                                {order.status}
                                            </span>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{format(new Date(order.createdAt), 'PPP')}</td>
                                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                            {/* "View Details" link */}
                                            <Link
                                                to={`/distributor/orders/${order._id}`} // Dynamic route
                                                className="text-darkGreen hover:text-darkGreen-light"
                                                title="View Details"
                                            >
                                                <FaEye className="w-5 h-5 inline-block" />
                                                <span className="sr-only">View Details</span>
                                            </Link>

                                            {/* "Cancel Order" button */}
                                            {order.status !== 'Cancelled' && order.status !== 'Delivered' && (
                                                <button
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleCancelOrder(order._id || '');
                                                      }}
                                                    className="text-red-600 hover:text-red-800"
                                                    title="Cancel Order"
                                                >
                                                  <FaTimes className='inline-block w-4 h-4'/>
                                                  <span className="sr-only">Cancel Order</span>
                                                </button>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
        </DistributorLayout>
    );
};

export default OrderList;