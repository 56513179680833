import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { format } from 'date-fns';
import { toast } from 'react-toastify';
import { fetchPatientDetails } from '../services/SleepCenterService';
import SleepCenterLayout from '../SleepCenterLayout';

interface PatientDetails {
    _id: string;
    user: {
        firstName: string;
        lastName: string;
        email: string;
        phoneNumber: string;
    };
    sleepStudies: Array<{
        _id: string;
        sleepStudyType: string;
        date: string;
        status: string;
        createdAt: string;
    }>;
}

const formatDate = (dateString: string | undefined) => {
    if (!dateString) return 'No date';
    try {
        const date = new Date(dateString);
        if (isNaN(date.getTime())) return 'Invalid date';
        return format(date, 'MMM dd, yyyy');
    } catch {
        return 'Invalid date';
    }
};

const SleepCenterPatientRecord: React.FC = () => {
    const { patientId } = useParams<{ patientId: string }>();
    const [patientDetails, setPatientDetails] = useState<PatientDetails | null>(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const loadPatientDetails = async () => {
            try {
                setLoading(true);
                const details = await fetchPatientDetails(patientId!);
                setPatientDetails(details);
                toast.success('Patient details loaded successfully');
            } catch (err: any) {
                console.error('Error fetching patient details:', err);
                setError('Failed to load patient details');
                toast.error(err.response?.data?.message || 'Failed to load patient details');
            } finally {
                setLoading(false);
            }
        };

        if (patientId) {
            loadPatientDetails();
        } else {
            toast.error('Patient ID is required');
        }
    }, [patientId]);

    if (loading) return <div className="flex justify-center items-center h-screen">Loading...</div>;
    if (error) {
        toast.error(error);
        return <div className="flex justify-center items-center h-screen">Error: {error}</div>;
    }
    if (!patientDetails) {
        toast.error('Patient not found');
        return <div className="flex justify-center items-center h-screen">Patient not found</div>;
    }

    return (
        <SleepCenterLayout>
            <div className="p-6 bg-white rounded-lg shadow-md max-w-4xl mx-auto">
                {/* Patient Information Section */}
                <div className="mb-8">
                    <h2 className="text-2xl font-bold text-gray-800 border-b pb-4 mb-6">
                        Patient Record
                    </h2>
                    <div className="flex justify-start space-x-24">
                        <div>
                            <label className="text-sm text-gray-600">Name</label>
                            <p className="font-medium text-gray-800 mt-1">
                                {patientDetails.user.firstName} {patientDetails.user.lastName}
                            </p>
                        </div>
                        <div>
                            <label className="text-sm text-gray-600">Phone Number</label>
                            <p className="font-medium text-gray-800 mt-1">
                                {patientDetails.user.phoneNumber}
                            </p>
                        </div>
                    </div>
                </div>

                {/* Sleep Studies Section */}
                <div className="mt-8">
                    <h3 className="text-xl font-semibold text-gray-800 mb-4">Sleep Studies</h3>
                    <div className="space-y-4">
                        {patientDetails.sleepStudies?.length > 0 ? (
                            patientDetails.sleepStudies.map((study) => (
                                <div
                                    key={study._id}
                                    className="p-4 bg-gray-50 rounded-lg shadow-sm"
                                >
                                    <div className="flex justify-between items-center">
                                        <div>
                                            <p className="font-medium text-gray-800">
                                                {study.sleepStudyType}
                                            </p>
                                            <p className="text-sm text-gray-600">
                                                Date: {formatDate(study.date)}
                                            </p>
                                        </div>
                                        <span className={`px-3 py-1 rounded-full text-sm font-medium ${
                                            study.status === 'Completed' ? 'bg-green-100 text-green-800' :
                                            study.status === 'Scheduled' ? 'bg-yellow-100 text-yellow-800' :
                                            'bg-blue-100 text-blue-800'
                                        }`}>
                                            {study.status}
                                        </span>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <p className="text-gray-500">No sleep studies recorded.</p>
                        )}
                    </div>
                </div>
            </div>
        </SleepCenterLayout>
    );
};

export default SleepCenterPatientRecord; 