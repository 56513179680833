// features/sleepCenter/components/SleepCenterLayout.tsx
import React from 'react';
import SleepCenterSidebar from './SleepCenterSidebar';

interface SleepCenterLayoutProps {
  children: React.ReactNode;
}

const SleepCenterLayout: React.FC<SleepCenterLayoutProps> = ({ children }) => {
  return (
    <div className="flex h-screen bg-gray-100 font-sans">
      <SleepCenterSidebar />
      <div className="flex-1 overflow-y-auto p-8">
        {children}
      </div>
    </div>
  );
};

export default SleepCenterLayout;