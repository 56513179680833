// src/features/admin/components/AdminLayout.tsx
import React from 'react';// Import the sidebar you just created
import { ToastContainer } from 'react-toastify'; // Include ToastContainer
import 'react-toastify/dist/ReactToastify.css';
import AdminSidebar from './AdminSidebar';


interface AdminLayoutProps {
    children: React.ReactNode;
}

const AdminLayout: React.FC<AdminLayoutProps> = ({ children }) => {
    return (
        <div className="flex h-screen bg-gray-100 font-sans overflow-hidden"> {/* Prevent body scroll */}
            <AdminSidebar />
            <div className="flex-1 flex flex-col overflow-hidden"> {/* Allow content to scroll */}
                {/* Optional: Add a Header specific to Admin section if needed */}
                {/* <AdminHeader /> */}
                <main className="flex-1 overflow-y-auto p-8"> {/* Main content scrolls */}
                    {children}
                </main>
            </div>
             {/* ToastContainer can live here or in App.tsx */}
             <ToastContainer position="top-right" autoClose={3000} />
        </div>
    );
};

export default AdminLayout;