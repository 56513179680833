import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import DatePicker from 'react-datepicker';
import { addSleepStudy, checkUserExists, fetchSleepCenterDetails } from '../services/SleepCenterService';

interface AddSleepStudyModalProps {
    onClose: () => void;
    onStudyAdded: () => void;
}

interface Offering {
    name: string;
    description: string;
    pricing: number;
}

const AddSleepStudyModal: React.FC<AddSleepStudyModalProps> = ({ onClose, onStudyAdded }) => {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [userVerified, setUserVerified] = useState(false);
    const [patientDetails, setPatientDetails] = useState<any>(null);
    const [offerings, setOfferings] = useState<Offering[]>([]);
    const [isCheckingUser, setIsCheckingUser] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState({
        userId: '',
        sleepStudyType: '',
        date: new Date(),
        notes: ''
    });
    const sleepCenterId = localStorage.getItem('sleepCenterId') || '';

    useEffect(() => {
        const fetchOfferings = async () => {
            try {
                const response = await fetchSleepCenterDetails(sleepCenterId);
                const formattedOfferings = (response.offerings || []).map(offering => ({
                    name: offering.name,
                    pricing: offering.pricing,
                    description: (offering as any).description || ''
                }));
                setOfferings(formattedOfferings);
            } catch (error) {
                console.error('Error fetching offerings:', error);
                toast.error('Failed to fetch offerings');
            }
        };
        fetchOfferings();
    }, [sleepCenterId]);

    const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPhoneNumber(e.target.value);
    };

    const handleCheckUser = async () => {
        if (!phoneNumber || phoneNumber.length !== 10) {
            toast.error('Please enter a valid 10-digit phone number');
            return;
        }

        setIsCheckingUser(true);
        try {
            const response = await checkUserExists(phoneNumber);

            if (response.exists) {
                setUserVerified(true);
                setPatientDetails(response.user);
                setFormData(prev => ({ ...prev, userId: response.user.id }));
                toast.success('User verified successfully');
            } else {
                toast.error('User not found. Please create a user account first.');
            }
        } catch (error) {
            console.error('Error checking user:', error);
            toast.error('Failed to verify user');
        } finally {
            setIsCheckingUser(false);
        }
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleDateChange = (date: Date | null) => {
        if (date) {
            setFormData(prev => ({
                ...prev,
                date
            }));
        }
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (!userVerified) {
            toast.error('Please verify user first');
            return;
        }

        setIsLoading(true);
        try {
            await addSleepStudy(formData);
            toast.success('Sleep study added successfully!');
            onStudyAdded();
            onClose();
        } catch (error: any) {
            toast.error(error.response?.data?.message || 'Failed to add sleep study');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white p-8 rounded-xl shadow-2xl w-[500px] relative">
                <h2 className="text-2xl font-bold mb-6 text-gray-800">Add New Sleep Study</h2>

                <form onSubmit={handleSubmit} className="space-y-6">
                    {/* Phone Number Input */}
                    <div className="space-y-2">
                        <label className="text-sm font-semibold text-gray-700">Phone Number</label>
                        <div className="flex gap-2">
                            <input
                                type="tel"
                                value={phoneNumber}
                                onChange={handlePhoneNumberChange}
                                className="flex-1 p-3 rounded-lg border border-gray-300 shadow-sm focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                                placeholder="Enter 10-digit number"
                                maxLength={10}
                                disabled={userVerified}
                            />
                            {!userVerified && (
                                <button
                                    type="button"
                                    onClick={handleCheckUser}
                                    disabled={isCheckingUser}
                                    className={`px-4 py-2 rounded-md text-white transition-colors ${
                                        isCheckingUser ? 'bg-gray-500 cursor-not-allowed' : 'bg-darkGreen hover:bg-darkGreen-light'
                                    }`}
                                >
                                    {isCheckingUser ? 'Checking...' : 'Verify'}
                                </button>
                            )}
                        </div>
                    </div>

                    {/* Patient Details */}
                    {userVerified && patientDetails && (
                        <div className="bg-gray-50 p-4 rounded-md">
                            <p className="text-sm text-gray-600"><strong>Patient Name:</strong> {patientDetails.firstName} {patientDetails.lastName}</p>
                        </div>
                    )}

                    {/* Study Type Dropdown */}
                    <div>
                        <label className="block text-sm font-medium text-gray-700">Study Type</label>
                        <select
                            name="sleepStudyType"
                            value={formData.sleepStudyType}
                            onChange={handleInputChange}
                            className="block w-full rounded-lg border-gray-300 shadow-sm focus:ring-2 focus:ring-blue-500 focus:border-transparent disabled:bg-gray-100"
                            required
                            disabled={!userVerified}
                        >
                            <option value="">Select Study Type</option>
                            {offerings.map((offering, index) => (
                                <option key={index} value={offering.name}>
                                    {offering.name} - ₹{offering.pricing}
                                </option>
                            ))}
                        </select>
                    </div>

                    {/* Date Picker */}
                    <div>
                        <label className="block text-sm font-medium text-gray-700">Date</label>
                        <DatePicker
                            selected={formData.date}
                            onChange={handleDateChange}
                            className="block w-full rounded-lg border-gray-300 shadow-sm focus:ring-2 focus:ring-blue-500 focus:border-transparent disabled:bg-gray-100"
                            dateFormat="dd/MM/yyyy"
                            required
                            disabled={!userVerified}
                        />
                    </div>

                    {/* Notes Input */}
                    <div>
                        <label className="block text-sm font-medium text-gray-700">Notes</label>
                        <textarea
                            name="notes"
                            value={formData.notes}
                            onChange={handleInputChange}
                            rows={3}
                            className="block w-full rounded-lg border-gray-300 shadow-sm focus:ring-2 focus:ring-blue-500 focus:border-transparent disabled:bg-gray-100"
                            disabled={!userVerified}
                        />
                    </div>

                    {/* Action Buttons */}
                    <div className="flex justify-end space-x-3 pt-4">
                        <button
                            type="button"
                            onClick={onClose}
                            className="px-6 py-3 bg-gray-100 text-gray-700 rounded-lg font-medium hover:bg-gray-200 transition-colors duration-200"
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            disabled={!userVerified || isLoading}
                            className={`px-6 py-3 rounded-lg font-medium transition-colors duration-200 ${
                                !userVerified ? 'bg-gray-300 text-gray-500 cursor-not-allowed' : 'bg-darkGreen text-white hover:bg-darkGreen-light'
                            }`}
                        >
                            {isLoading ? 'Adding...' : 'Add Study'}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default AddSleepStudyModal;