// src/features/admin/components/AdminSidebar.tsx
import React from 'react';
import { NavLink } from 'react-router-dom';
import {
    FaTachometerAlt,
    FaUsers,
    FaPlusSquare, // Icon for creating
    FaCog,
    FaSignOutAlt} from 'react-icons/fa'; // Example icons

const AdminSidebar: React.FC = () => {
    const linkClasses = "flex items-center w-full text-left px-6 py-3 text-lg rounded-md transition-colors duration-200";
    const activeLinkClasses = "bg-indigo-100 text-indigo-900 font-semibold";
    const inactiveLinkClasses = "text-gray-600 hover:bg-indigo-50 hover:text-indigo-900";
    const logoutLinkClasses = "text-red-600 hover:bg-red-50 hover:text-red-900";

    return (
        <div className="hidden lg:flex flex-col w-64 bg-white shadow-lg h-screen">
            <div className="p-4 border-b">
                <h2 className="text-xl font-bold text-center text-gray-800">Admin Panel</h2>
            </div>
            <nav className="mt-6 space-y-2 flex-1">
                <NavLink
                    to="/admin/dashboard"
                    className={({ isActive }) =>
                        `${linkClasses} ${isActive ? activeLinkClasses : inactiveLinkClasses}`
                    }
                >
                    <FaTachometerAlt className="mr-3" />
                    Dashboard
                </NavLink>
                <NavLink
                    to="/admin/manage-users"
                    className={({ isActive }) =>
                        `${linkClasses} ${isActive ? activeLinkClasses : inactiveLinkClasses}`
                    }
                >
                     <FaUsers className="mr-3" />
                    Manage Users
                </NavLink>

                {/* --- Link to Create Blog Post --- */}
                 <NavLink
                    to="/admin/blogs/create-blog"
                    className={({ isActive }) =>
                        `${linkClasses} ${isActive ? activeLinkClasses : inactiveLinkClasses}`
                    }
                 >
                     <FaPlusSquare className="mr-3" />
                     Create Blog Post
                 </NavLink>
                {/* --- End Link --- */}

                 {/* --- Optional: Link to Manage Blog Posts (future) --- */}
                 {/*
                 <NavLink
                    to="/admin/blogs" // Assuming '/admin/blogs' will list all posts
                    className={({ isActive }) =>
                        `${linkClasses} ${isActive ? activeLinkClasses : inactiveLinkClasses}`
                    }
                 >
                     <FaNewspaper className="mr-3" />
                     Manage Blogs
                 </NavLink>
                 */}
                 {/* --- End Optional Link --- */}

                <NavLink
                    to="/admin/settings"
                    className={({ isActive }) =>
                        `${linkClasses} ${isActive ? activeLinkClasses : inactiveLinkClasses}`
                    }
                >
                    <FaCog className="mr-3" />
                    Settings
                </NavLink>
            </nav>
             {/* Logout Button at the bottom */}
             <div className="p-4 mt-auto border-t">
                <NavLink
                    to="/auth/admin/logout"
                    className={({ isActive }) =>
                        `${linkClasses} ${isActive ? 'bg-red-100 text-red-900' : logoutLinkClasses}`
                     }
                >
                    <FaSignOutAlt className="mr-3" />
                    Logout
                </NavLink>
            </div>
        </div>
    );
};

export default AdminSidebar;