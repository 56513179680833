// src/features/blogs/services/BlogService.ts
import { getBlogsList, getBlogPostBySlug } from '../../../api/BlogApi'; // Import new API functions
import { BlogListResponse, BlogPost } from '../types/BlogTypes'; // Import updated types

/**
 * Service function to fetch a paginated list of published blog posts.
 * @param page - The page number.
 * @param limit - The number of items per page.
 * @returns Promise resolving to the BlogListResponse structure.
 */
export const fetchBlogsList = async (page: number = 1, limit: number = 10): Promise<BlogListResponse> => {
  try {
    const response = await getBlogsList(page, limit);
    // Assuming the backend structure matches BlogListResponse directly in response.data
    return response.data;
  } catch (error) {
    console.error('Error fetching blog list:', error);
    // Re-throw or handle error as appropriate for your application
    // For now, returning an empty state might be safer for the UI
    return { blogs: [], currentPage: 1, totalPages: 0, totalBlogs: 0 };
    // throw error; // Or re-throw if the component should handle the loading error state
  }
};

/**
 * Service function to fetch a single blog post by its slug.
 * @param slug - The blog post's slug.
 * @returns Promise resolving to the full BlogPost data.
 */
export const fetchBlogPostBySlug = async (slug: string): Promise<BlogPost> => {
    try {
        const response = await getBlogPostBySlug(slug);
        return response.data; // Axios wraps the response data in a `data` property
    } catch (error) {
        console.error(`Error fetching blog post with slug ${slug}:`, error);
        throw error; // Re-throw error to be handled by the calling component
    }
};


// --- Remove or comment out the old fetchBlogs function ---
// export const fetchBlogs = async (): Promise<BlogEntry[]> => {
//   const response = await getBlogs(); // Assuming getBlogs was the old API call
//   return response.data;
// };