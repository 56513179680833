// features/sleepCenter/components/SleepCenterSidebar.tsx
import React from 'react';
import { Link } from 'react-router-dom';

const SleepCenterSidebar: React.FC = () => {
    return (
        <div className="hidden lg:block w-64 bg-white shadow-lg">
            <nav className="mt-6 space-y-2">
                <Link
                    to="/sleep-center/dashboard"
                    className="block w-full text-left px-6 py-3 text-lg text-gray-600 hover:bg-indigo-50 hover:text-indigo-900 focus:outline-none rounded-md transition-all duration-200"
                >
                    Dashboard
                </Link>
                <Link
                    to="/sleep-center/studies"
                    className="block w-full text-left px-6 py-3 text-lg text-gray-600 hover:bg-indigo-50 hover:text-indigo-900 focus:outline-none rounded-md transition-all duration-200"
                >
                    Manage Studies
                </Link>
                <Link
                    to="/sleep-center/offerings"
                    className="block w-full text-left px-6 py-3 text-lg text-gray-600 hover:bg-indigo-50 hover:text-indigo-900 focus:outline-none rounded-md transition-all duration-200"
                >
                    Manage Offerings
                </Link>
                <Link
                    to="/sleep-center/patients" className="block w-full text-left px-6 py-3 text-lg text-gray-600 hover:bg-indigo-50 hover:text-indigo-900 focus:outline-none rounded-md transition-all duration-200"> Manage Patients </Link>
                <Link
                    to="/sleep-center/profile" className="block w-full text-left px-6 py-3 text-lg text-gray-600 hover:bg-indigo-50 hover:text-indigo-900 focus:outline-none rounded-md transition-all duration-200"> Manage Profile</Link>
                <Link
                    to="/auth/sleep-center/logout"
                    className="block w-full text-left px-6 py-3 text-lg text-red-600 hover:bg-red-50 hover:text-red-900 focus:outline-none rounded-md transition-all duration-200">
                    Logout
                </Link>
            </nav>
        </div>
    );
};

export default SleepCenterSidebar;