import React from 'react';
import { Helmet } from 'react-helmet';

interface FAQStructuredDataProps {
  faqData: { question: string; answer: string }[];
}

const FAQStructuredData: React.FC<FAQStructuredDataProps> = ({ faqData }) => {
  if (!faqData || faqData.length === 0) {
    return null; // Don't render anything if there's no FAQ data
  }

  const faqSchema = {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: faqData.map((faq) => ({
      '@type': 'Question',
      name: faq.question,
      acceptedAnswer: {
        '@type': 'Answer',
        text: faq.answer,
      },
    })),
  };

  return (
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(faqSchema)}</script>
    </Helmet>
  );
};

export default FAQStructuredData;