import React, { useEffect, useState } from 'react';
import UserSidebar from '../../UserSidebar';
import 'react-datepicker/dist/react-datepicker.css';
import { fetchAllSleepStudies, createSleepStudy, removeSleepStudy, updateSleepStudy } from '../services/UserSleepStudyService';
import { SleepStudyData } from '../types/UserSleepStudyTypes';
import { format } from 'date-fns';

const UserSleepStudy: React.FC = () => {
    const [sleepStudies, setSleepStudies] = useState<SleepStudyData[]>([]);
    const [loading, setLoading] = useState(true);
    const [selectedStudy, setSelectedStudy] = useState<SleepStudyData | null>(null);
    const [showModal, setShowModal] = useState(false);
    const [showScheduleModal, setShowScheduleModal] = useState(false);
    const [selectedDate, setSelectedDate] = useState<Date | null>(null);
    const [activeTab, setActiveTab] = useState<'requested' | 'scheduled' | 'completed'>('requested');
    const [page, setPage] = useState(1);
    const sleepStudiesPerPage = 5;
    const [selectedStudyId, setSelectedStudyId] = useState<string>('');
    const [showCreateModal, setShowCreateModal] = useState(false);

    const fetchSleepStudies = async () => {
        const userId = localStorage.getItem('userId') as string;

        try {
            setLoading(true);
            const studies = await fetchAllSleepStudies(userId);
            setSleepStudies(studies || []);
        } catch (err) {
            console.error('Error loading sleep studies', err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchSleepStudies();
    }, []);

    const handleSeeReportClick = (study: SleepStudyData) => {
        setSelectedStudy(study);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleCreateSubmit = async () => {
        if (!selectedDate) {
            alert('Please select a date');
            return;
        }

        try {
            const userId = localStorage.getItem('userId') as string;
            await createSleepStudy(userId, selectedDate.toISOString());
            setShowCreateModal(false);
            setSelectedDate(null);
            fetchSleepStudies();
            alert('Sleep study created successfully');
        } catch (error) {
            console.error('Error creating sleep study:', error);
            alert('Failed to create sleep study');
        }
    };

    const handleScheduleSubmit = async () => {
        if (!selectedDate) {
            alert('Please select a date');
            return;
        }

        try {
            await updateSleepStudy(selectedStudyId, format(selectedDate, 'yyyy-MM-dd'), 'Scheduled');
            setShowScheduleModal(false);
            setSelectedStudyId('');
            setSelectedDate(null);
            fetchSleepStudies();
            alert('Sleep study scheduled successfully');
        } catch (error) {
            console.error('Error scheduling sleep study:', error);
            alert('Failed to schedule sleep study');
        }
    };

    const handleDeleteSchedule = async (studyId: string) => {
        // eslint-disable-next-line no-restricted-globals
        if (confirm("Are you sure you want to cancel this scheduled study?")) {
            try {
                await removeSleepStudy(studyId);
                alert("Sleep study schedule cancelled successfully.");
                fetchSleepStudies(); // Refresh the list
            } catch (error) {
                console.error(error);
            }
        }
    };

    const handleTabClick = (tab: 'requested' | 'scheduled' | 'completed') => {
        setActiveTab(tab);
        setPage(1); // Reset page to 1 when changing tabs
    };

    // Pagination logic
    const filteredStudies = sleepStudies?.filter(study => {
        switch (activeTab) {
            case 'requested':
                return study.status === 'Request for Schedule';
            case 'scheduled':
                return study.status === 'Scheduled';
            case 'completed':
                return study.status === 'Completed';
            default:
                return false;
        }
    }) || [];

    const startIndex = (page - 1) * sleepStudiesPerPage;
    const paginatedStudies = filteredStudies.slice(startIndex, startIndex + sleepStudiesPerPage);
    const totalPages = Math.ceil(filteredStudies.length / sleepStudiesPerPage);

    const handleNextPage = () => {
        if (page < totalPages) {
            setPage(page + 1);
        }
    };

    const handlePreviousPage = () => {
        if (page > 1) {
            setPage(page - 1);
        }
    };

    const handleScheduleClick = (study: SleepStudyData) => {
        setSelectedStudyId(study._id);
        setShowScheduleModal(true);
    };

    return (
        <div className="flex flex-col lg:flex-row h-screen bg-gray-50 font-sans">
            <UserSidebar />
            <div className="flex-1 overflow-y-auto p-4 lg:p-8">
                <div className="flex flex-col lg:flex-row justify-between items-center mb-4 lg:mb-6">
                    <div className="text-center lg:text-left mb-4 lg:mb-0">
                        <h2 className="text-2xl lg:text-3xl font-bold mb-2">YourNidra Sleep Studies</h2>
                        <p className="text-sm lg:text-base text-gray-600">View scheduled and completed sleep studies.</p>
                    </div>
                    <button
                        onClick={() => setShowCreateModal(true)}
                        className="bg-darkGreen hover:bg-darkGreen text-white font-bold py-2 px-4 lg:px-6 rounded-lg shadow-md w-full lg:w-auto"
                    >
                        Create Sleep Study
                    </button>
                </div>

                {/* Tabs for Scheduled and Completed Studies */}
                <div className="mb-4 lg:mb-6 flex border-b border-gray-200 overflow-x-auto">
                    <button
                        className={`px-4 py-2 text-sm font-medium rounded-t-lg flex-shrink-0 ${
                            activeTab === 'requested'
                                ? 'bg-white text-darkGreen border-l border-t border-r border-gray-300'
                                : 'bg-gray-200 text-gray-600'
                        }`}
                        onClick={() => handleTabClick('requested')}
                    >
                        Study Requests
                    </button>
                    <button
                        className={`ml-2 px-4 py-2 text-sm font-medium rounded-t-lg flex-shrink-0 ${
                            activeTab === 'scheduled'
                                ? 'bg-white text-darkGreen border-l border-t border-r border-gray-300'
                                : 'bg-gray-200 text-gray-600'
                        }`}
                        onClick={() => handleTabClick('scheduled')}
                    >
                        Scheduled
                    </button>
                    <button
                        className={`ml-2 px-4 py-2 text-sm font-medium rounded-t-lg flex-shrink-0 ${
                            activeTab === 'completed'
                                ? 'bg-white text-darkGreen border-l border-t border-r border-gray-300'
                                : 'bg-gray-200 text-gray-600'
                        }`}
                        onClick={() => handleTabClick('completed')}
                    >
                        Completed
                    </button>
                </div>

                {/* Tab Content: Scheduled Studies */}
                {activeTab === 'scheduled' && (
                    <>
                        {loading ? (
                            <div className="flex justify-center items-center">
                                <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full text-darkGreen" role="status"></div>
                            </div>
                        ) : paginatedStudies.length > 0 ? (
                            <div className="space-y-4 lg:space-y-6">
                                {paginatedStudies.map((study) => (
                                    <div key={study._id} className="relative bg-white rounded-lg shadow-md p-4 lg:p-6 border-l-8 border-blue-500">
                                        <p className="text-xs lg:text-sm text-gray-600 mb-2">
                                            Scheduled for: {new Date(study.date).toLocaleDateString()}
                                        </p>
                                        <div className="flex flex-col lg:flex-row justify-between items-start lg:items-end">
                                            <p className="text-gray-700 font-bold">Status: {study.status}</p>
                                            <button
                                                onClick={() => handleDeleteSchedule(study._id)}
                                                className="bg-red-500 hover:bg-red-600 text-white font-bold py-1 px-4 rounded-lg shadow-md mt-2 lg:mt-0 w-full lg:w-auto"
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <p className="text-center text-gray-600">No sleep study scheduled</p>
                        )}

                        {/* Pagination Controls */}
                        {paginatedStudies.length > 0 && totalPages > 1 && (
                            <div className="flex flex-col lg:flex-row justify-between mt-4 space-y-4 lg:space-y-0">
                                {page > 1 && (
                                    <button
                                        onClick={handlePreviousPage}
                                        className="w-full lg:w-auto bg-darkGreen text-white font-bold py-2 px-4 rounded-lg shadow-md"
                                    >
                                        Previous
                                    </button>
                                )}
                                {page < totalPages && (
                                    <button
                                        onClick={handleNextPage}
                                        className="w-full lg:w-auto bg-darkGreen text-white font-bold py-2 px-4 rounded-lg shadow-md"
                                    >
                                        Next
                                    </button>
                                )}
                            </div>
                        )}
                    </>
                )}

                {/* Tab Content: Completed Studies */}
                {activeTab === 'completed' && (
                    <>
                        {loading ? (
                            <div className="flex justify-center items-center">
                                <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full text-darkGreen" role="status"></div>
                            </div>
                        ) : paginatedStudies.length > 0 ? (
                            <div className="space-y-4 lg:space-y-6">
                                {paginatedStudies.map((study) => (
                                    <div key={study._id} className="relative bg-white rounded-lg shadow-md p-4 lg:p-6 border-l-8 border-blue-500">
                                        <p className="text-xs lg:text-sm text-gray-600 mb-2">
                                            Completed on: {new Date(study.createdAt).toLocaleDateString()}
                                        </p>
                                        <div className="flex flex-col lg:flex-row justify-between items-start lg:items-end">
                                            <div className="mb-4 lg:mb-0">
                                                <h3 className="text-lg lg:text-xl font-semibold">Sleep Study Report</h3>
                                                <p className="text-xs lg:text-sm text-gray-700 mt-2">AHI: {study.ahi} events/hour (Moderate Apnea)</p>
                                                <p className="text-xs lg:text-sm text-gray-700">Minimum Oxygen Saturation: {study.minOxygen}%</p>
                                            </div>
                                            <button
                                                onClick={() => handleSeeReportClick(study)}
                                                className="bg-darkGreen hover:bg-darkGreen text-white font-bold py-1 px-4 rounded-lg shadow-md mt-2 lg:mt-0 w-full lg:w-auto"
                                            >
                                                See Report
                                            </button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <p className="text-center text-gray-600">No sleep study completed</p>
                        )}

                        {/* Pagination Controls */}
                        {paginatedStudies.length > 0 && totalPages > 1 && (
                            <div className="flex flex-col lg:flex-row justify-between mt-4 space-y-4 lg:space-y-0">
                                {page > 1 && (
                                    <button
                                        onClick={handlePreviousPage}
                                        className="w-full lg:w-auto bg-darkGreen text-white font-bold py-2 px-4 rounded-lg shadow-md"
                                    >
                                        Previous
                                    </button>
                                )}
                                {page < totalPages && (
                                    <button
                                        onClick={handleNextPage}
                                        className="w-full lg:w-auto bg-darkGreen text-white font-bold py-2 px-4 rounded-lg shadow-md"
                                    >
                                        Next
                                    </button>
                                )}
                            </div>
                        )}
                    </>
                )}

                {/* Tab Content: Study Requests */}
                {activeTab === 'requested' && (
                    <div className="space-y-4 lg:space-y-6">
                        {loading ? (
                            <div className="flex justify-center items-center">
                                <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full text-darkGreen" role="status"></div>
                            </div>
                        ) : paginatedStudies.length > 0 ? (
                            paginatedStudies.map((study) => (
                                <div key={study._id} className="relative bg-white rounded-lg shadow-md p-4 lg:p-6 border-l-8 border-yellow-500">
                                    <div className="flex flex-col space-y-2">
                                        <p className="text-sm text-gray-600">
                                            Requested by: Dr. {study.doctorId.firstName} {study.doctorId.lastName}
                                        </p>
                                        <p className="text-sm text-gray-600">
                                            Study Type: {study.sleepStudyType}
                                        </p>
                                        <p className="text-xs text-gray-500">
                                            Requested on: {new Date(study.createdAt).toLocaleDateString()}
                                        </p>
                                    </div>
                                    <div className="flex justify-end mt-4">
                                        <button
                                            onClick={() => handleScheduleClick(study)}
                                            className="bg-darkGreen hover:bg-darkGreen-light text-white font-bold py-2 px-4 rounded-lg shadow-md"
                                        >
                                            Schedule Study
                                        </button>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <p className="text-center text-gray-600">No requests for sleep study</p>
                        )}
                    </div>
                )}

                {/* Create Sleep Study Modal */}
                {showCreateModal && (
                    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                        <div className="bg-white p-6 rounded-lg shadow-xl max-w-md w-full">
                            <h3 className="text-lg font-semibold mb-4">Create New Sleep Study</h3>
                            <div className="mb-4">
                                <label className="block text-sm font-medium text-gray-700 mb-2">
                                    Select Date
                                </label>
                                <input
                                    type="date"
                                    className="w-full p-2 border rounded"
                                    onChange={(e) => setSelectedDate(new Date(e.target.value))}
                                    min={new Date().toISOString().split('T')[0]}
                                />
                            </div>
                            <div className="flex justify-end space-x-3">
                                <button
                                    onClick={() => {
                                        setShowCreateModal(false);
                                        setSelectedDate(null);
                                    }}
                                    className="px-4 py-2 text-gray-600 hover:text-gray-800"
                                >
                                    Cancel
                                </button>
                                <button
                                    onClick={handleCreateSubmit}
                                    className="px-4 py-2 bg-darkGreen text-white rounded hover:bg-darkGreen-light"
                                >
                                    Create
                                </button>
                            </div>
                        </div>
                    </div>
                )}

                {/* Schedule Study Modal */}
                {showScheduleModal && (
                    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                        <div className="bg-white p-6 rounded-lg shadow-xl max-w-md w-full">
                            <h3 className="text-lg font-semibold mb-4">Schedule Sleep Study</h3>
                            <div className="mb-4">
                                <label className="block text-sm font-medium text-gray-700 mb-2">
                                    Select Date
                                </label>
                                <input
                                    type="date"
                                    value={selectedDate ? format(selectedDate, 'yyyy-MM-dd') : ''}
                                    className="w-full p-2 border rounded"
                                    onChange={(e) => {
                                        const date = new Date(e.target.value);
                                        setSelectedDate(date);
                                    }}
                                    min={new Date().toISOString().split('T')[0]}
                                />
                            </div>
                            <div className="flex justify-end space-x-3">
                                <button
                                    onClick={() => {
                                        setShowScheduleModal(false);
                                        setSelectedStudyId('');
                                        setSelectedDate(null);
                                    }}
                                    className="px-4 py-2 text-gray-600 hover:text-gray-800"
                                >
                                    Cancel
                                </button>
                                <button
                                    onClick={handleScheduleSubmit}
                                    className="px-4 py-2 bg-darkGreen text-white rounded hover:bg-darkGreen-light"
                                >
                                    Schedule
                                </button>
                            </div>
                        </div>
                    </div>
                )}

                {/* Modal for displaying sleep study report */}
                {showModal && selectedStudy && (
                    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
                        <div className="bg-white rounded-lg shadow-md p-6 lg:p-8 mx-auto w-full max-w-[90vw] h-auto max-h-[90vh] overflow-y-auto relative">
                            <div className="flex justify-between items-center mb-4">
                                <h2 className="text-xl lg:text-2xl font-bold">Sleep Study Report</h2>
                                <button
                                    className="bg-darkGreen hover:bg-darkGreen-light text-white font-bold py-2 px-4 rounded"
                                    onClick={handleCloseModal}
                                >
                                    Close
                                </button>
                            </div>
                            <p className="text-xs lg:text-sm text-gray-600 mb-4">Completed on: {new Date(selectedStudy.createdAt).toLocaleDateString()}</p>

                            <p className="text-sm lg:text-base text-gray-700 mb-6">AHI: {selectedStudy.ahi} events/hour</p>
                            <p className="text-sm lg:text-base text-gray-700 mb-6">Minimum Oxygen Saturation: {selectedStudy.minOxygen}%</p>
                            <p className="text-sm lg:text-base text-gray-700 mb-6">Recommendations: {selectedStudy.recommendations}</p>

                            {selectedStudy.reportUrl && (
                                <button
                                    onClick={() => window.open(selectedStudy.reportUrl, '_blank')}
                                    className="w-full lg:w-auto bg-darkGreen hover:bg-darkGreen text-white font-bold py-2 px-4 rounded"
                                >
                                    Download Full Report
                                </button>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default UserSleepStudy;
