// frontend/features/distributor/components/ProductList.tsx
import React from 'react';
import DistributorLayout from './DistributorLayout';
import { Link } from 'react-router-dom';

interface Product {  //Temporary
    id: string;
    name: string;
    price: number;
}
const dummyProducts: Product[] = [  //Temporary
    { id: '1', name: 'CPAP Machine A', price: 500 },
    { id: '2', name: 'CPAP Mask B', price: 50 },
    { id: '3', name: 'CPAP Tubing', price: 20 },
];

const ProductList: React.FC = () => {
    return (
        <DistributorLayout>
            <div className="p-6">
                <h2 className="text-2xl font-bold mb-4">Product List</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                    {dummyProducts.map((product) => (
                        <div key={product.id} className="bg-white rounded-lg shadow-md p-4">
                            <h3 className="text-lg font-semibold">{product.name}</h3>
                            <p className="text-gray-600">Price: ₹{product.price}</p>
                            <Link to={`/distributor/products/${product.id}`} className="text-blue-600 hover:text-blue-800 mt-2 inline-block">
                                View Details
                            </Link>
                        </div>
                    ))}
                </div>
            </div>
        </DistributorLayout>
    );
};
export default ProductList;