// src/api/quizApi.ts
import axiosInstance from '../AxiosInstance';
import { QuizApiResponse, QuizData } from '../../features/user/questionnaire/types/userQuizTypes';

export const fetchQuizzesData = async (userId: string, token: string): Promise<QuizApiResponse> => {
    const response = await axiosInstance.get(`/user/${userId}/quiz/all`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return response.data;
};

export const fetchQuizDetailsData = async (quizId: string, token: string): Promise<QuizData> => {
    const response = await axiosInstance.get(`/quiz/${quizId}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return response.data.quiz;
};