import React from 'react';
import { Helmet } from 'react-helmet';

interface MedicalConditionStructuredDataProps {
  name: string;
  description: string;
  possibleSymptoms?: string; // Optional, could be a comma-separated string
  typicalAgeRange?: string;  // Optional
  // Add more properties as needed, based on the MedicalCondition schema
}

const MedicalConditionStructuredData: React.FC<MedicalConditionStructuredDataProps> = ({
  name,
  description,
  possibleSymptoms,
  typicalAgeRange,
}) => {
  const structuredData = {
    '@context': 'https://schema.org',
    '@type': 'MedicalCondition',
    name: name,
    description: description,
    possibleSymptoms: possibleSymptoms,
    typicalAgeRange: typicalAgeRange,
    // Add more properties here, e.g.,
    // associatedAnatomy: { ... },
    // differentialDiagnosis: { ... },
    // epidemiology: { ... },
    // possibleComplication: { ... },
    // possibleTreatment: { ... },
    // primaryPrevention: { ... },
    // riskFactor: { ... },
    // ... and many more. See https://schema.org/MedicalCondition
  };

  return (
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(structuredData)}</script>
    </Helmet>
  );
};

export default MedicalConditionStructuredData;