// frontend/features/distributor/services/OrderService.ts
import axiosInstance from '../../../api/AxiosInstance';
import { Order } from '../types/OrderTypes';

// Create a new order
export const createOrder = async (orderData: Omit<Order, '_id' | 'createdAt' | 'updatedAt' | 'status'>) => {
  try {
    const response = await axiosInstance.post('/orders', orderData);
    return response.data; // Assuming the backend returns the created order
  } catch (error) {
    console.error("Error creating order:", error);
    throw error;
  }
};

// Get all orders (for the distributor)
export const getAllOrders = async () => {  //No parameters needed for distributor
    try {
        const response = await axiosInstance.get('/orders');
        return response.data; // Assuming the backend returns an array of orders
    } catch (error) {
        console.error("Error getting all orders", error);
        throw error;
    }
};

// Get a single order by ID
export const getOrderById = async (orderId: string) => {
  try {
    const response = await axiosInstance.get(`/orders/${orderId}`);
    return response.data;
  } catch (error) {
    console.error("Error getting order:", error);
    throw error;
  }
};

// Update an order's status
export const updateOrderStatus = async (orderId: string, status: string) => {
    try{
        const response = await axiosInstance.put(`/orders/${orderId}/status`, { status });
        return response.data;
    } catch(error){
        console.error("Error updating order status", error);
        throw error;
    }
};

// Cancel an order
export const cancelOrder = async (orderId: string) => {
  try {
      const response = await axiosInstance.delete(`/orders/${orderId}`);
      return response.data;
  } catch (error) {
      console.error("Error deleting order", error);
        throw error;
  }
};