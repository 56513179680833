import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { SleepCenterLoginFormData } from '../../sleepCenter/types/SleepCenterTypes';
import { loginSleepCenter } from '../../sleepCenter/services/SleepCenterService';


const SleepCenterLogin: React.FC = () => {
    const [formData, setFormData] = useState<SleepCenterLoginFormData>({
        email: '',
        password: ''
    });
    const [error, setError] = useState<string | null>(null);
    const navigate = useNavigate();

    useEffect(() => {
        // Check if sleep center is already logged in
        const isSleepCenterLoggedIn = localStorage.getItem('isSleepCenterLoggedIn') === 'true';
        if (isSleepCenterLoggedIn) {
            navigate('/sleep-center/dashboard');
        }
        window.scrollTo(0, 0);
    }, [navigate]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setError(null);

        try {
            const response = await loginSleepCenter(formData);
            if (response.status === 200) {
                const { token, sleepCenter } = response.data;
                
                // Make sure all these items are being set
                localStorage.setItem('sleepCenterToken', token);
                localStorage.setItem('sleepCenterId', sleepCenter._id);
                localStorage.setItem('sleepCenterName', sleepCenter.name);
                localStorage.setItem('isSleepCenterLoggedIn', 'true');
                localStorage.setItem('userRole', 'sleepCenter');

                toast.success('Login successful!');
                setTimeout(() => {
                    navigate('/sleep-center/dashboard');
                }, 1000);
            }
        } catch (error: any) {
            let message = 'Something went wrong. Please try again.';
            if (axios.isAxiosError(error) && error.response) {
                message = error.response.data.message || message
            }
            setError(message);
            toast.error(message);
        }
    };

    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
            <ToastContainer />
            <div className="max-w-7xl w-full px-4 sm:px-6 lg:px-8">
                <div className="bg-white py-8 px-6 shadow rounded-lg sm:px-10 mx-auto max-w-lg">
                    <h2 className="text-center text-3xl font-extrabold text-darkGreen">Login</h2>
                    <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
                        <input type="hidden" name="remember" value="true" />
                        <div className="rounded-md shadow-sm space-y-4">
                            <div>
                                <label htmlFor="email-address" className="sr-only">Email address</label>
                                <input
                                    id="email-address"
                                    name="email"
                                    type="email"
                                    autoComplete="email"
                                    required
                                    className="appearance-none rounded-md relative block w-full px-4 py-3 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-base"
                                    placeholder="Email address"
                                    value={formData.email}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <label htmlFor="password" className="sr-only">Password</label>
                                <input
                                    id="password"
                                    name="password"
                                    type="password"
                                    autoComplete="current-password"
                                    required
                                    className="appearance-none rounded-md relative block w-full px-4 py-3 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-base"
                                    placeholder="Password"
                                    value={formData.password}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>

                        <div className="text-right"> {/* Right-align the forgot password link */}
                            <Link to="/auth/sleep-center/forgot-password" className="text-sm font-medium text-blue-600 hover:text-blue-500">
                                Forgot password?
                            </Link>
                        </div>


                        <div className="mt-4">
                            <button
                                type="submit"
                                className="group relative w-full flex justify-center py-3 px-4 border border-transparent text-base font-medium rounded-md text-white bg-darkGreen focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 shadow-lg"
                            >
                                Sign in
                            </button>
                        </div>

                        {error && <p className="text-red-500 text-sm mt-2 text-center">{error}</p>}

                    </form>
                    <div className="text-sm text-center mt-6">
                        <Link to="/auth/sleep-center/register" className="font-medium text-blue-600 hover:text-blue-500">
                            Don't have an account? Register here
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SleepCenterLogin;