// frontend/features/distributor/components/DistributorLogin.tsx
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { loginDistributor } from '../../distributor/services/DistributorService';
import { DistributorLoginFormData } from '../../distributor/types/DistributorAuthTypes';
import axios from 'axios';

const DistributorLogin: React.FC = () => {
    const [formData, setFormData] = useState<DistributorLoginFormData>({
        email: '',
        password: ''
    });
    const navigate = useNavigate();

    useEffect(() => {
        const isDistributorLoggedIn = localStorage.getItem('isDistributorLoggedIn') === 'true';
        if (isDistributorLoggedIn) {
            navigate('/distributor/dashboard'); //  Navigate to the correct dashboard
        }
        window.scrollTo(0, 0);
    }, [navigate]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            const response = await loginDistributor(formData);
            if (response.status === 200) {
                const { token, distributor } = response.data;

                // Store the token and other relevant information
                localStorage.setItem('distributorToken', token);
                localStorage.setItem('distributorId', distributor._id);
                localStorage.setItem('distributorName', distributor.name);
                localStorage.setItem('isDistributorLoggedIn', 'true');
                localStorage.setItem('userRole', 'distributor');

                toast.success('Login successful!');
                setTimeout(() => {
                    navigate('/distributor/dashboard');
                }, 1000);
            }
        } catch (error: any) {
            let message = 'Something went wrong. Please try again.';
            if (axios.isAxiosError(error) && error.response) {
                message = error.response.data.message || message;
            }
            toast.error(message);
        }
    };

    return (
        <>
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
                <div className="max-w-7xl w-full px-4 sm:px-6 lg:px-8">
                    <div className="bg-white py-8 px-6 shadow rounded-lg sm:px-10 mx-auto max-w-lg">
                        <h2 className="text-center text-3xl font-extrabold text-darkGreen"> Login</h2>
                        <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
                            <input type="hidden" name="remember" value="true" />
                            <div className="rounded-md shadow-sm space-y-4">
                                <div>
                                    <label htmlFor="email-address" className="sr-only">Email address</label>
                                    <input
                                        id="email-address"
                                        name="email"
                                        type="email"
                                        autoComplete="email"
                                        required
                                        className="appearance-none rounded-md relative block w-full px-4 py-3 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-base"
                                        placeholder="Email address"
                                        value={formData.email}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div>
                                    <label htmlFor="password" className="sr-only">Password</label>
                                    <input
                                        id="password"
                                        name="password"
                                        type="password"
                                        autoComplete="current-password"
                                        required
                                        className="appearance-none rounded-md relative block w-full px-4 py-3 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-base"
                                        placeholder="Password"
                                        value={formData.password}
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </div>

                            <div className="text-right">
                                <Link to="/distributor/forgot-password" className="text-sm font-medium text-blue-600 hover:text-blue-500">
                                    Forgot password?
                                </Link>
                            </div>

                            <div className="mt-4">
                                <button
                                    type="submit"
                                    className="group relative w-full flex justify-center py-3 px-4 border border-transparent text-base font-medium rounded-md text-white bg-darkGreen focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 shadow-lg"
                                >
                                    Sign in
                                </button>
                            </div>
                        </form>
                        <div className="text-sm text-center mt-6">
                            <Link to="/distributor/register" className="font-medium text-blue-600 hover:text-blue-500">
                                Don't have an account? Register here
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DistributorLogin;