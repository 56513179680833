// Admin Dashboard Component: features/admin/components/AdminDashboard.tsx
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const AdminDashboard: React.FC = () => {
  const [userCount, setUserCount] = useState(50); // Dummy Data
  const [doctorCount, setDoctorCount] = useState(20); // Dummy Data
  const [sleepStudyCount, setSleepStudyCount] = useState(10); // Dummy Data
  const [consultationCount, setConsultationCount] = useState(15); // Dummy Data
  const [deviceCount, setDeviceCount] = useState(5); // Dummy Data

  useEffect(() => {
    // This is where real data fetching would occur
    // For now, we're using dummy data
  }, []);

  return (
    <div className="p-6">
      <h2 className="text-3xl font-bold mb-6">Admin Dashboard</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        <div className="bg-white p-6 rounded-lg shadow-md text-center">
          <h3 className="text-xl font-semibold mb-2">Total Users</h3>
          <p className="text-4xl font-bold mb-4">{userCount}</p>
          <Link to="/admin/users" className="text-blue-500 font-semibold hover:underline">View Users</Link>
        </div>
        <div className="bg-white p-6 rounded-lg shadow-md text-center">
          <h3 className="text-xl font-semibold mb-2">Total Doctors</h3>
          <p className="text-4xl font-bold mb-4">{doctorCount}</p>
          <Link to="/admin/doctors" className="text-blue-500 font-semibold hover:underline">View Doctors</Link>
        </div>
        <div className="bg-white p-6 rounded-lg shadow-md text-center">
          <h3 className="text-xl font-semibold mb-2">Scheduled Sleep Studies</h3>
          <p className="text-4xl font-bold mb-4">{sleepStudyCount}</p>
          <Link to="/admin/sleep-studies" className="text-blue-500 font-semibold hover:underline">View Sleep Studies</Link>
        </div>
        <div className="bg-white p-6 rounded-lg shadow-md text-center">
          <h3 className="text-xl font-semibold mb-2">Doctor Consultations</h3>
          <p className="text-4xl font-bold mb-4">{consultationCount}</p>
          <Link to="/admin/consultations" className="text-blue-500 font-semibold hover:underline">View Consultations</Link>
        </div>
        <div className="bg-white p-6 rounded-lg shadow-md text-center">
          <h3 className="text-xl font-semibold mb-2">Devices Managed</h3>
          <p className="text-4xl font-bold mb-4">{deviceCount}</p>
          <Link to="/admin/devices" className="text-blue-500 font-semibold hover:underline">View Devices</Link>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;