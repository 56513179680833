// src/api/BlogApi.ts
import axiosInstance from "./AxiosInstance";
import { BlogListResponse, BlogPost } from "../features/blogs/types/BlogTypes"; // Import updated types

/**
 * Fetches a list of published blog posts from the backend.
 * Supports pagination.
 * @param page - The page number to fetch.
 * @param limit - The number of blogs per page.
 * @returns Promise resolving to the API response containing blogs and pagination info.
 */
export const getBlogsList = (page: number = 1, limit: number = 10) => {
    return axiosInstance.get<BlogListResponse>('/blog', {
        params: { page, limit } // Send pagination params as query string
    });
};

/**
 * Fetches a single published blog post by its slug.
 * @param slug - The unique slug of the blog post.
 * @returns Promise resolving to the API response containing the full blog post data.
 */
export const getBlogPostBySlug = (slug: string) => {
    // Ensure slug is properly encoded if it might contain special characters
    const encodedSlug = encodeURIComponent(slug);
    return axiosInstance.get<BlogPost>(`/blog/${encodedSlug}`);
};

// --- Remove or comment out old/unused functions if any ---
// export const getBlogs = () => { // Example: Old function to remove
//     return axiosInstance.get<BlogEntry[]>('/blogs');
// };