// src/home/BlogsCarousel.tsx
import React, { useState, useEffect } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Link } from 'react-router-dom';
// Remove the direct JSON import:
// import blogEntries from '../features/blogs/components/BlogEntries.json';
import { BlogListItem } from '../features/blogs/types/BlogTypes'; // Import updated type
import { fetchBlogsList } from '../features/blogs/services/BlogService'; // Import updated service

// Keep the chunking utility function
const chunkArray = (myArray: BlogListItem[], chunk_size: number): BlogListItem[][] => {
    const results: BlogListItem[][] = [];
    const arrayCopy = [...myArray]; // Create a copy to avoid modifying the original state directly
    while (arrayCopy.length) {
        results.push(arrayCopy.splice(0, chunk_size));
    }
    return results;
};

const BlogsCarousel: React.FC = () => {
    const [blogs, setBlogs] = useState<BlogListItem[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const blogsPerSlide = 3; // Number of blogs per carousel slide
    const blogsToFetch = 9; // Fetch enough blogs for a few slides, adjust as needed

    useEffect(() => {
        const loadFeaturedBlogs = async () => {
            setLoading(true);
            setError(null);
            try {
                // Fetch the first 'blogsToFetch' blogs (page 1, limit blogsToFetch)
                const response = await fetchBlogsList(1, blogsToFetch);
                setBlogs(response.blogs);
            } catch (err) {
                setError('Failed to load featured blogs.');
                console.error("Error fetching blogs for carousel:", err);
                setBlogs([]); // Set empty array on error
            } finally {
                setLoading(false);
            }
        };

        loadFeaturedBlogs();
    }, []); // Fetch only once on mount

    // Group fetched blogs for the carousel slides
    const groupedBlogEntries = chunkArray(blogs, blogsPerSlide);

    return (
        <section id="featured-blogs" className="py-12 bg-white">
            <div className="container mx-auto px-4">
                <h2 className="text-3xl font-bold text-center text-gray-900 mb-8">
                    Featured Blogs
                </h2>

                {loading && <div className="text-center">Loading blogs...</div>}
                {error && <div className="text-center text-red-600">{error}</div>}

                {!loading && !error && blogs.length > 0 && (
                    <Carousel
                        showThumbs={false}
                        showStatus={false} // Hide status indicator
                        infiniteLoop
                        useKeyboardArrows
                        autoPlay
                        interval={5000} // Change slide every 5 seconds
                        className="custom-carousel" // Add a class for potential custom styling
                    >
                        {groupedBlogEntries.map((group, index) => (
                            <div key={index} className="flex flex-wrap justify-center -mx-2"> {/* Use negative margin to counteract padding */}
                                {group.map((blog) => (
                                    <div key={blog._id} className="w-full sm:w-1/2 lg:w-1/3 px-2 mb-4"> {/* Add padding and bottom margin */}
                                        <Link
                                            // Use slug for the link
                                            to={`/blog/${blog.slug}`}
                                            className="block bg-white rounded-lg shadow hover:shadow-lg transition-shadow overflow-hidden h-full border border-gray-200" // Added border
                                        >
                                            <img
                                                // Use featuredImage or a fallback
                                                src={blog.featuredImage || '/images/placeholder.jpg'}
                                                alt={blog.title}
                                                className="w-full h-48 object-cover"
                                                loading="lazy" // Add lazy loading
                                            />
                                            <div className="p-4">
                                                <h3 className="text-lg font-semibold mb-2 text-gray-800 text-left"> {/* Ensure text is left-aligned */}
                                                    {blog.title}
                                                </h3>
                                                {/* Optional: Display excerpt if available */}
                                                {/* {blog.excerpt && <p className="text-sm text-gray-600 mb-3 text-left">{blog.excerpt}</p>} */}
                                                <p className="text-blue-600 hover:underline text-left">Read More</p> {/* Ensure text is left-aligned */}
                                            </div>
                                        </Link>
                                    </div>
                                ))}
                                {/* Add empty divs if a group has less than 3 items to maintain layout */}
                                {Array(blogsPerSlide - group.length).fill(0).map((_, i) => (
                                    <div key={`placeholder-${index}-${i}`} className="w-full sm:w-1/2 lg:w-1/3 px-2 mb-4"></div>
                                ))}
                            </div>
                        ))}
                    </Carousel>
                )}

                {!loading && !error && blogs.length === 0 && (
                    <p className="text-center text-gray-500">No featured blogs available at the moment.</p>
                )}
            </div>
        </section>
    );
};

// Optional: Add some basic CSS for better carousel control styling if needed
// You might need a separate CSS file or use Tailwind's capabilities
/*
.custom-carousel .control-dots .dot {
    background-color: #ccc !important; // Example: customize dot color
}
.custom-carousel .control-dots .dot.selected {
    background-color: #333 !important; // Example: customize selected dot color
}
*/

export default BlogsCarousel;