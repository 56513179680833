import React, { useState } from 'react';
import { assignCustomerToDistributor, checkUserExists, createUser } from '../services/DistributorService';
import { toast } from 'react-toastify';

interface AddCustomerModalProps {
    onClose: () => void;
    onCustomerAdded: () => void;
}

interface FormData {
    userId?: string;
    firstName: string;
    lastName: string;
    email: string;
    mobile: string;
    gender: string;
    items: string[];
    servicePackage: string[];
    shippingAddress: {
        street: string;
        city: string;
        state: string;
        postalCode: string;
        country: string;
    };
    paymentMethod: string;
    notes?: string;
}

const AddCustomerModal: React.FC<AddCustomerModalProps> = ({ onClose, onCustomerAdded }) => {
    const [searchUser, setSearchUser] = useState('');
    const [isCheckingUser, setIsCheckingUser] = useState(false);
    const [userExists, setUserExists] = useState(false);
    // This flag controls whether the form is in "create new" (editable) mode.
    // When false (default or user found), the fields remain non-editable.
    const [showCreateUserForm, setShowCreateUserForm] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const validatePhoneNumber = (phone: string) => {
        const phoneRegex = /^[6-9]\d{9}$/;
        return phoneRegex.test(phone);
    };

    const [formData, setFormData] = useState<FormData>({
        userId: '',
        firstName: '',
        lastName: '',
        email: '',
        mobile: '',
        gender: '',
        items: [],
        servicePackage: [],
        shippingAddress: {
            street: '',
            city: '',
            state: '',
            postalCode: '',
            country: '',
        },
        paymentMethod: '',
        notes: ''
    });

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchUser(e.target.value);
        setUserExists(false);
        setShowCreateUserForm(false);
        setFormData({
            userId: '',
            firstName: '',
            lastName: '',
            email: '',
            mobile: '',
            gender: '',
            items: [],
            servicePackage: [],
            shippingAddress: {
                street: '',
                city: '',
                state: '',
                postalCode: '',
                country: '',
            },
            paymentMethod: '',
            notes: ''
        });
    };

    const handleCheckUser = async () => {
        if (!searchUser) {
          toast.error('Please enter a mobile number to search.');
          return;
        }

        if (!validatePhoneNumber(searchUser)) {
            toast.error('Please enter a valid 10-digit mobile number starting with 6-9');
            return;
        }

        setIsCheckingUser(true);
        try {
            const response = await checkUserExists(searchUser);
            if (response.exists) {
                setUserExists(true);
                // Populate form with found user's details
                setFormData({
                    ...formData,
                    userId: response.user.id,
                    firstName: response.user.firstName,
                    lastName: response.user.lastName,
                    email: response.user.email,
                    mobile: response.user.phoneNumber,
                    gender: response.user.gender
                });
                toast.success('Customer found! Click Add Customer to add the customer to your list.');
                // Keep the fields non-editable
                setShowCreateUserForm(false);
            } else {
                setUserExists(false);
                toast.info('Customer not found. Please create a new customer.');
                // Enable editing on the form fields for new input
                setShowCreateUserForm(true);
            }
        } catch (error) {
            console.error('Error checking customer:', error);
            toast.error('Failed to check customer. Please try again.');
        } finally {
            setIsCheckingUser(false);
        }
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setIsLoading(true);

        const distributorId = localStorage.getItem('distributorId');

        if(!distributorId) {
          toast.error('Error in getting distributor. Please login again.');
          return;
        }

        try {
          let finalUserId = formData.userId;

          if (!userExists) {
            // If customer not found, create the customer and get the new customer's ID
            const newUserResponse = await createUser(formData);
            finalUserId = newUserResponse.data.id;
            setFormData(prevFormData => ({
              ...prevFormData,
              userId: finalUserId
            }));
          }
          if(!finalUserId) {
            toast.error('Error in creating customer.');
            return;
          }

          await assignCustomerToDistributor(finalUserId, distributorId);
          toast.success('Customer created successfully and added to your list!');
          onCustomerAdded();
          onClose();
        }
        catch (error) {
            console.error('Error creating customer:', error);
            toast.error('Failed to create customer');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white p-6 rounded-lg shadow-xl max-w-2xl w-full">
                <form onSubmit={handleSubmit} className="space-y-4">
                    <div>
                        <h4 className="text-md font-semibold mb-2">Search Existing Customer</h4>
                        <div className="flex items-center">
                            <input
                                type="text"
                                value={searchUser}
                                onChange={handleSearchChange}
                                className="border border-gray-300 rounded-md p-2 flex-grow mr-2"
                                placeholder="Enter mobile number"
                            />
                            <button
                                type="button"
                                onClick={handleCheckUser}
                                disabled={isCheckingUser}
                                className={`px-4 py-2 rounded-md text-white transition-colors ${
                                    isCheckingUser ? 'bg-gray-500 cursor-not-allowed' : 'bg-darkGreen hover:bg-darkGreen-light'
                                }`}
                            >
                                {isCheckingUser ? 'Checking...' : 'Check Customer'}
                            </button>
                        </div>
                        {userExists && (
                            <p className="text-sm text-green-600 mt-1">Customer found! Details loaded below.</p>
                        )}
                    </div>

                    <hr className="my-6 border-gray-300" />

                    {/* Complete Customer Form Section */}
                    <h4 className="text-md font-semibold mb-2">Customer Details</h4>
                    <div className="grid grid-cols-2 gap-4">
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">First Name</label>
                            <input
                                type="text"
                                name="firstName"
                                value={formData.firstName}
                                onChange={handleInputChange}
                                disabled={!showCreateUserForm}
                                className="border border-gray-300 rounded-md p-2 w-full"
                                required
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">Last Name</label>
                            <input
                                type="text"
                                name="lastName"
                                value={formData.lastName}
                                onChange={handleInputChange}
                                disabled={!showCreateUserForm}
                                className="border border-gray-300 rounded-md p-2 w-full"
                                required
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">Email</label>
                            <input
                                type="email"
                                name="email"
                                value={formData.email}
                                onChange={handleInputChange}
                                disabled={!showCreateUserForm}
                                className="border border-gray-300 rounded-md p-2 w-full"
                                required
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">Mobile Number</label>
                            <input
                                type="tel"
                                name="mobile"
                                value={formData.mobile}
                                onChange={handleInputChange}
                                disabled={!showCreateUserForm}
                                className="border border-gray-300 rounded-md p-2 w-full"
                                maxLength={10}
                                required
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">Gender</label>
                            <select
                                name="gender"
                                value={formData.gender}
                                onChange={handleInputChange}
                                disabled={!showCreateUserForm}
                                className="border border-gray-300 rounded-md p-2 w-full"
                                required
                            >
                                <option value="">Select Gender</option>
                                <option value="male">Male</option>
                                <option value="female">Female</option>
                                <option value="other">Other</option>
                            </select>
                        </div>
                    </div>

                    <div className="flex justify-end space-x-3 pt-4">
                        <button
                            type="button"
                            onClick={onClose}
                            className="px-4 py-2 text-gray-600 hover:text-gray-800"
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className="px-4 py-2 bg-darkGreen text-white rounded-md hover:bg-darkGreen-light disabled:opacity-50"
                            disabled={isLoading}
                        >
                            {isLoading ? "Processing..." : userExists ? "Add Customer" : "Create Customer"}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default AddCustomerModal;