import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import SleepCenterLayout from '../SleepCenterLayout';
import { fetchSleepCenterDetails, updateSleepCenterOfferings } from '../services/SleepCenterService';
import AddOfferingModal from './AddOfferingModal';

interface Offering {
    name: string;
    description: string;
    pricing: number;
}

const SleepCenterOfferings: React.FC = () => {
    const [offerings, setOfferings] = useState<Offering[]>([]);
    const [newOffering, setNewOffering] = useState<Offering>({
        name: '',
        description: '',
        pricing: 0
    });
    const [editIndex, setEditIndex] = useState<number | null>(null);
    const sleepCenterId = localStorage.getItem('sleepCenterId') || '';
    const [showAddModal, setShowAddModal] = useState(false);

    useEffect(() => {
        const fetchOfferings = async () => {
            try {
                const response = await fetchSleepCenterDetails(sleepCenterId);
                const formattedOfferings = (response.offerings || []).map(offering => ({
                    name: offering.name,
                    pricing: offering.pricing,
                    description: (offering as any).description || ''
                }));
                setOfferings(formattedOfferings);
            } catch (error) {
                console.error('Error fetching offerings:', error);
                toast.error('Failed to fetch offerings');
            }
        };
        fetchOfferings();
    }, [sleepCenterId]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        if (editIndex !== null) {
            const updatedOfferings = [...offerings];
            updatedOfferings[editIndex] = {
                ...updatedOfferings[editIndex],
                [name]: name === 'pricing' ? Number(value) : value
            };
            setOfferings(updatedOfferings);
        } else {
            setNewOffering(prev => ({
                ...prev,
                [name]: name === 'pricing' ? Number(value) : value
            }));
        }
    };

    const handleAddOffering = async () => {
        if (!newOffering.name || !newOffering.description || !newOffering.pricing) {
            toast.error('Please fill in all fields');
            return;
        }

        try {
            const updatedOfferings = [...offerings, newOffering];
            await updateSleepCenterOfferings(sleepCenterId, updatedOfferings);
            setOfferings(updatedOfferings);
            setNewOffering({ name: '', description: '', pricing: 0 });
            toast.success('Offering added successfully');
        } catch (error) {
            console.error('Error adding offering:', error);
            toast.error('Failed to add offering');
        }
    };

    const handleEditOffering = async () => {
        if (editIndex === null) return;

        try {
            await updateSleepCenterOfferings(sleepCenterId, offerings);
            setEditIndex(null);
            toast.success('Offering updated successfully');
        } catch (error) {
            console.error('Error updating offering:', error);
            toast.error('Failed to update offering');
        }
    };

    const handleDeleteOffering = async (index: number) => {
        try {
            const updatedOfferings = offerings.filter((_, i) => i !== index);
            await updateSleepCenterOfferings(sleepCenterId, updatedOfferings);
            setOfferings(updatedOfferings);
            toast.success('Offering deleted successfully');
        } catch (error) {
            console.error('Error deleting offering:', error);
            toast.error('Failed to delete offering');
        }
    };

    return (
        <SleepCenterLayout>
            <div className="p-8 max-w-6xl mx-auto">
                <div className="bg-white rounded-xl shadow-xl">
                    {/* Header */}
                    <div className="p-6 border-b border-gray-200">
                        <div className="flex justify-between items-center">
                            <div>
                                <h2 className="text-2xl font-bold text-gray-800">Manage Offerings</h2>
                                <p className="mt-2 text-gray-600">Create and manage your sleep study offerings</p>
                            </div>
                            <button
                                onClick={() => setShowAddModal(true)}
                                className="bg-darkGreen hover:bg-darkGreen-light text-white px-4 py-2 rounded-md transition duration-200"
                            >
                                Add Offering
                            </button>
                        </div>
                    </div>

                    {/* Existing Offerings */}
                    <div className="p-6">
                        <h3 className="text-xl font-semibold text-gray-800 mb-4">Current Offerings</h3>
                        <div className="space-y-4">
                            {offerings.length === 0 ? (
                                <p className="text-gray-500 text-center py-8">No offerings available. Add your first offering below.</p>
                            ) : (
                                offerings.map((offering, index) => (
                                    <div key={index} className="border border-gray-200 rounded-lg p-6 bg-white hover:shadow-md transition-shadow duration-200">
                                        {editIndex === index ? (
                                            // Edit Mode
                                            <div className="space-y-4">
                                                <input
                                                    type="text"
                                                    name="name"
                                                    value={offering.name}
                                                    onChange={handleInputChange}
                                                    className="w-full border border-gray-300 rounded-md p-2 focus:ring-2 focus:ring-darkGreen focus:border-transparent"
                                                    placeholder="Offering Name"
                                                />
                                                <textarea
                                                    name="description"
                                                    value={offering.description}
                                                    onChange={handleInputChange}
                                                    className="w-full border border-gray-300 rounded-md p-2 focus:ring-2 focus:ring-darkGreen focus:border-transparent"
                                                    placeholder="Description"
                                                    rows={3}
                                                />
                                                <input
                                                    type="number"
                                                    name="pricing"
                                                    value={offering.pricing}
                                                    onChange={handleInputChange}
                                                    className="w-full border border-gray-300 rounded-md p-2 focus:ring-2 focus:ring-darkGreen focus:border-transparent"
                                                    placeholder="Price"
                                                />
                                                <div className="flex justify-end gap-3">
                                                    <button
                                                        onClick={() => setEditIndex(null)}
                                                        className="px-4 py-2 text-gray-600 hover:text-gray-800 font-medium"
                                                    >
                                                        Cancel
                                                    </button>
                                                    <button
                                                        onClick={handleEditOffering}
                                                        className="flex items-center gap-2 px-4 py-2 bg-darkGreen hover:bg-darkGreen-light text-white rounded-md transition duration-200"
                                                    >
                                                        Save Changes
                                                    </button>
                                                </div>
                                            </div>
                                        ) : (
                                            // View Mode
                                            <div className="flex justify-between items-center">
                                                <div>
                                                    <h4 className="text-lg font-semibold text-gray-800">{offering.name}</h4>
                                                    <p className="text-gray-600 mt-2">{offering.description}</p>
                                                    <p className="text-darkGreen font-semibold mt-3">₹{offering.pricing}</p>
                                                </div>
                                                <div className="flex gap-3">
                                                    <button
                                                        onClick={() => setEditIndex(index)}
                                                        className="px-4 py-2 bg-darkGreen hover:bg-darkGreen-light text-white rounded-md transition duration-200"
                                                    >
                                                        Edit
                                                    </button>
                                                    <button
                                                        onClick={() => handleDeleteOffering(index)}
                                                        className="px-4 py-2 bg-red-600 hover:bg-red-700 text-white rounded-md transition duration-200"
                                                    >
                                                        Delete
                                                    </button>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                ))
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <AddOfferingModal
                isOpen={showAddModal}
                onClose={() => setShowAddModal(false)}
                offering={newOffering}
                onInputChange={handleInputChange}
                onSubmit={() => {
                    handleAddOffering();
                    setShowAddModal(false);
                }}
            />
        </SleepCenterLayout>
    );
};

export default SleepCenterOfferings;