// features/sleepCenter/components/SleepCenterProfile.tsx
import React, { useState, useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SleepCenterLayout from '../SleepCenterLayout';
import { fetchSleepCenterDetails, updateSleepCenterDetails } from '../services/SleepCenterService';
import { SleepCenter } from '../types/SleepCenterTypes';
import axios from 'axios';

const SleepCenterProfile: React.FC = () => {
    const [isEditing, setIsEditing] = useState(false);
    const [loading, setLoading] = useState(true);
    const sleepCenterId = localStorage.getItem('sleepCenterId') || '';
    const [sleepCenter, setSleepCenter] = useState<SleepCenter>({
        _id: '',
        name: '', 
        address: '', 
        contactNumber: '',
        email: '', 
        offerings: [],
    });
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchCenterDetails = async () => {
            try {
                setLoading(true);
                const response = await fetchSleepCenterDetails(sleepCenterId);
                setSleepCenter(response);
                setError('');
            } catch (error: any) {
                setError(error.message || 'Failed to load sleep center details');
                console.error('Error fetching sleep center:', error);
            } finally {
                setLoading(false);
            }
        };
        
        fetchCenterDetails();
    }, [sleepCenterId]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setSleepCenter(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setIsEditing(false);
        
        try {
            const response = await updateSleepCenterDetails(sleepCenterId, {
                name: sleepCenter.name,
                email: sleepCenter.email,
                address: sleepCenter.address,
                contactNumber: sleepCenter.contactNumber,
                offerings: sleepCenter.offerings
            });

            if (response.status === 200) {
                toast.success('Profile updated successfully!');
                fetchSleepCenterDetails(sleepCenterId)
                    .then((response) => setSleepCenter(response));
            }
        } catch (error: any) {
            let message = 'Failed to update profile. Please try again.';
            if (axios.isAxiosError(error) && error.response) {
                message = error.response.data.message || message;
            }
            toast.error(message);
            console.error('Error updating profile', error);
        }
    };

    if (loading) {
        return (
            <SleepCenterLayout>
                <div className="flex justify-center items-center h-full">
                    <p>Loading...</p>
                </div>
            </SleepCenterLayout>
        );
    }

    if (error) {
        return (
            <SleepCenterLayout>
                <div className="flex justify-center items-center h-full">
                    <p>Error loading Profile Details.</p>
                </div>
            </SleepCenterLayout>
        );
    }

    return (
        <SleepCenterLayout>
            <ToastContainer />
            <div className="p-6 bg-white rounded-lg shadow-md max-w-3xl mx-auto">
                <div className="flex justify-between items-center mb-6">
                    <h2 className="text-2xl font-bold text-gray-800">My Profile</h2>
                    {!isEditing && (
                        <button
                            onClick={() => setIsEditing(true)}
                            className="bg-darkGreen hover:bg-darkGreen-light text-white px-4 py-2 rounded-md transition-colors"
                        >
                            Edit Profile
                        </button>
                    )}
                </div>

                <form onSubmit={handleSubmit} className="space-y-6">
                    <div>
                        <label htmlFor="name" className="text-sm font-medium text-gray-700">
                            Center Name
                        </label>
                        <input
                            type="text"
                            name="name"
                            value={sleepCenter.name}
                            onChange={handleInputChange}
                            disabled={!isEditing}
                            className={`mt-1 px-3 py-2 border rounded-md block w-full ${
                                isEditing
                                    ? 'border-gray-300 focus:border-indigo-500 focus:ring-indigo-500'
                                    : 'bg-gray-100 text-gray-700'
                            }`}
                            required
                        />
                    </div>

                    <div>
                        <label htmlFor="email" className="text-sm font-medium text-gray-700">
                            Email Address
                        </label>
                        <input
                            type="email"
                            name="email"
                            value={sleepCenter.email}
                            onChange={handleInputChange}
                            disabled={!isEditing}
                            className={`mt-1 px-3 py-2 border rounded-md block w-full ${
                                isEditing
                                    ? 'border-gray-300 focus:border-indigo-500 focus:ring-indigo-500'
                                    : 'bg-gray-100 text-gray-700'
                            }`}
                            required
                        />
                    </div>

                    <div>
                        <label htmlFor="contactNumber" className="text-sm font-medium text-gray-700">
                            Phone Number
                        </label>
                        <input
                            type="tel"
                            name="contactNumber"
                            value={sleepCenter.contactNumber}
                            onChange={handleInputChange}
                            disabled={!isEditing}
                            className={`mt-1 px-3 py-2 border rounded-md block w-full ${
                                isEditing
                                    ? 'border-gray-300 focus:border-indigo-500 focus:ring-indigo-500'
                                    : 'bg-gray-100 text-gray-700'
                            }`}
                            required
                        />
                    </div>

                    <div>
                        <label htmlFor="address" className="text-sm font-medium text-gray-700">
                            Address
                        </label>
                        <input
                            type="text"
                            name="address"
                            value={sleepCenter.address}
                            onChange={handleInputChange}
                            disabled={!isEditing}
                            className={`mt-1 px-3 py-2 border rounded-md block w-full ${
                                isEditing
                                    ? 'border-gray-300 focus:border-indigo-500 focus:ring-indigo-500'
                                    : 'bg-gray-100 text-gray-700'
                            }`}
                            required
                        />
                    </div>

                    {/* Action Buttons */}
                    {isEditing && (
                        <div className="flex justify-end mt-4 space-x-3">
                            <button
                                type="button"
                                onClick={() => setIsEditing(false)}
                                className="px-4 py-2 bg-gray-100 text-gray-700 rounded-md hover:bg-gray-200 transition-colors"
                            >
                                Cancel
                            </button>
                            <button
                                type="submit"
                                className="px-4 py-2 bg-darkGreen text-white rounded-md hover:bg-darkGreen-light transition-colors"
                            >
                                Save Changes
                            </button>
                        </div>
                    )}
                </form>
            </div>
        </SleepCenterLayout>
    );
};

export default SleepCenterProfile;