import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
    fetchPatientDetails,
    orderSleepStudy,
    requestSelfAssessment,
    requestConsultation,
    unassignPatient,
    fetchDoctorHospitals,
    getSignedReportUrl,
    uploadPrescription,
    getSignedPrescriptionUrl,
    assignPatient,
    fetchAllDoctors
} from '../services/DoctorService';
import { fetchAvailableSleepCenters } from '../services/DoctorService';
import { PatientDetails, Doctor } from '../types/DoctorTypes';
import { format } from 'date-fns';
import DoctorLayout from './DoctorLayout';
import 'react-datepicker/dist/react-datepicker.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaEye } from 'react-icons/fa';
import QuizDetailsModal from './QuizDetailsModal';
import { Hospital } from '../../hospital/types/HospitalTypes';

interface SleepCenter {
    _id: string;
    name: string;
    offerings: {
        name: string;
        pricing: number;
    }[];
}

const DoctorPatientRecord: React.FC = () => {
    const { patientId } = useParams<{ patientId: string }>();
    const [patientDetails, setPatientDetails] = useState<PatientDetails | null>(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const navigate = useNavigate();
    const doctorId = localStorage.getItem('doctorId') || '';
    const [, setHospitals] = useState<Hospital[]>([]);
    const [selectedSleepCenter, setSelectedSleepCenter] = useState<string>('');
    const [sleepCenters, setSleepCenters] = useState<SleepCenter[]>([]);
    const [selectedOffering, setSelectedOffering] = useState<string>('');
    const [filteredOfferings, setFilteredOfferings] = useState<any[]>([]);



    const [, setSelectedSleepStudyType] = useState<string>('');
    const [orderingStudy, setOrderingStudy] = useState(false);
    const [selectedQuiz, setSelectedQuiz] = useState<any>(null);
    const [showQuizModal, setShowQuizModal] = useState(false);
    const [openSection, setOpenSection] = useState({
        selfAssessments: true,
        sleepStudies: true,
        prescriptions: true
    });
    const [showUnassignModal, setShowUnassignModal] = useState(false);
    const [showReportModal, setShowReportModal] = useState(false);
    const [selectedReport, setSelectedReport] = useState<string | null>(null);
    const [showPrescriptionModal, setShowPrescriptionModal] = useState(false);
    const [selectedPrescriptionFile, setSelectedPrescriptionFile] = useState<File | null>(null);

    // NEW STATE VARIABLES
    const [doctors, setDoctors] = useState<Doctor[]>([]); // To hold the list of doctors
    const [selectedDoctorId, setSelectedDoctorId] = useState(''); // To hold the selected doctor's ID
    const [isAssigning, setIsAssigning] = useState(false); // To track the assigning process
    const [showAssignModal, setShowAssignModal] = useState(false);

    const fetchSleepCenters = useCallback(async () => {
        try {
            const response = await fetchAvailableSleepCenters();
            setSleepCenters(response.data || []);
        } catch (error: any) {
            console.error('Error fetching available sleep centers:', error);
            toast.error(error.response?.data?.message || 'Could not fetch sleep centers');
        }
    }, []);



    useEffect(() => {
        const fetchHospitals = async () => {
            try {
                const response = await fetchDoctorHospitals(doctorId);
                setHospitals(response);
            } catch (error: any) {
                console.error('Error fetching associated hospitals:', error);
                toast.error(error.response?.data?.message || 'Could not fetch hospitals');
            }
        };

        fetchSleepCenters();
        fetchHospitals();
        const fetchPatientData = async () => {
            if (!patientId) {
                setError("Patient ID is missing");
                setLoading(false);
                return;
            }
            try {
                setLoading(true);
                const details = await fetchPatientDetails(patientId);
                setPatientDetails(details);
                setError(null);
            } catch (err) {
                setError("Failed to fetch patient details.");
                console.error(err);

            } finally {
                setLoading(false)
            }
        }
        fetchPatientData()
    }, [doctorId, patientId, fetchSleepCenters]);


      //NEW USE EFFECT
      useEffect(() => {
        const fetchDoctors = async () => {
          try {
            const doctors = await fetchAllDoctors();
            const filteredDoctors = doctors.filter((doc: { id: string | null; }) => doc.id !== doctorId);
            setDoctors(filteredDoctors);
          } catch (error) {
            console.error('Error fetching doctors:', error);
            toast.error('Failed to load doctors');
          }
        };
        fetchDoctors();
      }, [doctorId]);


    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        if (name === 'sleepCenter') {
            setSelectedSleepCenter(value);
            const selectedCenter = sleepCenters.find(center => center._id === value);
            if (selectedCenter) {
                setFilteredOfferings(selectedCenter.offerings);
                setSelectedOffering('');
            }
        } else if (name === 'offering') {
            setSelectedOffering(value);
        } else {
            setSelectedSleepStudyType(value);
        }
    };

    const handleOrderSleepStudy = async () => {
        if (!selectedSleepCenter || !selectedOffering) {
            toast.error('Please select a sleep center and offering.');
            return;
        }
        setOrderingStudy(true);
        try {
            const doctorId = localStorage.getItem('doctorId');
            if (!doctorId || !patientDetails) {
                toast.error('Missing required information');
                return;
            }
            const response = await orderSleepStudy({
                doctorId,
                patientId: patientDetails._id,
                sleepCenterId: selectedSleepCenter,
                sleepStudyType: selectedOffering
            });
            if (response.status === 201) {
                toast.success('Sleep study ordered successfully. Notifications sent to the patient and YourNidra team.');
                setSelectedSleepCenter('');
                setFilteredOfferings([]);
                const details = await fetchPatientDetails(patientId!);
                setPatientDetails(details);
            }
        } catch (error: any) {
            console.error('Error ordering sleep study:', error);
            toast.error(error.response?.data?.message || 'Failed to order sleep study');
        } finally {
            setOrderingStudy(false);
        }
    };

    const handleRequestQuiz = async () => {
        try {
           const res = await requestSelfAssessment(doctorId, patientId!);
           if(res.status === 200){
             toast.success('Assessment request sent to patient via email and WhatsApp');
           }
           else {
              toast.error(res.data.message || 'Failed to send assessment request');
           }
            // Refresh patient details            
            const details = await fetchPatientDetails(patientId!);
            setPatientDetails(details);
        } catch (error: any) {
            console.error('Error requesting quiz:', error);
            toast.error(error.response?.data?.message || 'Failed to send assessment request');
        }
    };

    const handleRequestConsultation = async () => {
        try {
            const res = await requestConsultation(doctorId, patientId!)
           if(res.status === 200){
            toast.success('Consultation request sent successfully!');
           } else {
            toast.error(res.data.message || 'Failed to send consultation request');
           }
            fetchPatientDetails(patientId!).then((details) => setPatientDetails(details));
        } catch (error) {
            console.error('Failed to request consultation:', error);
            alert('Failed to request consultation. Please try again.');
        }
    };
    const handleUnassignPatient = async () => {
        try {
           const res = await unassignPatient(doctorId, patientId!)
           if(res.status === 200){
                toast.success('Patient unassigned successfully.');
                navigate('/doctor/manage-patients');
           } else{
                toast.error(res.data.message || 'Failed to unassign patient. Please try again.');
           }

        } catch (error) {
            console.error('Error unassigning patient:', error);
            toast.error('Failed to unassign patient. Please try again.');
        }
    };
    const toggleSection = (section: keyof typeof openSection) => {
        setOpenSection(prev => ({
            ...prev,
            [section]: !prev[section]
        }));
    };

    const handleUploadPrescription = async () => {
        if (!selectedPrescriptionFile) return;

        try {
            const formData = new FormData();
            formData.append('prescription', selectedPrescriptionFile);
            formData.append('doctorId', doctorId);
            formData.append('userId', patientId!);

            const response = await uploadPrescription(formData);

            console.log(response)
            if (response.status === 201) {
                toast.success('Prescription uploaded successfully');
                setShowPrescriptionModal(false);
                setSelectedPrescriptionFile(null);
                const details = await fetchPatientDetails(patientId!);
                setPatientDetails(details);
            }
        } catch (error) {
            console.error('Error uploading prescription:', error);
            toast.error('Failed to upload prescription');
        }
    };

    const viewPrescription = async (prescriptionUrl: string) => {
        try {
            const data = await getSignedPrescriptionUrl(prescriptionUrl);
            if (!data.signedUrl) {
                throw new Error('No signed URL received');
            }
            window.open(data.signedUrl, '_blank');
        } catch (error) {
            console.error('Error accessing prescription:', error);
            toast.error('Unable to access the prescription');
        }
    };

    if (loading) return <div>Loading patient record...</div>;
    if (error) return <div>Error: {error}</div>;
    if (!patientDetails) return <div>Patient not found.</div>;

    const formatDate = (dateString: string | undefined) => {
        if (!dateString) return 'N/A';
        return format(new Date(dateString), 'MMM dd, yyyy');
    };

    const displayOptional = (value: string | null | undefined) => {
        return value || 'N/A';
    };

    const capitalizeFirstLetter = (str: string | null | undefined): string => {
        if (!str) return 'N/A';
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    };

    const getStatusBadgeClass = (status: string) => {
        return status === 'Completed' ? 'bg-green-100 text-green-800 px-3 py-1 rounded-full text-sm font-medium' :
               status === 'Scheduled' ? 'bg-yellow-100 text-yellow-800 px-3 py-1 rounded-full text-sm font-medium' :
               status === 'Cancelled' ? 'bg-red-100 text-red-800 px-3 py-1 rounded-full text-sm font-medium' :
               'bg-blue-100 text-blue-800 px-3 py-1 rounded-full text-sm font-medium';
    };

    const viewReport = async (reportUrl: string) => {
        try {
            const data = await getSignedReportUrl(reportUrl);
            if (!data.signedUrl) {
                throw new Error('No signed URL received');
            }
            window.open(data.signedUrl, '_blank');
        } catch (error) {
            console.error('Error accessing report:', error);
            toast.error('Unable to access the report');
        }
    };
//NEW
    const handleAssignPatient = async () => {
        if (!selectedDoctorId) {
            toast.error('Please select a doctor to assign the patient to.');
            return;
        }

        setIsAssigning(true);
        try {
            await assignPatient(doctorId, patientId!, selectedDoctorId);
            toast.success('Patient referred successfully!');
            setShowAssignModal(false);  // Close modal
            setSelectedDoctorId('');    // Reset selection
            
            // Refresh patient details instead of navigating
            const details = await fetchPatientDetails(patientId!);
            setPatientDetails(details);
        } catch (error: any) {
            toast.error(error.response?.data?.message || 'Failed to refer patient.');
        } finally {
            setIsAssigning(false);
        }
    };

    return (
        <DoctorLayout>
            <ToastContainer />
            <div className="p-6 bg-white rounded-lg shadow-md max-w-4xl mx-auto">
                <div className="flex justify-between items-center mb-6">
                    <h2 className="text-2xl font-bold text-gray-800">Patient Record</h2>
                </div>

                {/* Patient Information Section */}
                <div className="grid grid-cols-2 gap-6 mb-8">
                    <div className="space-y-4">
                        <div>
                            <label className="text-sm text-gray-600">Name</label>
                            <p className="font-medium text-gray-800">
                                {patientDetails.firstName} {patientDetails.lastName}
                            </p>
                        </div>
                        <div>
                            <label className="text-sm text-gray-600">Email</label>
                            <p className="font-medium text-gray-800">{patientDetails.email}</p>
                        </div>
                        <div>
                            <label className="text-sm text-gray-600">Phone Number</label>
                            <p className="font-medium text-gray-800">
                                {displayOptional(patientDetails.phoneNumber)}
                            </p>
                        </div>
                    </div>

                    <div className="space-y-4">
                        <div>
                            <label className="text-sm text-gray-600">Date of Birth</label>
                            <p className="font-medium text-gray-800">
                                {formatDate(patientDetails.dateOfBirth)}
                            </p>
                        </div>
                        <div>
                            <label className="text-sm text-gray-600">Gender</label>
                            <p className="font-medium text-gray-800">
                                {capitalizeFirstLetter(patientDetails.gender)}
                            </p>
                        </div>
                    </div>
                </div>

                {/* Action Buttons */}
                <div className="flex justify-end gap-4 mb-8">
                    <button
                        onClick={handleRequestQuiz}
                        className="bg-darkGreen hover:bg-darkGreen-light text-white px-4 py-2 rounded-md transition-colors"
                    >
                        Request Self Assessment
                    </button>

                    <button
                        onClick={handleRequestConsultation}
                        className="bg-darkGreen hover:bg-darkGreen-light text-white px-4 py-2 rounded-md transition-colors"
                    >
                        Request Consultation
                    </button>

                    <button
                        onClick={() => setShowAssignModal(true)}
                        className="bg-darkGreen hover:bg-darkGreen-light text-white px-4 py-2 rounded-md transition-colors"
                    >
                        Refer Patient to Doctor
                    </button>
                </div>

                {/* Self Assessments Section */}
                <div className="mb-8">
                    <div
                        onClick={() => toggleSection('selfAssessments')}
                        className="flex justify-between items-center cursor-pointer bg-gray-50 p-4 rounded-t-lg hover:bg-gray-100"
                    >
                        <h3 className="text-xl font-semibold text-gray-800">Self Assessments</h3>
                        <svg
                            className={`w-6 h-6 transform transition-transform ${openSection.selfAssessments ? 'rotate-180' : ''}`}
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                        >
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                        </svg>
                    </div>

                    {openSection.selfAssessments && (
                        <div className="border border-t-0 rounded-b-lg p-4">
                            {patientDetails.quizData.length > 0 ? (
                                <div className="bg-white rounded-lg">
                                    {patientDetails.quizData
                                        .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
                                        .map((quiz, index) => (
                                            <div key={`quiz-${quiz._id || index}`} className="mb-3 p-3 bg-gray-50 rounded shadow-sm flex justify-between items-center">
                                                <div>
                                                    <p className="text-gray-700">Date: {formatDate(quiz.createdAt)}</p>
                                                    <p className="text-gray-700">Risk Level: {quiz.riskLevel}</p>
                                                </div>
                                                <button
                                                    onClick={() => {
                                                        setSelectedQuiz(quiz);
                                                        setShowQuizModal(true);
                                                    }}
                                                    className="p-2 text-darkGreen hover:text-darkGreen-light transition-colors"
                                                    title="View Details"
                                                >
                                                    <FaEye className="w-5 h-5" />
                                                </button>
                                            </div>
                                        ))}
                                </div>
                            ) : (
                                <p className="text-gray-500">No self assessments taken yet.</p>
                            )}
                        </div>
                    )}
                </div>

                {/* Sleep Studies Section */}
                <div className="mb-6">
                    <div
                        onClick={() => toggleSection('sleepStudies')}
                        className="flex justify-between items-center cursor-pointer bg-gray-50 p-4 rounded-t-lg hover:bg-gray-100"
                    >
                        <h3 className="text-xl font-semibold text-gray-800">Sleep Studies</h3>
                        <svg
                            className={`w-6 h-6 transform transition-transform ${openSection.sleepStudies ? 'rotate-180' : ''}`}
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                        >
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                        </svg>
                    </div>

                    {openSection.sleepStudies && (
                        <div className="border border-t-0 rounded-b-lg p-4">
                            {/* List of Sleep Studies */}
                            {patientDetails.sleepStudyData && patientDetails.sleepStudyData.length > 0 ? (
                                <div className="mb-6">
                                    {patientDetails.sleepStudyData
                                        .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
                                        .map((study, index) => (
                                            <div key={study.id || index} className="mb-3 p-4 bg-gray-50 rounded-lg">
                                                <div className="flex justify-between items-center">
                                                    <div>
                                                        <p className="text-gray-700">{study.sleepStudyType}</p>
                                                        <p className="text-gray-600 text-sm">
                                                            Date: {format(new Date(study.createdAt), 'MMM dd, yyyy')}
                                                        </p>
                                                    </div>
                                                    <div className="flex items-center gap-3">
                                                        {study.status === 'Completed' && study.reportUrl ? (
                                                            <button
                                                                onClick={() => study.reportUrl && viewReport(study.reportUrl)}
                                                                className="p-2 text-darkGreen hover:text-darkGreen-light transition-colors"
                                                                title="View Report"
                                                            >
                                                                <FaEye className="w-5 h-5" />
                                                            </button>
                                                        ) : (
                                                            <span className={getStatusBadgeClass(study.status)}>
                                                                {study.status}
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                </div>
                            ) : (
                                <p className="text-gray-500 mb-6">No sleep studies recorded.</p>
                            )}

                            {/* Order New Sleep Study */}
                            <div className="bg-gray-50 rounded-lg p-4 space-y-4">
                                {/* Sleep Center Selection */}
                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-1">
                                        Available Sleep Centers
                                    </label>
                                    <select
                                        name="sleepCenter"
                                        value={selectedSleepCenter}
                                        onChange={handleInputChange}
                                        className="border border-gray-300 rounded-md p-2 w-full"
                                        required
                                    >
                                        <option value="">Select a sleep center</option>
                                        {sleepCenters.map(center => (
                                            <option key={center._id} value={center._id}>
                                                {center.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>

                                {/* Offerings Selection - Only show if sleep center is selected */}
                                {selectedSleepCenter && (
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 mb-1">
                                            Select Offering
                                        </label>
                                        <select
                                            name="offering"
                                            value={selectedOffering}
                                            onChange={handleInputChange}
                                            className="border border-gray-300 rounded-md p-2 w-full"
                                            required
                                        >
                                            <option value="">Select an Offering</option>
                                            {filteredOfferings.map((offering, index) => (
                                                <option key={index} value={offering.name}>
                                                    {offering.name} - ₹{offering.pricing}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                )}

                                {/* Order Button */}
                                <div className="flex justify-end">
                                    <button
                                        onClick={handleOrderSleepStudy}
                                        disabled={!selectedSleepCenter || !selectedOffering || orderingStudy}
                                        className={`bg-darkGreen hover:bg-darkGreen-light text-white py-2 px-4 rounded-md transition-colors ${orderingStudy ? 'opacity-50 cursor-not-allowed' : ''}`}
                                    >
                                        {orderingStudy ? 'Ordering...' : 'Order Sleep Study'}
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>


                {/* Prescriptions Section */}
                <div className="mb-8">
                    <div
                        onClick={() => toggleSection('prescriptions')}
                        className="flex justify-between items-center cursor-pointer bg-gray-50 p-4 rounded-t-lg hover:bg-gray-100"
                    >
                        <h3 className="text-xl font-semibold text-gray-800">Prescriptions</h3>
                        <svg
                            className={`w-6 h-6 transform transition-transform ${openSection.prescriptions ? 'rotate-180' : ''}`}
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                        >
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                        </svg>
                    </div>

                    {openSection.prescriptions && (
                        <div className="border border-t-0 rounded-b-lg p-4">
                            {/* Prescriptions List */}
                            {patientDetails.prescriptions && patientDetails.prescriptions.length > 0 ? (
                                <div className="mb-6">
                                    {patientDetails.prescriptions
                                        .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
                                        .map((prescription, index) => (
                                            <div key={prescription._id || index} className="mb-3 p-4 bg-gray-50 rounded-lg">
                                                <div className="flex justify-between items-center">
                                                    <div>
                                                        <p className="text-gray-700 font-medium">Prescription {patientDetails.prescriptions.length - index}</p>
                                                        <p className="text-gray-600 text-sm">
                                                            Date: {format(new Date(prescription.createdAt), 'MMM dd, yyyy')}
                                                        </p>
                                                    </div>
                                                    <button
                                                        onClick={() => prescription.fileUrl && viewPrescription(prescription.fileUrl)}
                                                        className="p-2 text-darkGreen hover:text-darkGreen-light transition-colors"
                                                        title="View Prescription"
                                                    >
                                                        <FaEye className="w-5 h-5" />
                                                    </button>
                                                </div>
                                            </div>
                                        ))}
                                </div>
                            ) : (
                                <p className="text-gray-500 mb-6">No prescriptions uploaded yet.</p>
                            )}

                            {/* Upload Button */}
                            <div className="flex justify-end">
                                <button
                                    onClick={() => setShowPrescriptionModal(true)}
                                    className="bg-darkGreen hover:bg-darkGreen-light text-white px-4 py-2 rounded-md transition-colors"
                                >
                                    Upload Prescription
                                </button>
                            </div>
                        </div>
                    )}
                </div>


                {/* Unassign Button */}
                <div className="border-t pt-6 flex justify-end">
                    <button
                        onClick={() => setShowUnassignModal(true)}
                        className="mt-4 bg-red-500 hover:bg-red-700 text-white font-semibold py-2 px-4 rounded"
                    >
                        Unassign Patient
                    </button>
                </div>

                {/* Unassign Confirmation Modal */}
                {showUnassignModal && (
                    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                        <div className="bg-white p-6 rounded-lg shadow-xl max-w-md w-full">
                            <h3 className="text-lg font-semibold mb-4">Confirm Unassign</h3>
                            <p className="text-gray-600 mb-6">
                                Are you sure you want to unassign this patient?
                            </p>
                            <div className="flex justify-end gap-2">
                                <button
                                    onClick={() => setShowUnassignModal(false)}
                                    className="px-4 py-2 text-gray-600 hover:text-gray-800"
                                >
                                    Cancel
                                </button>
                                <button
                                    onClick={handleUnassignPatient}
                                    className="bg-red-600 hover:bg-red-700 text-white px-4 py-2 rounded-md transition-colors"
                                >
                                    Unassign
                                </button>
                            </div>
                        </div>
                    </div>
                )}

                {/* Quiz Modal - Keep this at the root level */}
                {showQuizModal && selectedQuiz && (
                    <QuizDetailsModal
                        quiz={selectedQuiz}
                        onClose={() => {
                            setShowQuizModal(false);
                            setSelectedQuiz(null);
                        }}
                    />
                )}

                {showReportModal && selectedReport && (
                    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                        <div className="bg-white rounded-lg p-6 w-full max-w-4xl h-[80vh]">
                            <div className="flex justify-between items-center mb-4">
                                <h3 className="text-lg font-semibold">Sleep Study Report</h3>
                                <button
                                    onClick={() => {
                                        setShowReportModal(false);
                                        setSelectedReport(null);
                                    }}
                                    className="text-gray-500 hover:text-gray-700"
                                >
                                    <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                </button>
                            </div>
                            <div className="h-full">
                                <iframe
                                    src={selectedReport}
                                    className="w-full h-full rounded-lg"
                                    title="Sleep Study Report"
                                />
                            </div>
                        </div>
                    </div>
                )}

                {/* Prescription Upload Modal */}
                {showPrescriptionModal && (
                    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                        <div className="bg-white rounded-lg p-6 w-full max-w-md">
                            <div className="flex justify-between items-center mb-4">
                                <h3 className="text-lg font-semibold">Upload Prescription</h3>
                                <button
                                    onClick={() => {
                                        setShowPrescriptionModal(false);
                                        setSelectedPrescriptionFile(null);
                                    }}
                                    className="text-gray-500 hover:text-gray-700"
                                >
                                    <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                </button>
                            </div>
                            <div className="space-y-4">
                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-2">
                                        Select Prescription File (PDF)
                                    </label>
                                    <input
                                        type="file"
                                        accept=".pdf"
                                        onChange={(e) => setSelectedPrescriptionFile(e.target.files?.[0] || null)}
                                        className="w-full border border-gray-300 rounded-md p-2"
                                    />
                                </div>
                                <div className="flex justify-end">
                                    <button
                                        onClick={handleUploadPrescription}
                                        disabled={!selectedPrescriptionFile}
                                        className="bg-darkGreen hover:bg-darkGreen-light text-white px-4 py-2 rounded-md transition-colors disabled:opacity-50"
                                    >
                                        Upload
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {/* Assign Patient Modal */}
                {showAssignModal && (
                    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                        <div className="bg-white rounded-lg p-6 w-full max-w-md">
                            <div className="flex justify-between items-center mb-4">
                                <h3 className="text-lg font-semibold">Assign Patient to Doctor</h3>
                                <button
                                    onClick={() => setShowAssignModal(false)}
                                    className="text-gray-500 hover:text-gray-700"
                                >
                                    <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                </button>
                            </div>
                            <div className="space-y-4">
                                <div>
                                    <label htmlFor="doctorSelect" className="block text-sm font-medium text-gray-700 mb-2">
                                        Select Doctor
                                    </label>
                                    <select
                                        id="doctorSelect"
                                        value={selectedDoctorId}
                                        onChange={(e) => setSelectedDoctorId(e.target.value)}
                                        className="w-full border border-gray-300 rounded-md p-2"
                                    >
                                        <option value="">Select a Doctor</option>
                                        {doctors.map((doctor) => (
                                            <option key={doctor._id} value={doctor._id}>
                                                {doctor.firstName} {doctor.lastName} ({doctor.email})
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="flex justify-end gap-3">
                                    <button
                                        onClick={() => setShowAssignModal(false)}
                                        className="px-4 py-2 text-gray-600 hover:text-gray-800"
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        onClick={handleAssignPatient}
                                        disabled={isAssigning || !selectedDoctorId}
                                        className={`bg-darkGreen hover:bg-darkGreen-light text-white px-4 py-2 rounded-md transition-colors ${
                                            isAssigning || !selectedDoctorId ? 'opacity-50 cursor-not-allowed' : ''
                                        }`}
                                    >
                                        {isAssigning ? 'Assigning...' : 'Assign Patient'}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

            </div>
        </DoctorLayout>
    );
};

export default DoctorPatientRecord;