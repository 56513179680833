import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import DistributorLayout from './DistributorLayout';
import { getCustomerDetails } from '../services/DistributorService';
import { uploadPrescription, getSignedPrescriptionUrl, getSignedReportUrl } from '../../doctor/services/DoctorService';
import { toast, ToastContainer } from 'react-toastify';
import { format } from 'date-fns';
import { FaEye } from 'react-icons/fa';  // <-- Import the eye icon
import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';

interface CustomerDetails {
  _id: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  gender: string;
  dateOfBirth: string;
  address: string;
  type: string;
  createdAt: string;
  updatedAt: string;
  distributorId: string[];
  doctorId: string[];
  prescriptions: Array<{
    _id: string;
    fileUrl: string;
    createdAt: string;
  }>;
  quizData: Array<{
    _id: string;
    riskLevel: string;
    createdAt: string;
    answers: any;
  }>;
  sleepStudyData: Array<{
    _id: string;
    sleepStudyType: string;
    status: string;
    reportUrl?: string;
    createdAt: string;
  }>;
  requestFlags: {
    sleepStudy: any;
    selfAssessment: any;
    consultation: any;
  };
  cpapMachine?: {
    model: string;
    serialNumber: string;
    manufacturer: string;
    purchaseDate: string;
    warrantyExpiry: string;
  };
  servicePackage?: {
    name: string;
    startDate: string;
    endDate: string;
    status: string;
    items: Array<{
      name: string;
      quantity: number;
      price: number;
    }>;
  };
}

const CustomerDetail: React.FC = () => {
  const { customerId } = useParams<{ customerId: string }>();
  const [customer, setCustomer] = useState<CustomerDetails | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  // Accordion open/close state
  const [openSection, setOpenSection] = useState({
    selfAssessments: true,
    prescriptions: true,
    sleepStudies: true,
    cpapDevice: true,
    servicePackage: true
  });

  // Prescription upload modal state
  const [showPrescriptionModal, setShowPrescriptionModal] = useState(false);
  const [selectedPrescriptionFile, setSelectedPrescriptionFile] = useState<File | null>(null);

  // Fetch customer details
  const fetchCustomerDetailsCallback = useCallback(async () => {
    try {
      const distributorId = localStorage.getItem('distributorId');
      if (!distributorId) {
        toast.error('Error: Distributor ID not found. Please login again.');
        return;
      }
      if (!customerId) {
        toast.error('Error: Customer ID is required.');
        return;
      }
      const response = await getCustomerDetails(customerId, distributorId);
      setCustomer(response.customer);
    } catch (error) {
      console.error('Error fetching customer details:', error);
      toast.error('Failed to fetch customer details. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  }, [customerId]);

  useEffect(() => {
    fetchCustomerDetailsCallback();
  }, [fetchCustomerDetailsCallback]);

  // Toggle accordion sections
  const toggleSection = (section: keyof typeof openSection) => {
    setOpenSection(prev => ({
      ...prev,
      [section]: !prev[section]
    }));
  };

  // Format date string
  const formatDate = (dateString: string | undefined) => {
    if (!dateString) return 'N/A';
    return format(new Date(dateString), 'MMM dd, yyyy');
  };

  // Capitalize helper
  const capitalizeFirstLetter = (str: string): string => {
    if (!str) return 'N/A';
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  // Handle prescription upload
  const handleUploadPrescription = async () => {
    if (!selectedPrescriptionFile) return;
    try {
      const formData = new FormData();
      formData.append('prescription', selectedPrescriptionFile);

      const distributorId = localStorage.getItem('distributorId');
      if (!distributorId || !customer) {
        toast.error('Missing required information');
        return;
      }

      formData.append('distributorId', distributorId);
      formData.append('customerId', customer._id);

      const response = await uploadPrescription(formData);
      if (response.status === 201) {
        toast.success('Prescription uploaded successfully');
        setShowPrescriptionModal(false);
        setSelectedPrescriptionFile(null);
        // Optionally refresh
        await fetchCustomerDetailsCallback();
      }
    } catch (error) {
      console.error('Error uploading prescription:', error);
      toast.error('Failed to upload prescription');
    }
  };

  // View prescription
  const viewPrescription = async (prescriptionUrl: string) => {
    try {
      const data = await getSignedPrescriptionUrl(prescriptionUrl);
      if (!data.signedUrl) {
        throw new Error('No signed URL received');
      }
      window.open(data.signedUrl, '_blank');
    } catch (error) {
      console.error('Error accessing prescription:', error);
      toast.error('Unable to access the prescription');
    }
  };

  // View sleep study report
  const viewReport = async (reportUrl: string) => {
    try {
      const data = await getSignedReportUrl(reportUrl);
      if (!data.signedUrl) {
        throw new Error('No signed URL received');
      }
      window.open(data.signedUrl, '_blank');
    } catch (error) {
      console.error('Error accessing report:', error);
      toast.error('Unable to access the report');
    }
  };

  if (!customerId) {
    return (
      <DistributorLayout>
        <div className="p-6">Customer ID is required.</div>
      </DistributorLayout>
    );
  }

  if (isLoading) {
    return (
      <DistributorLayout>
        <div className="p-6">Loading customer details...</div>
      </DistributorLayout>
    );
  }

  if (!customer) {
    return (
      <DistributorLayout>
        <div className="p-6">Customer not found.</div>
      </DistributorLayout>
    );
  }

  return (
    <DistributorLayout>
      <ToastContainer />
      <div className="p-6 bg-white rounded-lg shadow-md max-w-4xl mx-auto">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-bold text-gray-800">Customer Details</h2>
        </div>
        <div className="grid grid-cols-2 gap-x-8 gap-y-4 mb-8">
          {/* Row 1 */}
          <div>
            <label className="text-sm text-gray-600">Name</label>
            <p className="font-medium text-gray-800">
              {customer.firstName} {customer.lastName}
            </p>
          </div>
          <div>
            <label className="text-sm text-gray-600">Email</label>
            <p className="font-medium text-gray-800">{customer.email}</p>
          </div>

          {/* Row 2 */}
          <div>
            <label className="text-sm text-gray-600">Phone Number</label>
            <p className="font-medium text-gray-800">{customer.phoneNumber}</p>
          </div>
          <div>
            <label className="text-sm text-gray-600">Address</label>
            {customer.address ? (
              <p className="font-medium text-gray-800">{customer.address}</p>
            ) : (
              <p className="font-medium text-gray-800">Not Shared</p>
            )}
          </div>

          {/* Row 3 */}
          <div>
            <label className="text-sm text-gray-600">Date of Birth</label>
            <p className="font-medium text-gray-800">{formatDate(customer.dateOfBirth)}</p>
          </div>
          <div>
            <label className="text-sm text-gray-600">Gender</label>
            <p className="font-medium text-gray-800">
              {capitalizeFirstLetter(customer.gender)}
            </p>
          </div>
        </div>

        {/* 1) Self Assessments */}
        <div className="mb-8">
          <div
            onClick={() => toggleSection('selfAssessments')}
            className="flex justify-between items-center cursor-pointer bg-gray-50 p-4 rounded-t-lg hover:bg-gray-100"
          >
            <h3 className="text-xl font-semibold text-gray-800">Self Assessments</h3>
            <svg
              className={`w-6 h-6 transform transition-transform ${
                openSection.selfAssessments ? 'rotate-180' : ''
              }`}
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
            </svg>
          </div>
          {openSection.selfAssessments && (
            <div className="border border-t-0 rounded-b-lg p-4">
              {customer.quizData && customer.quizData.length > 0 ? (
                <div className="space-y-4">
                  {customer.quizData.map((quiz) => (
                    <div key={quiz._id} className="bg-gray-50 p-4 rounded-lg">
                      <div className="flex justify-between items-center">
                        <div>
                          <p className="text-gray-700">Date: {formatDate(quiz.createdAt)}</p>
                          <p className="text-gray-700">Risk Level: {quiz.riskLevel}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <p className="text-gray-500">No self assessments taken yet.</p>
              )}
            </div>
          )}
        </div>

        {/* 2) Prescriptions */}
        <div className="mb-8">
          <div
            onClick={() => toggleSection('prescriptions')}
            className="flex justify-between items-center cursor-pointer bg-gray-50 p-4 rounded-t-lg hover:bg-gray-100"
          >
            <h3 className="text-xl font-semibold text-gray-800">Prescriptions</h3>
            <svg
              className={`w-6 h-6 transform transition-transform ${
                openSection.prescriptions ? 'rotate-180' : ''
              }`}
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
            </svg>
          </div>
          {openSection.prescriptions && (
            <div className="border border-t-0 rounded-b-lg p-4">
              {customer.prescriptions && customer.prescriptions.length > 0 ? (
                <div className="space-y-4">
                  {customer.prescriptions.map((prescription) => (
                    <div key={prescription._id} className="bg-gray-50 p-4 rounded-lg">
                      <div className="flex justify-between items-center">
                        <div>
                          <p className="text-gray-700">Date: {formatDate(prescription.createdAt)}</p>
                        </div>
                        <button
                          onClick={() => viewPrescription(prescription.fileUrl)}
                          className="text-darkGreen hover:text-darkGreen-light"
                          title="View Prescription"
                        >
                          {/* Eye Icon */}
                          <FaEye className="w-5 h-5" />
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <p className="text-gray-500">No prescriptions uploaded yet.</p>
              )}
              <div className="flex justify-end mt-4">
                <button
                  onClick={() => setShowPrescriptionModal(true)}
                  className="bg-darkGreen hover:bg-darkGreen-light text-white px-4 py-2 rounded-md transition-colors"
                >
                  Upload Prescription
                </button>
              </div>
            </div>
          )}
        </div>

        {/* 3) Sleep Studies */}
        <div className="mb-8">
          <div
            onClick={() => toggleSection('sleepStudies')}
            className="flex justify-between items-center cursor-pointer bg-gray-50 p-4 rounded-t-lg hover:bg-gray-100"
          >
            <h3 className="text-xl font-semibold text-gray-800">Sleep Studies</h3>
            <svg
              className={`w-6 h-6 transform transition-transform ${
                openSection.sleepStudies ? 'rotate-180' : ''
              }`}
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
            </svg>
          </div>
          {openSection.sleepStudies && (
            <div className="border border-t-0 rounded-b-lg p-4">
              {customer.sleepStudyData && customer.sleepStudyData.length > 0 ? (
                <div className="space-y-4">
                  {customer.sleepStudyData.map((study) => (
                    <div key={study._id} className="bg-gray-50 p-4 rounded-lg">
                      <div className="flex justify-between items-center">
                        <div>
                          <p className="text-gray-700">Type: {study.sleepStudyType}</p>
                          <p className="text-gray-700">Date: {formatDate(study.createdAt)}</p>
                        </div>
                        <div className="flex items-center gap-3">
                          {study.status === 'Completed' && study.reportUrl ? (
                            <button
                              onClick={() => study.reportUrl && viewReport(study.reportUrl)}
                              className="text-darkGreen hover:text-darkGreen-light"
                              title="View Report"
                            >
                              {/* Eye Icon */}
                              <FaEye className="w-5 h-5" />
                            </button>
                          ) : (
                            <span
                              className={`px-3 py-1 rounded-full text-sm font-medium ${
                                study.status === 'Completed'
                                  ? 'bg-green-100 text-green-800'
                                  : study.status === 'Scheduled'
                                  ? 'bg-yellow-100 text-yellow-800'
                                  : study.status === 'Cancelled'
                                  ? 'bg-red-100 text-red-800'
                                  : 'bg-blue-100 text-blue-800'
                              }`}
                            >
                              {study.status}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <p className="text-gray-500">No sleep studies recorded.</p>
              )}
            </div>
          )}
        </div>

        {/* 4) CPAP Machine */}
        <div className="mb-8">
          <div
            onClick={() => toggleSection('cpapDevice')}
            className="flex justify-between items-center cursor-pointer bg-gray-50 p-4 rounded-t-lg hover:bg-gray-100"
          >
            <h3 className="text-xl font-semibold text-gray-800">CPAP Machine</h3>
            <svg
              className={`w-6 h-6 transform transition-transform ${
                openSection.cpapDevice ? 'rotate-180' : ''
              }`}
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
            </svg>
          </div>
          {openSection.cpapDevice && (
            <div className="border border-t-0 rounded-b-lg p-4">
              {customer.cpapMachine ? (
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <label className="text-sm text-gray-600">Model</label>
                    <p className="font-medium text-gray-800">{customer.cpapMachine.model}</p>
                  </div>
                  <div>
                    <label className="text-sm text-gray-600">Serial Number</label>
                    <p className="font-medium text-gray-800">{customer.cpapMachine.serialNumber}</p>
                  </div>
                  <div>
                    <label className="text-sm text-gray-600">Manufacturer</label>
                    <p className="font-medium text-gray-800">{customer.cpapMachine.manufacturer}</p>
                  </div>
                  <div>
                    <label className="text-sm text-gray-600">Purchase Date</label>
                    <p className="font-medium text-gray-800">
                      {formatDate(customer.cpapMachine.purchaseDate)}
                    </p>
                  </div>
                  <div>
                    <label className="text-sm text-gray-600">Warranty Expiry</label>
                    <p className="font-medium text-gray-800">
                      {formatDate(customer.cpapMachine.warrantyExpiry)}
                    </p>
                  </div>
                </div>
              ) : (
                <p className="text-gray-500">No CPAP machine assigned.</p>
              )}
            </div>
          )}
        </div>

        {/* 5) Service Package */}
        <div className="mb-8">
          <div
            onClick={() => toggleSection('servicePackage')}
            className="flex justify-between items-center cursor-pointer bg-gray-50 p-4 rounded-t-lg hover:bg-gray-100"
          >
            <h3 className="text-xl font-semibold text-gray-800">Service Package</h3>
            <svg
              className={`w-6 h-6 transform transition-transform ${
                openSection.servicePackage ? 'rotate-180' : ''
              }`}
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
            </svg>
          </div>
          {openSection.servicePackage && (
            <div className="border border-t-0 rounded-b-lg p-4">
              {customer.servicePackage ? (
                <div className="space-y-4">
                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <label className="text-sm text-gray-600">Package Name</label>
                      <p className="font-medium text-gray-800">{customer.servicePackage.name}</p>
                    </div>
                    <div>
                      <label className="text-sm text-gray-600">Status</label>
                      <span
                        className={`px-3 py-1 rounded-full text-sm font-medium ${
                          customer.servicePackage.status === 'Active'
                            ? 'bg-green-100 text-green-800'
                            : customer.servicePackage.status === 'Expired'
                            ? 'bg-red-100 text-red-800'
                            : 'bg-yellow-100 text-yellow-800'
                        }`}
                      >
                        {customer.servicePackage.status}
                      </span>
                    </div>
                    <div>
                      <label className="text-sm text-gray-600">Start Date</label>
                      <p className="font-medium text-gray-800">
                        {formatDate(customer.servicePackage.startDate)}
                      </p>
                    </div>
                    <div>
                      <label className="text-sm text-gray-600">End Date</label>
                      <p className="font-medium text-gray-800">
                        {formatDate(customer.servicePackage.endDate)}
                      </p>
                    </div>
                  </div>
                  <div>
                    <label className="text-sm text-gray-600">Included Items</label>
                    <div className="mt-2 space-y-2">
                      {customer.servicePackage.items.map((item, index) => (
                        <div
                          key={index}
                          className="flex justify-between items-center bg-gray-50 p-2 rounded"
                        >
                          <span className="text-gray-700">{item.name}</span>
                          <span className="text-gray-700">
                            {item.quantity} x ${item.price}
                          </span>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ) : (
                <p className="text-gray-500">No service package assigned.</p>
              )}
            </div>
          )}
        </div>

        {/* Prescription Upload Modal */}
        {showPrescriptionModal && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white rounded-lg p-6 w-full max-w-md">
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-lg font-semibold">Upload Prescription</h3>
                <button
                  onClick={() => {
                    setShowPrescriptionModal(false);
                    setSelectedPrescriptionFile(null);
                  }}
                  className="text-gray-500 hover:text-gray-700"
                >
                  <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Select Prescription File (PDF)
                  </label>
                  <input
                    type="file"
                    accept=".pdf"
                    onChange={(e) => setSelectedPrescriptionFile(e.target.files?.[0] || null)}
                    className="w-full border border-gray-300 rounded-md p-2"
                  />
                </div>
                <div className="flex justify-end">
                  <button
                    onClick={handleUploadPrescription}
                    disabled={!selectedPrescriptionFile}
                    className="bg-darkGreen hover:bg-darkGreen-light text-white px-4 py-2 rounded-md transition-colors disabled:opacity-50"
                  >
                    Upload
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </DistributorLayout>
  );
};

export default CustomerDetail;