// features/auth/doctor/DoctorLogout.tsx (Logout functionality)
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const DoctorLogout: React.FC = () => {
    const navigate = useNavigate();

    useEffect(() => {
        localStorage.clear();
        navigate('/auth/doctor/login');
    }, [navigate]);

    // No need to return any JSX since this component just redirects
    return null;
};

export default DoctorLogout;