// src/features/blogs/components/BlogCard.tsx
import React from 'react';
import { BlogCardProps } from '../types/BlogTypes';

const BlogCard: React.FC<BlogCardProps> = ({ title, image, excerpt }) => (
  <div className="bg-white shadow-md rounded-lg overflow-hidden h-full"> {/* Ensure card takes full height */}
    {image && <img src={image} alt={title} className="w-full h-48 object-cover" loading="lazy"/>}
    <div className="p-4 flex flex-col"> {/* Use flex-col to push Read More down if needed */}
      <h2 className="text-xl font-bold mb-2 text-gray-800 text-left">{title}</h2>
      {excerpt && <p className="text-sm text-gray-600 mb-3 text-left flex-grow">{excerpt}</p>} {/* Add flex-grow if needed */}
      <p className="text-blue-600 hover:underline text-left mt-auto pt-2"> {/* Use mt-auto to push to bottom */}
        Read More
      </p>
    </div>
  </div>
);

export default BlogCard;