// features/distributor/components/DistributorSidebar.tsx
import React from 'react';
import { NavLink } from 'react-router-dom';

const DistributorSidebar: React.FC = () => {
    return (
        <div className="hidden lg:block w-64 bg-white shadow-lg">
            <nav className="mt-6 space-y-2">
                <NavLink
                    to="/distributor/dashboard"
                    className={({ isActive }) =>
                        `block w-full text-left px-6 py-3 text-lg rounded-md transition-all duration-200 ${isActive ? 'bg-indigo-50 text-indigo-900' : 'text-gray-600 hover:bg-indigo-50 hover:text-indigo-900'}`
                    }
                >
                    Dashboard
                </NavLink>
                 <NavLink
                    to="/distributor/orders"
                    className={({ isActive }) =>
                        `block w-full text-left px-6 py-3 text-lg rounded-md transition-all duration-200 ${isActive ? 'bg-indigo-50 text-indigo-900' : 'text-gray-600 hover:bg-indigo-50 hover:text-indigo-900'}`
                    }
                >
                    Manage Orders
                </NavLink>
                 <NavLink
                    to="/distributor/products"
                    className={({ isActive }) =>
                        `block w-full text-left px-6 py-3 text-lg rounded-md transition-all duration-200 ${isActive ? 'bg-indigo-50 text-indigo-900' : 'text-gray-600 hover:bg-indigo-50 hover:text-indigo-900'}`
                    }
                >
                    Manage Products
                </NavLink>
                <NavLink
                    to="/distributor/customers"
                    className={({ isActive }) =>
                      `block w-full text-left px-6 py-3 text-lg rounded-md transition-all duration-200 ${isActive ? 'bg-indigo-50 text-indigo-900' : 'text-gray-600 hover:bg-indigo-50 hover:text-indigo-900'}`
                    }
                >
                    Manage Customers
                </NavLink>
                  <NavLink
                    to="/distributor/service-packages"
                    end
                    className={({ isActive }) =>
                        `block w-full text-left px-6 py-3 text-lg rounded-md transition-all duration-200 ${isActive ? 'bg-indigo-50 text-indigo-900' : 'text-gray-600 hover:bg-indigo-50 hover:text-indigo-900'}`
                    }
                  >
                    Service Packages
                </NavLink>
                <NavLink
                    to="/distributor/service-packages/orders"
                    className={({ isActive }) =>
                        `block w-full text-left px-6 py-3 text-lg rounded-md transition-all duration-200 ${isActive ? 'bg-indigo-50 text-indigo-900' : 'text-gray-600 hover:bg-indigo-50 hover:text-indigo-900'}`
                    }
                >
                    Manage Service Package Orders
                </NavLink>
                <NavLink
                    to="/auth/distributor/logout"
                    className={({ isActive }) =>
                        `block w-full text-left px-6 py-3 text-lg rounded-md transition-all duration-200 ${isActive ? 'bg-red-50 text-red-900' : 'text-red-600 hover:bg-red-50 hover:text-red-900'}`
                    }
                >
                    Logout
                </NavLink>
            </nav>
        </div>
    );
};

export default DistributorSidebar;