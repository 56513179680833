import React, { useState } from 'react';
import { SleepStudy } from '../types/SleepCenterTypes';
import UpdateStatusModal from './UpdateStatusModal';
import { format } from 'date-fns';

interface SleepStudyCardProps {
    id?: string;
    patientName?: string;
    studyType?: string;
    date?: string;
    status?: string;
    reportUrl?: string;
    study?: SleepStudy;
    onStatusChange?: (studyId: string, newStatus: string, newDate: string, reportUrl?: string) => void;
    showStatusUpdate?: boolean;
    cancelledAt?: string;
    completedAt?: string;
}

const SleepStudyCard: React.FC<SleepStudyCardProps> = (props) => {
    const {
        id = props.study?._id,
        patientName = `${props.study?.userId?.firstName} ${props.study?.userId?.lastName}`,
        studyType = props.study?.sleepStudyType,
        date = props.study?.date || 'No date',
        status = props.study?.status,
        reportUrl = props.study?.reportUrl,
        cancelledAt = props.study?.cancelledAt,
        completedAt = props.study?.completedAt,
    } = props;

    const [showStatusModal, setShowStatusModal] = useState(false);

    const formatDisplayDate = (dateString: string) => {
        if (dateString === 'No date') return 'To be finalised';
        try {
            return format(new Date(dateString), 'do MMMM yyyy');
        } catch {
            return 'To be finalised';
        }
    };

    return (
        <div className="bg-white p-4 rounded-lg shadow-sm border border-gray-200">
            <div className="flex flex-col gap-3">
                <div className="flex justify-between items-center">
                    <div>
                        <h3 className="font-medium text-gray-900">{patientName}</h3>
                        <p className="text-sm text-gray-500">{studyType}</p>
                        {status === 'Cancelled' ? (
                            <>
                                <p className="text-sm text-gray-500">Study Date Requested: {formatDisplayDate(date)}</p>
                                <p className="text-sm text-gray-500">Cancelled Date: {formatDisplayDate(cancelledAt || '')}</p>
                            </>
                        ) : status === 'Completed' ? (
                            <>
                                <p className="text-sm text-gray-500">Study Date Requested: {formatDisplayDate(date)}</p>
                                <p className="text-sm text-gray-500">Completed Date: {formatDisplayDate(completedAt || '')}</p>
                            </>
                        ) : (
                            <p className="text-sm text-gray-500">
                                {status === 'Request for Schedule' ? (
                                    <>Requested Date: {formatDisplayDate(date)}</>
                                ) : (
                                    <>Scheduled Date: {formatDisplayDate(date)}</>
                                )}
                            </p>
                        )}
                    </div>
                    <span className={`px-3 py-1 rounded-full text-sm font-medium ${
                        status === 'Completed' ? 'bg-green-100 text-green-800' :
                        status === 'Scheduled' ? 'bg-yellow-100 text-yellow-800' :
                        status === 'Cancelled' ? 'bg-red-100 text-red-800' :
                        'bg-blue-100 text-blue-800'
                    }`}>
                        {status}
                    </span>
                </div>

                <div className="flex justify-end gap-3 pt-2">
                    {props.onStatusChange && (
                        <>
                            {status === 'Completed' && reportUrl ? (
                                <a
                                    href={reportUrl}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-darkGreen hover:text-darkGreen-light font-medium px-4 py-2 border border-darkGreen rounded-md text-sm transition-colors cursor-pointer"
                                >
                                    See Report
                                </a>
                            ) : status !== 'Cancelled' && (
                                <button
                                    onClick={() => setShowStatusModal(true)}
                                    className="text-darkGreen hover:text-darkGreen-light font-medium px-4 py-2 border border-darkGreen rounded-md text-sm transition-colors"
                                >
                                    Update Status
                                </button>
                            )}
                        </>
                    )}
                </div>
            </div>

            <UpdateStatusModal
                id={id!}
                isOpen={showStatusModal}
                onClose={() => setShowStatusModal(false)}
                currentStatus={status || 'Request for Schedule'}
                currentDate={date}
                onStatusUpdate={async (newStatus, newDate, reportUrl) => {
                    if (id && props.onStatusChange) {
                        props.onStatusChange(id, newStatus, newDate || date, reportUrl);
                    }
                }}
            />
        </div>
    );
};

export default SleepStudyCard;
