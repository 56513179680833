import React, { useState, useEffect } from 'react';
import DistributorLayout from './DistributorLayout';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { getDistributorServicePackages } from '../services/DistributorService';
import 'react-toastify/dist/ReactToastify.css';

interface ServicePackage {
  packageId: string;
  packageName: string;
  price: number;
  description: string;
  services: string[];
  status: string;
  // Additional fields if needed
}

const ServicePackageList: React.FC = () => {
  const [servicePackages, setServicePackages] = useState<ServicePackage[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    fetchServicePackages();
  }, []);

  const fetchServicePackages = async () => {
    try {
      const distributorId = localStorage.getItem('distributorId');
      if (!distributorId) {
        toast.error('Error: Distributor ID not found. Please login again.');
        return;
      }
      const response = await getDistributorServicePackages(distributorId);

      // Map packages to ensure each has a valid packageId
      const packages = (response?.distributorServicePackages || [])
        .filter((pkg: any) => pkg.status === 'Active')
        .map((pkg: any) => ({
          packageId: pkg._id,
          packageName: pkg.packageName,
          price: pkg.price,
          description: pkg.description,
          services: pkg.distributorServices, // Assuming this is an array of service IDs
        }));
      setServicePackages(packages);
    } catch (error) {
      if (error instanceof Error && error.message === 'Request failed with status code 404') {
        setServicePackages([]);
      } else {
        console.error('Error fetching service packages:', error);
        toast.error('Failed to fetch service packages. Please try again later.');
        setServicePackages([]);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleCardClick = (packageId: string) => {
    // Navigate to the view page, ensuring the packageId is passed as a route parameter.
    navigate(`/distributor/service-packages/view/${packageId}`);
  };

  const handleAddServicePackageClick = () => {
    navigate('/distributor/add-service-package');
  };

  return (
    <DistributorLayout>
      <ToastContainer />
      <div className="p-6">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-bold">Service Packages</h2>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {isLoading ? (
            <div key="loading" className="col-span-full text-center py-4">
              Loading service packages...
            </div>
          ) : servicePackages.length === 0 ? (
            <div key="empty" className="col-span-full text-center py-4">
              No service packages found.
            </div>
          ) : (
            <>
              {servicePackages.map((pkg) => (
                <div
                  key={pkg.packageId}
                  className="bg-white rounded-lg shadow-md p-4 border cursor-pointer transition-colors hover:shadow-lg"
                  onClick={() => handleCardClick(pkg.packageId)}
                >
                  <h3 className="text-lg font-semibold mb-2">{pkg.packageName}</h3>
                  <p className="text-sm text-gray-800 font-semibold">Price: INR {pkg.price}</p>
                  <p className="text-sm text-gray-800 font-semibold mt-1">
                    # of Services: {pkg.services.length}
                  </p>
                </div>
              ))}
              {/* "Add Service Package" Card placed at the end */}
              <div
                key="add-service-package"
                className="flex flex-col items-center justify-center bg-white rounded-lg shadow-md border border-dashed border-gray-300 p-4 cursor-pointer hover:bg-gray-50 transition-colors"
                onClick={handleAddServicePackageClick}
              >
                <div className="text-gray-500 text-4xl font-bold mb-2">+</div>
                <p className="text-gray-600">Add Service Package</p>
              </div>
            </>
          )}
        </div>
      </div>
    </DistributorLayout>
  );
};

export default ServicePackageList;