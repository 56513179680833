import React, { useState, useEffect, useCallback } from 'react';
import DistributorLayout from './DistributorLayout';
import { getCustomerServicePackages } from '../services/DistributorService';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AssignServicePackageModal from './AssignServicePackageModal'; // Import the modal
import { getDistributorCustomers } from '../services/DistributorService';

interface Customer {
  id: string;
  firstName: string;
  lastName: string;
}

interface CustomerServicePackages {
    customer: {
      id: string;
      firstName: string;
      lastName: string;
    };
  servicePackages: any[]; // Replace any with a proper type
}
const ManageServicePackageOrders: React.FC = () => {
    const [, setCustomersWithPackages] = useState<CustomerServicePackages[]>([]);
    const [loading, setLoading] = useState(true);
    const [showAssignModal, setShowAssignModal] = useState(false);
    const [customers, setCustomers] = useState<Customer[]>([]);
    const distributorId = localStorage.getItem('distributorId') || '';

    const fetchCustomers = useCallback(async () => {
    try {
      const customersData = await getDistributorCustomers(distributorId);
      setCustomers(customersData.customers);
      } catch (error) {
      console.error('Error fetching data:', error);
      toast.error('Failed to load customers');
    }
  }, [distributorId]);

   // Fetch packages for each customer using their _id
  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const distributorId = localStorage.getItem('distributorId');
      if (!distributorId) {
        toast.error('Distributor ID not found. Please login again.');
        return;
      }
      // Ensure customers have been fetched
      if (customers.length === 0) {
        toast.error('No customers found.');
        return;
      }
      const packagesData: CustomerServicePackages[] = await Promise.all(
        customers.map(async (customer: any) => {
          const response = await getCustomerServicePackages(distributorId, customer.id);
          return {
            customer: customer,
            servicePackages: response?.servicePackages || [],
          };
        })
      );
      setCustomersWithPackages(packagesData);
    } catch (error: any) {
      console.error('Error fetching data:', error);
      toast.error(error.response?.data?.message || 'Failed to load data.');
    } finally {
      setLoading(false);
    }
  }, [customers]);

    useEffect(() => {
        fetchCustomers();
    }, [fetchCustomers]);

    useEffect(() => {
        if (customers.length > 0) {
          fetchData();
        }
      }, [customers, fetchData]);

     const handlePackageAssigned = () => {
        fetchData();
    };

    if (loading) {
        return <DistributorLayout><div className="p-6">Loading...</div></DistributorLayout>;
    }


    return (
      <DistributorLayout>
          <ToastContainer />
          <div className="p-6">
              <div className="flex justify-between items-center mb-4">
                  <h2 className="text-2xl font-bold">Manage Service Package Orders</h2>
                   <button
                        onClick={() => setShowAssignModal(true)}
                        className="bg-darkGreen hover:bg-darkGreen-light text-white font-bold py-2 px-4 rounded-md transition-colors"
                    >
                        Assign Package to Customer
                    </button>
              </div>

              {/* Display Customer List */}
          </div>
            {showAssignModal && (
                <AssignServicePackageModal
                customers = {customers}
                onClose={() => setShowAssignModal(false)}
                onPackageAssigned={handlePackageAssigned}
                />
            )}
      </DistributorLayout>
    );
    };

export default ManageServicePackageOrders;