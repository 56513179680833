import React, { useState, useEffect } from 'react';
import UserSidebar from '../../UserSidebar'; // Import Sidebar component
import { UserProfileType } from '../types/UserProfileTypes';
import { loadProfile, saveProfile } from '../services/UserProfileService';
import { format, parseISO, isValid } from 'date-fns'; // Import date-fns
import { capitalizeWords } from '../../../../utils/stringUtils';

const UserProfile: React.FC = () => {
    const [isEditing, setIsEditing] = useState(false);
    const [profile, setProfile] = useState<UserProfileType>({
        firstName: "Not Shared",
        lastName: "Not Shared",
        email: "Not Shared",
        dateOfBirth: "Not Shared",
        gender: "Not Shared",
        phoneNumber: "Not Shared",
        address: "Not Shared",
    });

    useEffect(() => {
        const userId = localStorage.getItem('userId');
        const token = localStorage.getItem('userToken');

        if (userId && token) {
            loadUserProfile(userId, token);
        }
    }, []);

    const loadUserProfile = async (userId: string, token: string) => {
        try {
            const profileData = await loadProfile(userId, token);
            setProfile(profileData);
        } catch (error) {
            console.error("Failed to load profile:", error);
        }
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setProfile(prev => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setIsEditing(false);
        try {
            const userId = localStorage.getItem('userId');
            const token = localStorage.getItem('userToken');
            if (userId && token) {
                await saveProfile(userId, profile, token);
            }
        } catch (error) {
            console.error("Failed to save profile:", error);
        }
    };

    // Function to display date in DD-MM-YYYY format for the UI
    const formatDisplayDate = (dateOfBirth: string) => {
        if (!dateOfBirth || !isValid(parseISO(dateOfBirth))) {
            return 'Not Shared';
        }
        return format(parseISO(dateOfBirth), 'dd-MM-yyyy'); // Format date for display
    };

    // Function to get date in YYYY-MM-DD format for date input field
    const getISODate = (dateOfBirth: string) => {
        if (!dateOfBirth || !isValid(parseISO(dateOfBirth))) {
            return ''; // Return empty string if the date is invalid
        }
        return format(parseISO(dateOfBirth), 'yyyy-MM-dd');
    };

    return (
        <div className="flex flex-col lg:flex-row h-screen bg-gray-50 font-sans">
            <UserSidebar />

            <div className="flex-1 overflow-y-auto p-8 lg:p-8">
                <header className="mb-4 lg:mb-8 flex flex-col lg:flex-row items-center justify-between">
                    <h1 className="text-2xl lg:text-3xl font-bold mb-4 lg:mb-0">Your Profile</h1>
                    {!isEditing && (
                        <button
                            onClick={() => setIsEditing(true)}
                            className="bg-darkGreen text-white font-bold py-2 px-4 rounded shadow"
                        >
                            Edit Profile
                        </button>
                    )}
                </header>

                <div className="bg-white rounded-lg shadow-md p-4 lg:p-6">
                    <form onSubmit={handleSubmit}>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 lg:gap-6">
                            {/* First Name */}
                            <div className="mb-4">
                                <label htmlFor="firstName" className="block text-sm font-medium text-gray-700 mb-1">
                                    First Name
                                </label>
                                <input
                                    type="text"
                                    name="firstName"
                                    id="firstName"
                                    value={capitalizeWords(profile.firstName)}
                                    onChange={handleInputChange}
                                    disabled={!isEditing}
                                    className={`mt-1 block w-full border-0 border-b-2 ${isEditing
                                        ? "border-indigo-300 focus:border-indigo-500 focus:ring-0"
                                        : "border-transparent bg-transparent focus:border-indigo-500"
                                        } text-base lg:text-lg`}
                                />
                            </div>

                            {/* Last Name */}
                            <div className="mb-4">
                                <label htmlFor="lastName" className="block text-sm font-medium text-gray-700 mb-1">
                                    Last Name
                                </label>
                                <input
                                    type="text"
                                    name="lastName"
                                    id="lastName"
                                    value={capitalizeWords(profile.lastName)}
                                    onChange={handleInputChange}
                                    disabled={!isEditing}
                                    className={`mt-1 block w-full border-0 border-b-2 ${isEditing
                                        ? "border-indigo-300 focus:border-indigo-500 focus:ring-0"
                                        : "border-transparent bg-transparent focus:border-indigo-500"
                                        } text-base lg:text-lg`}
                                />
                            </div>

                            {/* Gender */}
                            <div className="mb-4">
                                <label htmlFor="gender" className="block text-sm font-medium text-gray-700 mb-1">
                                    Gender
                                </label>
                                {isEditing ? (
                                    <select
                                        name="gender"
                                        id="gender"
                                        value={capitalizeWords(profile.gender)}
                                        onChange={handleInputChange}
                                        className="mt-1 block w-full border-0 border-b-2 border-indigo-300 focus:border-indigo-500 focus:ring-0 text-base lg:text-lg"
                                    >
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                        <option value="Other">Other</option>
                                    </select>
                                ) : (
                                    <input
                                        type="text"
                                        name="gender"
                                        id="gender"
                                        value={capitalizeWords(profile.gender)}
                                        disabled
                                        className="mt-1 block w-full border-transparent bg-transparent text-base lg:text-lg"
                                    />
                                )}
                            </div>

                            {/* Phone Number */}
                            <div className="mb-4">
                                <label htmlFor="phoneNumber" className="block text-sm font-medium text-gray-700 mb-1">
                                    Phone Number
                                </label>
                                <input
                                    type="text"
                                    name="phoneNumber"
                                    id="phoneNumber"
                                    value={profile.phoneNumber}
                                    onChange={handleInputChange}
                                    disabled={!isEditing}
                                    className={`mt-1 block w-full border-0 border-b-2 ${isEditing
                                        ? "border-indigo-300 focus:border-indigo-500 focus:ring-0"
                                        : "border-transparent bg-transparent focus:border-indigo-500"
                                        } text-base lg:text-lg`}
                                />
                            </div>

                            {/* Date of Birth */}
                            <div className="mb-4">
                                <label htmlFor="dateOfBirth" className="block text-sm font-medium text-gray-700 mb-1">
                                    Date of Birth
                                </label>
                                <input
                                    type={isEditing ? "date" : "text"}
                                    name="dateOfBirth"
                                    id="dateOfBirth"
                                    value={
                                        !isEditing
                                            ? formatDisplayDate(profile.dateOfBirth)
                                            : getISODate(profile.dateOfBirth)
                                    }
                                    onChange={handleInputChange}
                                    disabled={!isEditing}
                                    className={`mt-1 block w-full border-0 border-b-2 ${isEditing
                                        ? "border-indigo-300 focus:border-indigo-500 focus:ring-0"
                                        : "border-transparent bg-transparent focus:border-indigo-500"
                                        } text-base lg:text-lg`}
                                />
                            </div>

                            {/* Email */}
                            <div className="mb-4">
                                <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
                                    Email
                                </label>
                                <input
                                    type="email"
                                    name="email"
                                    id="email"
                                    value={profile.email}
                                    onChange={handleInputChange}
                                    disabled={!isEditing}
                                    className={`mt-1 block w-full border-0 border-b-2 ${isEditing
                                        ? "border-indigo-300 focus:border-indigo-500 focus:ring-0"
                                        : "border-transparent bg-transparent focus:border-indigo-500"
                                        } text-base lg:text-lg`}
                                />
                            </div>

                            {/* Address */}
                            <div className="mb-4 md:col-span-2">
                                <label htmlFor="address" className="block text-sm font-medium text-gray-700 mb-1">
                                    Address
                                </label>
                                <textarea
                                    name="address"
                                    id="address"
                                    value={capitalizeWords(profile.address)}
                                    onChange={handleInputChange}
                                    disabled={!isEditing}
                                    rows={3}
                                    className={`mt-1 block w-full border-0 border-b-2 ${isEditing
                                        ? "border-indigo-300 focus:border-indigo-500 focus:ring-0"
                                        : "border-transparent bg-transparent focus:border-indigo-500"
                                        } text-base lg:text-lg`}
                                />
                            </div>
                        </div>

                        {/* Edit Mode Actions */}
                        {isEditing && (
                            <div className="flex flex-col md:flex-row justify-end items-center space-y-4 md:space-y-0 md:space-x-2 mt-6">
                                <button
                                    type="button"
                                    onClick={() => setIsEditing(false)}
                                    className="w-full md:w-auto bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded shadow"
                                >
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="w-full md:w-auto bg-darkGreen text-white font-bold py-2 px-4 rounded shadow"
                                >
                                    Save Changes
                                </button>
                            </div>
                        )}
                    </form>
                </div>
            </div>
        </div>
    );
};

export default UserProfile;