// Admin User Management Component: features/admin/components/AdminUserManagement.tsx
import React, { useEffect, useState } from 'react';
import { getAllUsers, deleteUser } from '../services/AdminUserService';
import { Admin } from '../types/AdminUserTypes';
import AdminUserModal from './AdminUserModal';

const AdminUserManagement: React.FC = () => {
  const [users, setUsers] = useState<Admin[]>([]);
  const [selectedUser, setSelectedUser] = useState<Admin | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    loadUsers();
  }, []);

  const loadUsers = async () => {
    const fetchedUsers = await getAllUsers();
    setUsers(fetchedUsers);
  };

  const handleEditUser = (user: Admin) => {
    setSelectedUser(user);
    setIsModalOpen(true);
  };

  const handleDeleteUser = async (userId: string) => {
    await deleteUser(userId);
    loadUsers();
  };

  const handleCreateUser = () => {
    setSelectedUser(null);
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    loadUsers();
  };

  return (
    <div className="admin-user-management">
      <h2 className="text-2xl font-bold mb-4">Admin User Management</h2>
      <button className="bg-blue-500 text-white px-4 py-2 rounded mb-4" onClick={handleCreateUser}>Create New User</button>
      <table className="min-w-full bg-white shadow-md rounded mb-4">
        <thead>
          <tr className="bg-gray-100">
            <th className="py-2 px-4">Name</th>
            <th className="py-2 px-4">Email</th>
            <th className="py-2 px-4">Role</th>
            <th className="py-2 px-4">Actions</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <tr key={user.id} className="border-b">
              <td className="py-2 px-4">{user.firstName} {user.lastName}</td>
              <td className="py-2 px-4">{user.email}</td>
              <td className="py-2 px-4">{user.phoneNumber}</td>
              <td className="py-2 px-4">
                <button className="bg-green-500 text-white px-2 py-1 rounded mr-2" onClick={() => handleEditUser(user)}>Edit</button>
                <button className="bg-red-500 text-white px-2 py-1 rounded" onClick={() => handleDeleteUser(user.id)}>Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {isModalOpen && (
        <AdminUserModal 
            user={selectedUser || undefined} 
            onClose={handleModalClose}
        />
      )}
    </div>
  );
};

export default AdminUserManagement;