import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { checkExistingUser, createUser, assignUserToSleepCenter } from '../services/SleepCenterService';

interface SleepCenterPatientModalProps {
    onClose: () => void;
    onPatientAdded: () => void;
}

interface FormData {
    email: string;
    firstName: string;
    lastName: string;
    mobile: string;
    gender: string;
}

const SleepCenterPatientModal: React.FC<SleepCenterPatientModalProps> = ({ onClose, onPatientAdded }) => {
    const [formData, setFormData] = useState<FormData>({
        email: '',
        firstName: '',
        lastName: '',
        mobile: '',
        gender: ''
    });
    const [showCreateUserForm, setShowCreateUserForm] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isCheckingUser, setIsCheckingUser] = useState(false);
    const [existingPatient, setExistingPatient] = useState<any>(null);
    const sleepCenterId = localStorage.getItem('sleepCenterId') || '';

    const validatePhoneNumber = (phone: string) => {
        const phoneRegex = /^[6-9]\d{9}$/;
        return phoneRegex.test(phone);
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        if (name === 'mobile') {
            const numbersOnly = value.replace(/[^\d]/g, '');
            if (numbersOnly.length <= 10) {
                setFormData(prev => ({ ...prev, [name]: numbersOnly }));
            }
        } else {
            setFormData(prev => ({ ...prev, [name]: value }));
        }
    };

    const handleCheckExistingPatient = async (mobile: string) => {
        if (!validatePhoneNumber(mobile)) {
            toast.error('Please enter a valid 10-digit mobile number starting with 6-9');
            return;
        }

        setIsCheckingUser(true);
        const currentMobile = formData.mobile;

        try {
            const response = await checkExistingUser(mobile);
            if (response && response.data.user) {
                setExistingPatient(response.data.user);
                setFormData({
                    ...formData,
                    firstName: response.data.user.firstName,
                    lastName: response.data.user.lastName,
                    email: response.data.user.email,
                    gender: response.data.user.gender,
                    mobile: currentMobile
                });
                toast.success('Patient found! Details populated.');
                setShowCreateUserForm(true);
            } else {
                setShowCreateUserForm(true);
                setExistingPatient(null);
                setFormData({
                    ...formData,
                    firstName: '',
                    lastName: '',
                    email: '',
                    gender: '',
                    mobile: currentMobile
                });
                toast.info('No existing patient found. Please fill in all details.');
            }
        } catch (error: any) {
            console.error('Error checking existing patient:', error);
            toast.error('Failed to check for existing patient. Please try again.');
        } finally {
            setIsCheckingUser(false);
        }
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (isLoading) return;

        if (!validatePhoneNumber(formData.mobile)) {
            toast.error('Please enter a valid 10-digit mobile number starting with 6-9');
            return;
        }

        setIsLoading(true);
        try {
            if (existingPatient) {
                await assignUserToSleepCenter(existingPatient.id, sleepCenterId);
                onPatientAdded();
            } else {
                const response = await createUser(formData);
                await assignUserToSleepCenter(response.data.id, sleepCenterId);
                onPatientAdded();
            }
            toast.success('Patient added successfully!');
            onClose();
        } catch (error: any) {
            if (error.response?.status === 409) {
                toast.warning('This patient is already registered with this sleep center');
            } else {
                toast.error('Failed to add patient. Please try again.');
                console.error('Error adding patient:', error);
            }
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="fixed inset-0 z-50 overflow-auto bg-black bg-opacity-50 flex justify-center items-center">
            <ToastContainer />
            <div className="bg-white p-8 rounded-xl shadow-2xl w-[500px] relative">
                <h2 className="text-2xl font-bold mb-6 text-gray-800">
                    Add New Patient
                </h2>

                <form onSubmit={handleSubmit} className="space-y-6">
                    <div className="space-y-2">
                        <label className="text-sm font-semibold text-gray-700">Mobile Number</label>
                        <div className="flex gap-2">
                            <input
                                type="tel"
                                name="mobile"
                                value={formData.mobile}
                                onChange={handleChange}
                                className="flex-1 p-3 block rounded-lg border border-gray-300 shadow-sm 
                                         focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                                placeholder="Enter 10-digit mobile number"
                                maxLength={10}
                                required
                            />
                            <button
                                type="button"
                                onClick={() => handleCheckExistingPatient(formData.mobile)}
                                disabled={!formData.mobile || isCheckingUser}
                                className={`px-4 py-2 rounded-md text-white transition-colors ${
                                    isCheckingUser 
                                        ? 'bg-gray-500 cursor-not-allowed' 
                                        : 'bg-darkGreen hover:bg-darkGreen-light'
                                }`}
                            >
                                {isCheckingUser ? 'Checking...' : 'Check User'}
                            </button>
                        </div>
                    </div>

                    <div className="grid grid-cols-2 gap-4">
                        <div className="space-y-2">
                            <label className="text-sm font-semibold text-gray-700">First Name</label>
                            <input
                                type="text"
                                name="firstName"
                                value={formData.firstName}
                                onChange={handleChange}
                                className={`p-3 block w-full rounded-lg border border-gray-300 shadow-sm 
                                         focus:ring-2 focus:ring-blue-500 focus:border-transparent
                                         ${!showCreateUserForm ? 'bg-gray-100' : existingPatient ? 'bg-gray-50' : ''}`}
                                required
                                disabled={!showCreateUserForm || existingPatient !== null}
                            />
                        </div>
                        <div className="space-y-2">
                            <label className="text-sm font-semibold text-gray-700">Last Name</label>
                            <input
                                type="text"
                                name="lastName"
                                value={formData.lastName}
                                onChange={handleChange}
                                className={`p-3 block w-full rounded-lg border border-gray-300 shadow-sm 
                                         focus:ring-2 focus:ring-blue-500 focus:border-transparent
                                         ${!showCreateUserForm ? 'bg-gray-100' : existingPatient ? 'bg-gray-50' : ''}`}
                                required
                                disabled={!showCreateUserForm || existingPatient !== null}
                            />
                        </div>
                    </div>

                    <div className="space-y-2">
                        <label className="text-sm font-semibold text-gray-700">Email Address</label>
                        <input
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            className={`p-3 block w-full rounded-lg border border-gray-300 shadow-sm 
                                     focus:ring-2 focus:ring-blue-500 focus:border-transparent
                                     ${!showCreateUserForm ? 'bg-gray-100' : existingPatient ? 'bg-gray-50' : ''}`}
                            required
                            disabled={!showCreateUserForm || existingPatient !== null}
                        />
                    </div>

                    <div className="space-y-2">
                        <label className="text-sm font-semibold text-gray-700">Gender</label>
                        <div className="flex gap-4">
                            <label className="inline-flex items-center">
                                <input
                                    type="radio"
                                    name="gender"
                                    value="male"
                                    checked={formData.gender === 'male'}
                                    onChange={handleChange}
                                    className="form-radio text-blue-500"
                                    disabled={!showCreateUserForm || existingPatient !== null}
                                />
                                <span className={`ml-2 ${!showCreateUserForm || existingPatient ? 'text-gray-500' : ''}`}>Male</span>
                            </label>
                            <label className="inline-flex items-center">
                                <input
                                    type="radio"
                                    name="gender"
                                    value="female"
                                    checked={formData.gender === 'female'}
                                    onChange={handleChange}
                                    className="form-radio text-blue-500"
                                    disabled={!showCreateUserForm || existingPatient !== null}
                                />
                                <span className={`ml-2 ${!showCreateUserForm || existingPatient ? 'text-gray-500' : ''}`}>Female</span>
                            </label>
                        </div>
                    </div>

                    <div className="flex justify-end space-x-3 pt-4">
                        <button
                            type="button"
                            onClick={onClose}
                            className="px-6 py-3 bg-gray-100 text-gray-700 rounded-lg font-medium
                                     hover:bg-gray-200 transition-colors duration-200"
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            disabled={!showCreateUserForm || isLoading}
                            className={`px-6 py-3 rounded-lg font-medium transition-colors duration-200
                                     ${!showCreateUserForm 
                                         ? 'bg-gray-300 text-gray-500 cursor-not-allowed' 
                                         : 'bg-darkGreen text-white hover:bg-darkGreen-light'}`}
                        >
                            {existingPatient ? 'Assign Patient' : 'Create Patient'}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default SleepCenterPatientModal; 