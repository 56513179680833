// features/sleepCenter/components/SleepCenterDashboard.tsx
import React, { useEffect, useState, useCallback } from 'react';
import { fetchSleepCenterAllSleepStudies, fetchSleepCenterDetails } from '../services/SleepCenterService';
import { SleepStudyData } from '../../user/sleepStudy/types/UserSleepStudyTypes';
import SleepStudyCard from './SleepStudyCard';
import SleepCenterSidebar from '../SleepCenterSidebar';
import { FaChartBar, FaBed, FaClock } from 'react-icons/fa'; // Import icons
import { SleepCenter } from '../types/SleepCenterTypes';
import 'react-datepicker/dist/react-datepicker.css';


const SleepCenterDashboard: React.FC = () => {
    const [sleepStudies, setSleepStudies] = useState<SleepStudyData[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const sleepCenterId = localStorage.getItem('sleepCenterId') || '';
    const [, setSleepCenterDetails] = useState<SleepCenter | null>(null);
    const [activeTab, setActiveTab] = useState<'requestForSchedule' | 'scheduled' | 'completed' | 'cancelled'>('requestForSchedule');

    const fetchStudies = useCallback(async () => {
        try {
            setLoading(true);
            const [response, centerDetails] = await Promise.all([
                fetchSleepCenterAllSleepStudies(sleepCenterId),
                fetchSleepCenterDetails(sleepCenterId)
            ]);
            setSleepStudies(response || []);
             setSleepCenterDetails(centerDetails);
            setError(null);
        } catch (err: any) {
            setError(err.message || 'Failed to fetch sleep studies.');
            console.error('Failed to fetch sleep studies', err);
        } finally {
            setLoading(false);
        }
    }, [sleepCenterId]);


     useEffect(() => {
        fetchStudies();
     }, [fetchStudies]);

    const getTotalCountByStatus = (status: string) => {
        return sleepStudies.filter(study => study.status === status).length;
    };

    const getFilteredStudies = () => {
        return sleepStudies
            .filter(study => {
                return study.status === (
                    activeTab === 'requestForSchedule' ? 'Request for Schedule' : 
                    activeTab === 'scheduled' ? 'Scheduled' : 
                    activeTab === 'completed' ? 'Completed' : 'Cancelled'
                );
            })
            .sort((a, b) => {
                if (!a.date || !b.date) return 0;
                return new Date(b.date).getTime() - new Date(a.date).getTime();
            });
    };
    // Add these functions for counting
    const getFilteredCountByStatus = (status: string) => {
        return sleepStudies.filter(study => study.status === status).length;
    };

    if (loading) {
        return (
            <div className="flex h-screen bg-gray-100 font-sans">
             <SleepCenterSidebar />
                <div className="flex-1 overflow-y-auto p-8">
                  <div>Loading...</div>
                </div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="flex h-screen bg-gray-100 font-sans">
              <SleepCenterSidebar />
                <div className="flex-1 overflow-y-auto p-8">
                    <div>Error: {error}</div>
                </div>
             </div>
        );
    }

    return (
        <div className="flex h-screen bg-gray-100 font-sans">
            <SleepCenterSidebar />
            <div className="flex-1 overflow-y-auto p-8">
                {/* Summary Statistics */}
                <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
                    <div className="bg-white rounded-lg shadow-md p-6 flex items-center justify-between">
                        <div>
                            <p className="text-gray-500 text-sm font-medium">Total Upcoming Studies</p>
                            <p className="text-2xl font-bold text-gray-800 mt-1">
                                {getTotalCountByStatus('Request for Schedule')}
                            </p>
                        </div>
                        <div className="bg-blue-100 p-3 rounded-full">
                            <FaBed className="text-2xl text-blue-600" />
                        </div>
                    </div>
                    <div className="bg-white rounded-lg shadow-md p-6 flex items-center justify-between">
                        <div>
                            <p className="text-gray-500 text-sm font-medium">Total Scheduled Studies</p>
                            <p className="text-2xl font-bold text-gray-800 mt-1">
                                {getTotalCountByStatus('Scheduled')}
                            </p>
                        </div>
                        <div className="bg-yellow-100 p-3 rounded-full">
                            <FaClock className="text-2xl text-yellow-600" />
                        </div>
                    </div>
                    <div className="bg-white rounded-lg shadow-md p-6 flex items-center justify-between">
                        <div>
                            <p className="text-gray-500 text-sm font-medium">Total Completed Studies</p>
                            <p className="text-2xl font-bold text-gray-800 mt-1">
                                {getTotalCountByStatus('Completed')}
                            </p>
                        </div>
                        <div className="bg-green-100 p-3 rounded-full">
                            <FaChartBar className="text-2xl text-green-600" />
                        </div>
                    </div>
                </div>

                {/* Combined Filters and Studies Card */}
                <div className="bg-white rounded-lg shadow-md">
                    {/* Status Tabs */}
                    <div className="px-6 border-b border-gray-200">
                        <nav className="flex space-x-8">
                            <button
                                onClick={() => setActiveTab('requestForSchedule')}
                                className={`${
                                    activeTab === 'requestForSchedule'
                                        ? 'border-darkGreen text-darkGreen'
                                        : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                                } whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm transition-colors duration-200`}
                            >
                                Request For Schedule ({getFilteredCountByStatus('Request for Schedule')})
                            </button>
                            <button
                                onClick={() => setActiveTab('scheduled')}
                                className={`${
                                    activeTab === 'scheduled'
                                        ? 'border-darkGreen text-darkGreen'
                                        : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                                } whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm transition-colors duration-200`}
                            >
                                Scheduled ({getFilteredCountByStatus('Scheduled')})
                            </button>
                            <button
                                onClick={() => setActiveTab('completed')}
                                className={`${
                                    activeTab === 'completed'
                                        ? 'border-darkGreen text-darkGreen'
                                        : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                                } whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm transition-colors duration-200`}
                            >
                                Completed ({getFilteredCountByStatus('Completed')})
                            </button>
                            <button
                                onClick={() => setActiveTab('cancelled')}
                                className={`${
                                    activeTab === 'cancelled'
                                        ? 'border-darkGreen text-darkGreen'
                                        : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                                } whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm transition-colors duration-200`}
                            >
                                Cancelled ({getFilteredCountByStatus('Cancelled')})
                            </button>
                        </nav>
                    </div>

                    {/* Sleep Studies List */}
                    <div className="p-6">
                        <div className="space-y-4">
                            {getFilteredStudies().length > 0 ? (
                                getFilteredStudies().map((study) => (
                                    <SleepStudyCard
                                        key={study._id}
                                        id={study._id}
                                        patientName={study.userId?.firstName + ' ' + study.userId?.lastName}
                                        studyType={study.sleepStudyType!}
                                        date={study.date || 'No date'}
                                        status={study.status}
                                    />
                                ))
                            ) : (
                                <div className="text-center py-8">
                                    <p className="text-gray-500">
                                        No {activeTab} sleep studies found.
                                    </p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SleepCenterDashboard;