import React, { useState, useEffect, useCallback } from 'react';
import { SleepStudy } from '../types/SleepCenterTypes';
import { fetchSleepCenterAllSleepStudies } from '../services/SleepCenterService';
import SleepStudyCard from './SleepStudyCard';
import AddSleepStudyModal from './AddSleepStudyModal';
import SleepCenterSidebar from '../SleepCenterSidebar';
import { updateSleepStudyApi } from '../../../api/user/SleepStudyApi';

const SleepCenterStudies: React.FC = () => {
    const [studies, setStudies] = useState<SleepStudy[]>([]);
    const [activeTab, setActiveTab] = useState<'requestForSchedule' | 'scheduled' | 'completed' | 'cancelled'>('requestForSchedule');
    const sleepCenterId = localStorage.getItem('sleepCenterId') || '';
    const [showAddStudyModal, setShowAddStudyModal] = useState(false);

    const fetchStudies = useCallback(async () => {
        if (!sleepCenterId) return;
        
        try {
            const response = await fetchSleepCenterAllSleepStudies(sleepCenterId);
            console.log("Studies =>",response);
            if (response) {
                setStudies(response);
            } else {
                setStudies([]);
            }
        } catch (error) {
            console.error('Error fetching studies:', error);
            setStudies([]);
        }
    }, [sleepCenterId]);

    useEffect(() => {
        fetchStudies();
    }, [fetchStudies]);

    const getStudiesCountByStatus = (status: string) => {
        return studies.filter(study => study.status === status).length;
    };

    const getFilteredStudies = () => {
        return studies
            .filter(study => {
                return study.status === (
                    activeTab === 'requestForSchedule' ? 'Request for Schedule' : 
                    activeTab === 'scheduled' ? 'Scheduled' : 
                    activeTab === 'completed' ? 'Completed' : 'Cancelled'
                );
            })
            .sort((a, b) => {
                try {
                    if (!a.date || !b.date) return 0;
                    const dateA = new Date(a.date);
                    const dateB = new Date(b.date);
                    if (isNaN(dateA.getTime()) || isNaN(dateB.getTime())) return 0;
                    return dateB.getTime() - dateA.getTime();
                } catch (error) {
                    console.error('Date sorting error:', error);
                    return 0;
                }
            });
    };

    const handleStatusChange = async (studyId: string, newStatus: string, newDate: string) => {
        try {
            await updateSleepStudyApi(studyId, newDate, newStatus);
            await fetchStudies();
        } catch (error: any) {
            console.error('Error updating status:', error);
        }
    };

    return (
        <div className="flex h-screen bg-gray-100 font-sans">
            <SleepCenterSidebar />
            <div className="flex-1 overflow-y-auto p-8">
                {/* Main Content Card */}
                <div className="bg-white rounded-lg shadow-md">
                    {/* Header with Add Study Button */}
                    <div className="p-6 border-b border-gray-200">
                        <div className="flex justify-between items-center mb-4">
                            <h2 className="text-2xl font-bold text-gray-800">Manage Sleep Studies</h2>
                            <button
                                onClick={() => setShowAddStudyModal(true)}
                                className="bg-darkGreen hover:bg-darkGreen-light text-white px-4 py-2 rounded-lg transition-colors duration-200 flex items-center gap-2"
                            >
                                Add Sleep Study
                            </button>
                        </div>
                    </div>

                    {/* Status Tabs */}
                    <div className="px-6 border-b border-gray-200">
                        <nav className="flex space-x-8">
                            <button
                                onClick={() => setActiveTab('requestForSchedule')}
                                className={`${
                                    activeTab === 'requestForSchedule'
                                        ? 'border-darkGreen text-darkGreen'
                                        : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                                } whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm transition-colors duration-200`}
                            >
                                Request For Schedule ({getStudiesCountByStatus('Request for Schedule')})
                            </button>
                            <button
                                onClick={() => setActiveTab('scheduled')}
                                className={`${
                                    activeTab === 'scheduled'
                                        ? 'border-darkGreen text-darkGreen'
                                        : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                                } whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm transition-colors duration-200`}
                            >
                                Scheduled ({getStudiesCountByStatus('Scheduled')})
                            </button>
                            <button
                                onClick={() => setActiveTab('completed')}
                                className={`${
                                    activeTab === 'completed'
                                        ? 'border-darkGreen text-darkGreen'
                                        : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                                } whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm transition-colors duration-200`}
                            >
                                Completed ({getStudiesCountByStatus('Completed')})
                            </button>
                            <button
                                onClick={() => setActiveTab('cancelled')}
                                className={`${
                                    activeTab === 'cancelled'
                                        ? 'border-darkGreen text-darkGreen'
                                        : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                                } whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm transition-colors duration-200`}
                            >
                                Cancelled ({getStudiesCountByStatus('Cancelled')})
                            </button>
                        </nav>
                    </div>

                    {/* Sleep Studies List */}
                    <div className="p-6">
                        <div className="space-y-4">
                            {getFilteredStudies().length > 0 ? (
                                getFilteredStudies().map(study => (
                                    <SleepStudyCard
                                        key={study._id}
                                        id={study._id}
                                        patientName={study.userId?.firstName + ' ' + study.userId?.lastName}
                                        studyType={study.sleepStudyType!}
                                        date={study.date || 'No date'}
                                        status={study.status}
                                        onStatusChange={handleStatusChange}
                                    />
                                ))
                            ) : (
                                <div className="text-center py-8">
                                    <p className="text-gray-500">
                                        No {activeTab} sleep studies found.
                                    </p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            {/* Add Sleep Study Modal */}
            {showAddStudyModal && (
                <AddSleepStudyModal
                    onClose={() => setShowAddStudyModal(false)}
                    onStudyAdded={() => {
                        setShowAddStudyModal(false);
                        fetchStudies();
                    }}
                />
            )}
        </div>
    );
};

export default SleepCenterStudies; 