// frontend/features/distributor/services/DistributorService.ts
import axiosInstance from '../../../api/AxiosInstance';
import { DistributorLoginFormData } from '../types/DistributorAuthTypes';
import { Order } from '../types/OrderTypes';

// ... (existing functions: loginDistributor, forgotPasswordRequest, resetPasswordRequest) ...
export const loginDistributor = async (formData: DistributorLoginFormData) => {
    try {
        const response = await axiosInstance.post('/distributor/login', formData);
        return response;
    } catch (error: any) {
        throw error; // Re-throw for handling in the component
    }
};

export const forgotPasswordRequest = async (email: string) => {
    try {
        return await axiosInstance.post('/distributor/forgot-password', { email });
    } catch (error: any) {
        throw error;
    }
};

export const resetPasswordRequest = async (token: string, password: string) => {
    try {
        return await axiosInstance.post(`/distributor/reset-password/${token}`, { password });
    } catch (error: any) {
        throw error;
    }
};

export const createOrder = async (orderData: Omit<Order, '_id' | 'createdAt' | 'updatedAt' | 'status'>) => {
    try {
        const response = await axiosInstance.post('/orders', orderData);
        return response.data; // Assuming the backend returns the created order
    } catch (error) {
        console.error("Error creating order:", error);
        throw error;
    }
};

// Get all orders (for the distributor)
export const getAllOrders = async () => {  //No parameters needed for distributor
    try {
        const response = await axiosInstance.get('/orders');
        return response.data; // Assuming the backend returns an array of orders
    } catch (error) {
        console.error("Error getting all orders", error);
        throw error;
    }
};

// Get a single order by ID
export const getOrderById = async (orderId: string) => {
    try {
        const response = await axiosInstance.get(`/orders/${orderId}`);
        return response.data;
    } catch (error) {
        console.error("Error getting order:", error);
        throw error;
    }
};

// Update an order's status
export const updateOrderStatus = async (orderId: string, status: string) => {
    try {
        const response = await axiosInstance.put(`/orders/${orderId}/status`, { status });
        return response.data;
    } catch (error) {
        console.error("Error updating order status", error);
        throw error;
    }
};

// Cancel an order
export const cancelOrder = async (orderId: string) => {
    try {
        const response = await axiosInstance.delete(`/orders/${orderId}`);
        return response.data;
    } catch (error) {
        console.error("Error deleting order", error);
        throw error;
    }
};

// Check if a user exists by email or mobile
export const checkUserExists = async (mobile: string) => {
    try {
        const response = await axiosInstance.get(`/user/check-mobile/${mobile}`);
        return response.data;
    } catch (error) {
        console.error('Error checking user existence:', error);
        throw error; // Re-throw to handle in component
    }
};

//Create a new user
export const createUser = async (userData: any) => {  // Replace `any` with a more specific type if you have one
    try {
        console.log(userData);
        const response = await axiosInstance.post('/user/register', userData);
        return response; // Assuming the backend returns the created user data
    } catch (error) {
        console.error('Error creating user:', error);
        throw error; // Re-throw to handle in component
    }
};

// Add a helper function to fetch user details
export const fetchUserDetails = async (userId: string) => {
    try {
        const response = await axiosInstance.get(`/user/${userId}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching user details:', error);
        throw error;
    }
};

export const assignCustomerToDistributor = async (customerId: string, distributorId: string) => {
    try {
        const response = await axiosInstance.post(`/distributor/assign-customer`, { customerId, distributorId });
        return response.data;
    } catch (error) {
        console.error('Error assigning customer to distributor:', error);
        throw error;
    }
};

export const getDistributorCustomers = async (distributorId: string) => {
    try {
        const response = await axiosInstance.get(`/distributor/${distributorId}/customers`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const getCustomerDetails = async (customerId: string, distributorId: string) => {
    try {
        const response = await axiosInstance.get(`/distributor/${distributorId}/customers/${customerId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const getDistributorServicePackages = async (distributorId: string) => {
    try {
      const response = await axiosInstance.get(`/distributor/${distributorId}/service-packages`);
      return response.data;
    } catch (error) {
      console.error('Error fetching service packages:', error);
      throw error;
    }
  };

export const createServicePackage = async (distributorId: string, payload: any) => {
    try {
        const response = await axiosInstance.post(`/distributor/${distributorId}/service-package`, payload);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const getDistributorServices = async (distributorId: string) => {
    try {
        const response = await axiosInstance.get(`/distributor/${distributorId}/services`);
        return response.data; // Expected to return an object with an services array
    } catch (error) {
        throw error;
    }
};

export const createService = async (distributorId: string, payload: any) => {
    try {
        const response = await axiosInstance.post(`/distributor/${distributorId}/service`, payload);
        return response.data;
    } catch (error) {
        console.log(error);
        throw error;
    }
};

export const addServicePackageToDistributor = async (distributorId: string, servicePackageId: string) => {
    try {
      const response = await axiosInstance.post(`/distributor/${distributorId}/service-packages/${servicePackageId}`);
      return response.data;
    } catch(error) {
      console.error("Error in adding service package to distributor", error);
      throw error;
    }
  };

  export const removeServicePackageFromDistributor = async (distributorId: string, servicePackageId: string) => {
    try {
      const response = await axiosInstance.delete(`/distributor/${distributorId}/service-packages/${servicePackageId}`);
      return response.data;
    } catch(error) {
      console.error("Error in deleting service package to distributor", error);
      throw error;
    }
  };

  export const addServiceToDistributor = async (distributorId: string, serviceId: string) => {
    try {
      const response = await axiosInstance.post(`/distributor/${distributorId}/services/${serviceId}`);
      return response.data; //  { message: string }
    } catch (error) {
      console.error('Error adding service to distributor:', error);
      throw error;
    }
  };

  export const removeServiceFromDistributor = async (distributorId: string, serviceId: string) => {
    try {
      const response = await axiosInstance.delete(`/distributor/${distributorId}/services/${serviceId}`);
      return response.data; //  { message: string }
    } catch (error) {
      console.error('Error removing service from distributor:', error);
      throw error;
    }
  };

  export const getServicePackageDetails = async (servicePackageId: string) => {
    try {
      const response = await axiosInstance.get(`/distributor/service-packages/${servicePackageId}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching service package details:', error);
      throw error;
    }
  };

  export const assignServicePackage = async (customerId: string, packageId: string, startDate: string, endDate: string) => {
    try {
        const response = await axiosInstance.post('/distributor/assign-package', { customerId, packageId, startDate, endDate });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const getCustomerServicePackages = async (distributorId: string, customerId: string) => {
    try {
        const response = await axiosInstance.get(`/distributor/${distributorId}/customers/${customerId}/service-packages`);
        return response.data;
    } catch (error) {
        throw error;
    }
};