// frontend/features/distributor/components/ProductDetail.tsx
import React from 'react';
import { useParams } from 'react-router-dom';
import DistributorLayout from './DistributorLayout';

const ProductDetail: React.FC = () => {
    const { productId } = useParams<{ productId: string }>();

    // Dummy product data (replace with API call in a real app)
    const product = {
      productId: 'prod-1',
      productName: 'CPAP Machine Model X',
      price: 50000,
      description:
        'High-quality CPAP machine for effective sleep apnea treatment.',
    };

    if (!productId) {
        return <DistributorLayout><div>Product ID is required.</div></DistributorLayout>;
    }

    // Replace this with actual data fetching
    // const product = getProductById(productId); //  You'd implement this

    // if (!product) {
    //     return <DistributorLayout><div>Product not found.</div></DistributorLayout>;
    // }

    return (
        <DistributorLayout>
            <div className="p-6 bg-white rounded-lg shadow-md">
                <h2 className="text-2xl font-bold mb-4">{product.productName}</h2>
                <p className="text-gray-600 mb-4">
                   {product.description}
                </p>
                <p>
                    <strong>Price:</strong> ₹{product.price}
                </p>
                {/* Add more product details here as needed */}
            </div>
        </DistributorLayout>
    );
};

export default ProductDetail;