import React, { useEffect, useState } from 'react';
import { CompanyAnalytics } from '../types/CompanyTypes';
import { fetchCompanyAnalytics } from '../services/CompanyService';
import { Line } from 'recharts'; // Assuming you're using Recharts, change as needed
import Sidebar from '../CompanySidebar'; // Import your company sidebar

const CompanyDashboard: React.FC = () => {
    const [analytics, setAnalytics] = useState<CompanyAnalytics | null>(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const token = localStorage.getItem('companyToken'); // Assuming company auth uses localStorage
        if (token) {
            fetchCompanyData(token);
        }
    }, []);

    const fetchCompanyData = async (token: string) => {
        try {
            const data = await fetchCompanyAnalytics(token);
            setAnalytics(data);
        } catch (err) {
            setError('Failed to load company data');
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;
    if (!analytics) return <div>No data available</div>; // Handle cases where analytics is null

    return (
        <div className="flex flex-col lg:flex-row h-screen bg-gray-50 font-sans">
            <Sidebar /> 
            <div className="flex-1 overflow-y-auto p-8 lg:p-8">
                <h1 className="text-3xl font-bold mb-4">Company Dashboard</h1>

                {/* Key Metrics */}
                <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 mb-6"> 
                    {/* ...Key metric cards (see previous examples) */}
                </div>

                {/* Charts */}
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                    {/* Example: Department Data (Bar chart) */}
                    <div className="bg-white rounded-lg shadow-md p-4">
                        <h2 className="text-lg font-bold mb-2">Employees by Department</h2>
                        {/* ... Bar chart implementation ... */}
                    </div>

                    {/* Example: Risk Level Distribution (Pie chart) */}
                    <div className="bg-white rounded-lg shadow-md p-4">
                        <h2 className="text-lg font-bold mb-2">Risk Level Distribution</h2>
                        {/* ... Pie chart implementation ... */}
                    </div>

                    {/* Example: Questionnaire Trends (Line chart) - You can add more chart components */}
                    <div className="bg-white rounded-lg shadow-md p-4">
                        <h2 className="text-lg font-bold mb-2">Questionnaire Trends</h2>
                        <Line data={analytics.questionnaireTrends} /> {/* Replace with your actual chart */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CompanyDashboard;