import React, { useState, useEffect, useCallback } from 'react';
import 'react-calendar/dist/Calendar.css';
import { Appointment, Timeslot } from '../types/DoctorTypes';
import { format, parse } from 'date-fns';
import DoctorLayout from './DoctorLayout';
import {
    createAppointment,
    deleteAppointment,
    fetchAvailableAppointmentTimeslots,
    fetchDoctorAllAppointments,
    checkExistingPatient
} from '../services/DoctorService';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';

const DoctorSchedule: React.FC = () => {
    const [appointments, setAppointments] = useState<Appointment[]>([]);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [availableTimeslots, setAvailableTimeslots] = useState<Timeslot[]>([]);
    const [newAppointment, setNewAppointment] = useState<Appointment>({
        date: format(new Date(), 'yyyy-MM-dd'),
        time: '',
        userId: '',
        doctorId: '',
        status: 'Scheduled'
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [isUserVerified, setIsUserVerified] = useState(false);
    const [isCheckingUser, setIsCheckingUser] = useState(false);
    const [verifiedUserId, setVerifiedUserId] = useState('');
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [appointmentToDelete, setAppointmentToDelete] = useState<string | null>(null);
     const [verifiedPatientName, setVerifiedPatientName] = useState<string>('');
    const doctorId = localStorage.getItem('doctorId') || '';


    const fetchAppointments = useCallback(async () => {
        try {
            setLoading(true);
            const fetchedAppointments = await fetchDoctorAllAppointments(doctorId);
            setAppointments(fetchedAppointments);
            setError(null);
        } catch (err: any) {
           setError('Failed to fetch appointments.');
           if (axios.isAxiosError(err)) {
            toast.error(err.response?.data?.message || 'Failed to fetch appointments.');
           } else {
            toast.error('Failed to fetch appointments.');
            console.error('Error fetching appointments:', err);
            }
         } finally {
             setLoading(false);
         }
       }, [doctorId]);


    useEffect(() => {
         fetchAppointments();
        const fetchTimeslots = async () => {
            try {
                const timeslots = await fetchAvailableAppointmentTimeslots(
                    doctorId,
                    format(selectedDate, 'yyyy-MM-dd')
                );
                setAvailableTimeslots(timeslots);
            } catch (error: any) {
                console.error('Error fetching available timeslots:', error);
                toast.error( error.response?.data?.message || 'Could not fetch available timeslots');
            }
         };
          fetchTimeslots();
    }, [doctorId, selectedDate, fetchAppointments]);


    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setNewAppointment((prev: Appointment) => ({ ...prev, [name]: value }));
    };

    const handleScheduleNewAppointment = async () => {
        // Convert 12-hour time format to 24-hour format for Date object
        const convertTo24Hour = (time12h: string) => {
            const [time, modifier] = time12h.split(' ');
            let [hours, minutes] = time.split(':').map(Number);

            if (hours === 12) {
                hours = 0;
            }

            if (modifier === 'PM') {
                hours += 12;
            }
            return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:00`;
        };

        if (!selectedDate || !newAppointment.time) {
            toast.error('Please select both a valid date and time.');
            return;
        }

        try {
             const time24 = convertTo24Hour(newAppointment.time);
             const selectedDateTime = parse(
                 `${format(selectedDate, 'yyyy-MM-dd')}T${time24}`,
                 "yyyy-MM-dd'T'HH:mm:ss",
                 new Date()
             );
             const now = new Date();

            if (selectedDateTime < now) {
                toast.error("Cannot schedule appointments earlier than the current time. Please select a future date and time.");
                return;
            }


           await createAppointment({
               ...newAppointment,
               userId: verifiedUserId,
               doctorId,
               date: selectedDateTime.toISOString(),
           });

             // Reset all states
            setNewAppointment({
                date: format(new Date(), 'yyyy-MM-dd'),
                time: '',
                userId: '',
                doctorId: '',
                status: 'Scheduled'
            });
            setVerifiedUserId('');
            setIsUserVerified(false);
            setShowCreateModal(false);
            setVerifiedPatientName('');


           toast.success("Appointment scheduled successfully");
           fetchAppointments();

        } catch (error: any) {
            toast.error(error.response?.data?.message ||  'Failed to schedule appointment');
            console.error('Failed to create appointment:', error);
        }
    };

    const handleDeleteAppointment = async (appointmentId: string) => {
        setAppointmentToDelete(appointmentId);
        setShowDeleteModal(true);
    };

    const confirmDelete = async () => {
        if (!appointmentToDelete) return;

        try {
            const res = await deleteAppointment(appointmentToDelete);
           if (res.status === 200) {
              toast.success("Appointment deleted successfully");
              fetchAppointments()
            }

        } catch (error: any) {
              toast.error(error.response?.data?.message || 'Failed to delete appointment');
            console.error('Error deleting appointment', error);
        } finally {
            setShowDeleteModal(false);
            setAppointmentToDelete(null);
        }
    };

    const handleCheckUser = async () => {
        setIsCheckingUser(true);
        try {
           const response = await checkExistingPatient(newAppointment.userId.toString());
            if (response.status === 200 && response.data.exists) {
                setVerifiedUserId(response.data.user.id);
                 setIsUserVerified(true);
                setVerifiedPatientName(`${response.data.user.firstName} ${response.data.user.lastName}`);
                toast.success('User verified successfully');
            } else {
                setIsUserVerified(false);
               setVerifiedPatientName('');
                toast.error('User not found');
            }
        } catch (error) {
            console.error('Error checking user:', error);
            toast.error('Failed to verify user');
             setIsUserVerified(false);
              setVerifiedPatientName('');
        } finally {
            setIsCheckingUser(false);
        }
    };

    const resetForm = () => {
        setNewAppointment({
            date: format(new Date(), 'yyyy-MM-dd'),
            time: '',
            userId: '',
            doctorId: '',
            status: 'Scheduled'
        });
        setVerifiedUserId('');
        setIsUserVerified(false);
        setVerifiedPatientName('');
    };

    const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        e.stopPropagation();
        const newDate = new Date(e.target.value);
        if (!isNaN(newDate.getTime())) {
            setSelectedDate(newDate);
            setNewAppointment(prev => ({
                ...prev,
                date: format(newDate, 'yyyy-MM-dd')
            }));
        }
    };

    if (loading) {
        return (
            <DoctorLayout>
                <ToastContainer />
                <div>Loading...</div>
            </DoctorLayout>
        )
    };
    if (error) {
        return (
            <DoctorLayout>
                <ToastContainer />
                <div>Error: {error}</div>
            </DoctorLayout>
        )
    };

    return (
        <DoctorLayout>
            <ToastContainer />
            <div className="p-6 bg-white rounded-lg shadow-md">
                <div className="flex justify-between items-center mb-6">
                    <h2 className="text-2xl font-bold text-gray-800">Manage Appointments</h2>
                    <button
                        onClick={() => setShowCreateModal(true)}
                        className="bg-darkGreen hover:bg-darkGreen-light text-white px-4 py-2 rounded-md transition-colors"
                    >
                        Create Appointment
                    </button>
                </div>

                <div className="mb-6 flex items-center gap-4">
                    <div className="flex items-center gap-2">
                        <label htmlFor="date" className="text-sm font-medium text-gray-700">
                            Select Date:
                        </label>
                        <input
                            type="date"
                            id="date"
                            value={format(selectedDate, 'yyyy-MM-dd')}
                            onChange={handleDateChange}
                            className="border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-darkGreen"
                        />
                    </div>
                </div>

                <div className="overflow-x-auto">
                    <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50">
                            <tr>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Patient Name
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Time
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Status
                                </th>
                                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Actions
                                </th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                            {appointments
                                .filter(apt => format(new Date(apt.date), 'yyyy-MM-dd') === format(selectedDate, 'yyyy-MM-dd'))
                                .map((appointment) => (
                                    <tr key={appointment._id}>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            <div className="text-sm font-medium text-gray-900">
                                                {typeof appointment.userId === 'object'
                                                    ? `${appointment.userId.firstName} ${appointment.userId.lastName}`
                                                    : appointment.userId}
                                            </div>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            <div className="text-sm text-gray-900">
                                               {appointment.time}
                                            </div>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            <span className={`px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-full ${
                                                appointment.status === 'Scheduled' ? 'bg-blue-100 text-blue-800' :
                                                appointment.status === 'Completed' ? 'bg-green-100 text-green-800' :
                                                'bg-red-100 text-red-800'
                                            }`}>
                                                {appointment.status}
                                            </span>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                            <button
                                                onClick={() => handleDeleteAppointment(appointment._id || '')}
                                                className="text-red-600 hover:text-red-900 ml-4"
                                            >
                                                Delete
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                    {appointments.filter(apt =>
                        format(new Date(apt.date), 'yyyy-MM-dd') === format(selectedDate, 'yyyy-MM-dd')
                    ).length === 0 && (
                        <p className="text-center text-gray-500 py-4">
                            No appointments scheduled for this date.
                        </p>
                    )}
                </div>

                {/* Create Appointment Modal */}
                {showCreateModal && (
                    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                        <div className="bg-white p-6 rounded-lg shadow-xl max-w-md w-full">
                            <h3 className="text-lg font-semibold mb-4">Create New Appointment</h3>
                            <div className="space-y-4">
                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-1">
                                        Patient Mobile Number
                                    </label>
                                    <div className="flex gap-2">
                                        <input
                                            type="tel"
                                            name="userId"
                                            placeholder="Enter patient's mobile number"
                                            value={newAppointment.userId.toString()}
                                            onChange={handleInputChange}
                                            className="border border-gray-300 rounded-md p-2 flex-1"
                                            disabled={isUserVerified}
                                        />
                                        <button
                                            onClick={handleCheckUser}
                                            disabled={!newAppointment.userId || isCheckingUser || isUserVerified}
                                            className={`px-4 py-2 rounded-md text-white transition-colors ${isUserVerified
                                                ? 'bg-darkGreen cursor-not-allowed'
                                                : 'bg-darkGreen hover:bg-darkGreen-light'
                                            }`}
                                        >
                                            {isCheckingUser ? 'Checking...' : isUserVerified ? 'Verified' : 'Check User'}
                                        </button>
                                    </div>
                                    {isUserVerified && (
                                        <p className="text-sm text-green-600 mt-1">
                                            Patient Name: {verifiedPatientName}
                                        </p>
                                    )}
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-1">
                                        Date
                                    </label>
                                    <input
                                        type="date"
                                        name="date"
                                        value={format(selectedDate, 'yyyy-MM-dd')}
                                        onChange={handleDateChange}
                                        className="border border-gray-300 rounded-md p-2 w-full"
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-1">
                                        Time
                                    </label>
                                    <select
                                        name="time"
                                        value={newAppointment.time}
                                        onChange={handleInputChange}
                                        className="border border-gray-300 rounded-md p-2 w-full"
                                    >
                                        <option value="">Select a time</option>
                                        {availableTimeslots.map(slot => (
                                            <option
                                                key={slot.time}
                                                value={slot.time}
                                                disabled={!slot.isAvailable}
                                            >
                                                {slot.time} {!slot.isAvailable && "(Booked)"}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="flex justify-end gap-2 mt-4">
                                    <button
                                        type="button"
                                        onClick={() => {
                                            setShowCreateModal(false);
                                            resetForm();
                                        }}
                                        className="px-4 py-2 text-gray-600 hover:text-gray-800"
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        onClick={handleScheduleNewAppointment}
                                        className="bg-darkGreen hover:bg-darkGreen-light text-white px-4 py-2 rounded-md transition-colors"
                                         disabled={!isUserVerified || !newAppointment.time}
                                     >
                                        Schedule Appointment
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {/* Delete Confirmation Modal */}
                {showDeleteModal && (
                    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                        <div className="bg-white p-6 rounded-lg shadow-xl max-w-md w-full">
                            <h3 className="text-lg font-semibold mb-4">Confirm Delete</h3>
                            <p className="text-gray-600 mb-6">
                                Are you sure you want to delete this appointment? This action cannot be undone.
                            </p>
                            <div className="flex justify-end gap-2">
                                <button
                                    onClick={() => {
                                        setShowDeleteModal(false);
                                        setAppointmentToDelete(null);
                                    }}
                                    className="px-4 py-2 text-gray-600 hover:text-gray-800"
                                >
                                    Cancel
                                </button>
                                <button
                                    onClick={confirmDelete}
                                    className="bg-red-600 hover:bg-red-700 text-white px-4 py-2 rounded-md transition-colors"
                                >
                                    Delete
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </DoctorLayout>
    );
};

export default DoctorSchedule;