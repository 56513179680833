// frontend/features/doctor/services/DoctorService.ts
import axiosInstance from '../../../api/AxiosInstance';
import { Appointment, DoctorLoginFormData, DoctorRegisterFormData, PatientDetails, Timeslot } from '../types/DoctorTypes';


// Authentication API calls
export const loginDoctor = async (formData: DoctorLoginFormData) => {
    try {
        const response = await axiosInstance.post('/doctor/login', formData);
        return response;
    } catch (error: any) {
        throw error; // Re-throw for handling in the component
    }
};

export const registerDoctor = async (formData: DoctorRegisterFormData) => {
    try {
        const response = await axiosInstance.post('/doctor/register', formData);
        return response;
    } catch (error: any) {
        throw error;
    }
};

export const forgotPasswordRequest = async (email: string) => {
    try {
        return await axiosInstance.post('/doctor/forgot-password', { email });
    } catch (error: any) {
        throw error;
    }
};

export const resetPasswordRequest = async (token: string, password: string) => {
    try {
        return await axiosInstance.post(`/doctor/reset-password/${token}`, { password });
    } catch (error: any) {
        throw error;
    }
};

export const fetchDoctorPatients = async (doctorId: string): Promise<PatientDetails[]> => {
    try {
        const response = await axiosInstance.get(`/doctor/${doctorId}/patients`);
        return response.data;
    } catch (error) {
        console.error('Error fetching doctor patients:', error);
        throw error; // Re-throw for handling in the component
    }
};

export const fetchPatientDetails = async (userId: string): Promise<PatientDetails> => {
    try {
        const response = await axiosInstance.get(`/doctor/patient/${userId}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching patient details:', error);
        throw error;
    }
};

export const fetchDoctorAllAppointments = async (doctorId: string): Promise<Appointment[]> => {
    try {
        const response = await axiosInstance.get(`/doctor/${doctorId}/appointments`);
        return response.data;
    } catch (error) {
        console.error('Error fetching appointments:', error);
        throw error;
    }
};

export const createAppointment = async (appointment: Appointment) => {
    try {
        const response = await axiosInstance.post('/doctor/appointment', appointment);
        return response.data;
    } catch (error) {
        console.error('Error creating appointment:', error);
        throw error;
    }
};

export const updateAppointment = async (appointmentId: string, appointmentData: any) => {
    try {
        const response = await axiosInstance.put(`/appointments/${appointmentId}`, appointmentData);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const deleteAppointment = async (appointmentId: string) => {
    try {
        const response = await axiosInstance.delete(`/doctor/appointment/${appointmentId}`);
        return response;
    } catch (error) {
        console.error('Error deleting appointment:', error);
        throw error;
    }
};

export const fetchAvailableAppointmentTimeslots = async (doctorId: string, date: string): Promise<Timeslot[]> => {
    try {
        const response = await axiosInstance.get(`/consultation/${doctorId}/available-timeslots/${date}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching available timeslots:', error);
        throw error;
    }
};

export const verifyOTP = async (email: string, otp: string) => {
    try {
        const response = await axiosInstance.post('/doctor/verify-otp', { email, otp });
        return response;
    } catch (error: any) {
        console.error('Error verifying OTP:', error);
        throw error; // Re-throw for handling in the component
    }
};

export const assignPatientToDoctor = async (patientId: string, doctorId: string) => {
    try {
        const response = await axiosInstance.post('/doctor/assign-patient', {
            doctorId,
            patientId
        });
        return response.data;
    } catch (error: any) {
        throw error;
    }
};

export const createUser = async (userData: any) => {
    try {
        const response = await axiosInstance.post('/doctor/create-user', userData);
        return response;
    } catch (error) {
        console.error('Error creating user:', error);
        throw error;
    }
};

export const createConsultation = async (patientId: string) => {
    try {
        const response = await axiosInstance.post('/consultation/request', { patientId });
        return response.data;

    } catch (error) {
        console.error('Error creating consultation request:', error)
        throw error; // Re-throw the error to be caught by the component
    }
}

export const orderSleepStudy = async (
    data: {
        doctorId: string;
        patientId: string;
        sleepStudyType: string;
        sleepCenterId: string;
    }) => {
    try {
        const response = await axiosInstance.post('/doctor/sleep-study/order', data);
        return response;
    } catch (error) {
        throw error;
    }
};

export const checkExistingPatient = async (mobile: string) => {
    try {
        const response = await axiosInstance.get(`/user/check-mobile/${mobile}`);
        return response;
    } catch (error: any) {
        throw new Error('Failed to check existing patient');
    }
};

export const updateDoctorDetails = async (doctorId: string, updatedData: any) => {
    try {
        const response = await axiosInstance.put(`/doctor/update/${doctorId}`, updatedData);
        return response;
    } catch (error: any) {
        throw error;
    }
};

export const getDoctorDetails = async (doctorId: string) => {
    try {
        const response = await axiosInstance.get(`/doctor/details/${doctorId}`);
        return response;
    } catch (error) {
        throw error;
    }
};

export const fetchDoctorHospitals = async (doctorId: string) => {
    try {
        const response = await axiosInstance.get(`/doctor/${doctorId}/hospitals`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const fetchAllHospitals = async () => {
    try {
        const response = await axiosInstance.get('/hospital/all');
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const joinHospital = async (doctorId: string, hospitalId: string) => {
    try {
        const response = await axiosInstance.post(`/doctor/${doctorId}/hospital`, {
            hospitalId
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const unassignHospital = async (doctorId: string, hospitalId: string) => {
    try {
        const response = await axiosInstance.delete(`/doctor/${doctorId}/hospital/${hospitalId}`);

        return response.data;
    } catch (error) {
        throw error;
    }
};

export const getDashboardStats = async (doctorId: string) => {
    try {
        const response = await axiosInstance.get(`/doctor/${doctorId}/dashboard`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const requestSelfAssessment = async (doctorId: string, patientId: string) => {
    try {
        const response = await axiosInstance.post(`/doctor/${doctorId}/request-assessment/${patientId}`);
        return response;
    } catch (error) {
        throw error;
    }
};

export const requestConsultation = async (doctorId: string, patientId: string) => {
    try {
        const response = await axiosInstance.post(`/doctor/${doctorId}/request-consultation/${patientId}`);
        return response;
    } catch (error) {
        throw error;
    }
};

export const unassignPatient = async (doctorId: string, patientId: string) => {
    try {
        const response = await axiosInstance.put(`/doctor/unassign-patient`, {
            doctorId,
            patientId
        });
        return response;
    } catch (error) {
        console.error('Error unassigning patient:', error);
        throw error;
    }
};

export const fetchAvailableSleepCenters = async () => {
    try {
        const response = await axiosInstance.get('/sleep-center/all', {
        });
        return response;
    } catch (error) {
        throw error;
    }
};

export const getSignedReportUrl = async (reportUrl: string) => {
    try {
        const response = await axiosInstance.get(`/doctor/report/get-signed-url?url=${encodeURIComponent(reportUrl)}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const uploadPrescription = async (formData: FormData) => {
    return await axiosInstance.post('/doctor/prescription/upload', formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
};

export const getSignedPrescriptionUrl = async (prescriptionUrl: string) => {
    try {
        const response = await axiosInstance.get(`/doctor/prescription/get-signed-url?url=${encodeURIComponent(prescriptionUrl)}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const assignPatient = async( currentDoctorId: string, patientId: string, newDoctorId: string) => {
    try{
        return await axiosInstance.put(`/doctor/patient/${patientId}/assign`, {newDoctorId: newDoctorId, currentDoctorId: currentDoctorId}, {
            headers: {
            Authorization: `Bearer ${localStorage.getItem('doctorToken')}`
            }
        });
    } catch (error){
        throw error;
    }
}

export const fetchAllDoctors = async () => {
    try {
        const response = await axiosInstance.get('/doctor/all');
        return response.data;
    } catch (error) {
        throw error;
    }
};