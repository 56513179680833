// src/features/admin/services/AdminBlogService.ts
// Or add these functions to src/features/admin/services/AdminUserService.ts

import {
    getAdminBlogsList,
    getAdminBlogPostById,
    postAdminBlogPost, // Renamed API function for clarity
    putAdminBlogPost,  // Renamed API function for clarity
    deleteAdminBlogPost // Renamed API function for clarity
} from '../../../api/admin/AdminBlogApi'; // Assuming API calls are in this file

import { BlogListResponse, BlogPost } from '../../blogs/types/BlogTypes'; // Use shared types

// --- Create/Update/Delete Functions (Modified to use renamed API calls) ---

export const createBlogPost = async (blogFormData: FormData): Promise<BlogPost> => {
    try {
        const response = await postAdminBlogPost(blogFormData);
        return response.data; // Return created blog data
    } catch (error: unknown) {
        console.error("Error creating blog post:", error);
        throw error;
    }
};

export const updateBlogPost = async (blogId: string, blogFormData: FormData): Promise<BlogPost> => {
    try {
        const response = await putAdminBlogPost(blogId, blogFormData);
        return response.data; // Return updated blog data
    } catch (error: unknown) {
        console.error(`Error updating blog post ${blogId}:`, error);
        throw error;
    }
};

export const deleteBlogPost = async (blogId: string): Promise<{ message: string }> => {
    try {
        const response = await deleteAdminBlogPost(blogId);
        return response.data; // Return success message
    } catch (error: unknown) {
        console.error(`Error deleting blog post ${blogId}:`, error);
        throw error;
    }
};


// --- NEW Functions for Admin Listing/Editing ---

interface AdminBlogListParams {
    page?: number;
    limit?: number;
    status?: 'draft' | 'published' | 'archived';
    sortBy?: string;
    sortOrder?: 'asc' | 'desc';
}

/**
 * Service function to fetch a list of all blogs (including drafts) for admin view.
 * @param params - Optional parameters for pagination, filtering, sorting.
 * @returns Promise resolving to the BlogListResponse structure.
 */
export const fetchAdminBlogs = async (params: AdminBlogListParams = {}): Promise<BlogListResponse> => {
  try {
    const response = await getAdminBlogsList(params);
    return response.data;
  } catch (error) {
    console.error('Error fetching admin blog list:', error);
    // Return an empty state for graceful UI handling
    return { blogs: [], currentPage: 1, totalPages: 0, totalBlogs: 0 };
    // throw error; // Or re-throw if the component handles loading errors specifically
  }
};

/**
 * Service function to fetch a single blog post by ID for admin editing.
 * @param blogId - The ID of the blog post.
 * @returns Promise resolving to the full BlogPost data.
 */
export const fetchAdminBlogById = async (blogId: string): Promise<BlogPost> => {
    try {
        const response = await getAdminBlogPostById(blogId);
        return response.data;
    } catch (error) {
        console.error(`Error fetching admin blog post with ID ${blogId}:`, error);
        throw error; // Re-throw error to be handled by the calling component
    }
};