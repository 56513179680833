// src/features/services/sleepStudyService.ts

import { deleteSleepStudy, getSleepStudies, scheduleSleepStudy, updateSleepStudyApi } from '../../../../api/user/SleepStudyApi';
import { SleepStudyData } from '../types/UserSleepStudyTypes';

export const fetchAllSleepStudies = async (userId: string): Promise<SleepStudyData[]> => {
    try {
        const studies = await getSleepStudies(userId);
        return studies;
    } catch (error) {
        throw new Error('Failed to fetch sleep studies.');
    }
};

export const createSleepStudy = async (userId: string, date: string): Promise<void> => {
    try {
        await scheduleSleepStudy(userId, date);
    } catch (error) {
        throw new Error('Failed to schedule sleep study.');
    }
};

export const removeSleepStudy = async (studyId: string): Promise<void> => {
    try {
        await deleteSleepStudy(studyId);
    } catch (error) {
        throw new Error('Failed to delete sleep study schedule.');
    }
};

export const updateSleepStudy = async (selectedStudyId: string, date: string, status: string): Promise<void> => {
    try {
        await updateSleepStudyApi(selectedStudyId, date, status);
    } catch (error) {
        throw new Error('Failed to update sleep study.');
    }
};