// features/sleepCenter/components/UploadSleepStudyReport.tsx
import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { uploadSleepStudyReport } from '../services/SleepCenterService';
import SleepCenterLayout from '../SleepCenterLayout';

const UploadSleepStudyReport: React.FC = () => {
    const { studyId } = useParams<{ studyId: string }>();
    const [reportFile, setReportFile] = useState<File | null>(null);
    const [uploading, setUploading] = useState(false);
    const navigate = useNavigate();

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setReportFile(e.target.files?.[0] || null);
    };

     const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setUploading(true);
         try {
             if (!studyId || !reportFile) {
                 toast.error('Please select a file to upload.');
                 return;
             }

          const response =  await uploadSleepStudyReport(studyId, reportFile);
          if(response.status === 200)
             toast.success('Sleep study report uploaded successfully!');
             setTimeout(() => {
                  navigate('/sleep-center/dashboard');
                }, 2000);
        } catch (error: any) {
            toast.error(error.response?.data?.message || 'Failed to upload sleep study report.');
            console.error('Failed to upload sleep study report:', error);
        } finally {
             setUploading(false);
        }
    };

    return (
        <SleepCenterLayout>
            <ToastContainer />
            <div className="p-6 bg-white rounded-lg shadow-md max-w-md mx-auto">
                <h2 className="text-2xl font-bold mb-6 text-center">Upload Sleep Study Report</h2>
                <form onSubmit={handleSubmit} className="space-y-4">
                    <div>
                        <label htmlFor="reportFile" className="block text-sm font-medium text-gray-700 mb-2">
                            Report File
                        </label>
                        <input
                            type="file"
                            id="reportFile"
                            onChange={handleInputChange}
                            className="border border-gray-300 rounded-lg p-2 w-full"
                            required
                        />
                    </div>
                    <div className="flex justify-end">
                        <button
                            type="submit"
                            disabled={uploading}
                            className={`bg-darkGreen text-white px-4 py-2 rounded-md transition-colors ${uploading ? 'opacity-50 cursor-not-allowed' : 'hover:bg-darkGreen-light'}`}
                        >
                           {uploading ? 'Uploading...' : 'Upload Report'}
                       </button>
                    </div>
                </form>
            </div>
        </SleepCenterLayout>
    );
};

export default UploadSleepStudyReport;