import React from 'react';

interface Offering {
    name: string;
    description: string;
    pricing: number;
}

interface AddOfferingModalProps {
    isOpen: boolean;
    onClose: () => void;
    offering: Offering;
    onInputChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onSubmit: () => void;
}

const AddOfferingModal: React.FC<AddOfferingModalProps> = ({
    isOpen,
    onClose,
    offering,
    onInputChange,
    onSubmit
}) => {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white rounded-lg p-6 w-full max-w-2xl">
                <div className="flex justify-between items-center mb-6">
                    <h3 className="text-xl font-semibold text-gray-800">Add New Offering</h3>
                    <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
                        ×
                    </button>
                </div>

                <div className="space-y-4">
                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                            Offering Name
                        </label>
                        <input
                            type="text"
                            name="name"
                            value={offering.name}
                            onChange={onInputChange}
                            className="w-full border border-gray-300 rounded-md p-2 focus:ring-2 focus:ring-darkGreen focus:border-transparent"
                            placeholder="Enter offering name"
                        />
                    </div>

                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                            Description
                        </label>
                        <textarea
                            name="description"
                            value={offering.description}
                            onChange={onInputChange}
                            className="w-full border border-gray-300 rounded-md p-2 focus:ring-2 focus:ring-darkGreen focus:border-transparent"
                            placeholder="Enter offering description"
                            rows={3}
                        />
                    </div>

                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                            Price (₹)
                        </label>
                        <input
                            type="number"
                            name="pricing"
                            value={offering.pricing || ''}
                            onChange={onInputChange}
                            className="w-full border border-gray-300 rounded-md p-2 focus:ring-2 focus:ring-darkGreen focus:border-transparent"
                            placeholder="Enter price"
                        />
                    </div>
                </div>

                <div className="flex justify-end gap-3 mt-6">
                    <button
                        onClick={onClose}
                        className="px-4 py-2 text-gray-600 hover:text-gray-800 font-medium"
                    >
                        Cancel
                    </button>
                    <button
                        onClick={onSubmit}
                        className="bg-darkGreen hover:bg-darkGreen-light text-white px-6 py-2 rounded-md transition duration-200"
                    >
                        Add Offering
                    </button>
                </div>
            </div>
        </div>
    );
};

export default AddOfferingModal; 