import React, { useState, useEffect } from 'react';
import { assignServicePackage, getDistributorServicePackages } from '../services/DistributorService';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { DistributorServicePackage } from '../types/ServicePackageOrderTypes';
import { calculateEndDate } from '../../../utils/dateHelpers';

interface AssignServicePackageModalProps {
  customers: {
    id: string;
    firstName: string;
    lastName: string;
  }[];
  onClose: () => void;
  onPackageAssigned: () => void;
}

const parseDuration = (duration: string): number => {
  const match = duration.match(/(\d+)\s*(day|week|month|year)s?/i);
  if (!match) return 0;

  const value = parseInt(match[1], 10);
  const unit = match[2].toLowerCase();

  switch (unit) {
    case 'day': return value;
    case 'week': return value * 7;
    case 'month': return value * 30;
    case 'year': return value * 365;
    default: return 0;
  }
};

const AssignServicePackageModal: React.FC<AssignServicePackageModalProps> = ({
  customers,
  onClose,
  onPackageAssigned
}) => {
  const [servicePackages, setServicePackages] = useState<DistributorServicePackage[]>([]);
  const [selectedCustomerId, setSelectedCustomerId] = useState('');
  const [selectedPackage, setSelectedPackage] = useState<DistributorServicePackage | null>(null);
  const [selectedStartDate, setSelectedStartDate] = useState('');
  const [calculatedEndDate, setCalculatedEndDate] = useState('');
  const [packagePrice, setPackagePrice] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const distributorId = localStorage.getItem('distributorId') || '';

  console.log(customers);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!distributorId) {
          toast.error('Distributor ID not found. Please login again.');
          return;
        }
        const packagesData = await getDistributorServicePackages(distributorId);
        setServicePackages(packagesData.distributorServicePackages);
      } catch (error) {
        console.error('Error fetching data:', error);
        toast.error('Failed to load service packages');
      }
    };
    fetchData();
  }, [distributorId]);

  useEffect(() => {
    if (selectedPackage) {
      setPackagePrice(selectedPackage.price);
      const longestDuration = Math.max(
        ...selectedPackage.distributorServices.map(service => parseDuration(service.duration))
      );
      if (selectedStartDate) {
        const endDate = calculateEndDate(new Date(selectedStartDate), longestDuration);
        setCalculatedEndDate(endDate?.toISOString().split('T')[0] || '');
      }
    }
  }, [selectedPackage, selectedStartDate]);

  const isFormValid = selectedCustomerId && selectedPackage && selectedStartDate;

  const handleAssignPackage = async () => {
    if (!isFormValid) {
      toast.error('Please fill all fields before assigning the package.');
      return;
    }

    setIsLoading(true);
    try {
      await assignServicePackage(selectedCustomerId, selectedPackage._id, selectedStartDate, calculatedEndDate);
      toast.success('Service package assigned successfully!');
      onPackageAssigned();
      onClose();
    } catch (error: any) {
      console.error('Error assigning service package:', error);
      toast.error(error.response?.data?.message || 'Failed to assign service package.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-xl max-w-md w-full">
        <h3 className="text-lg font-semibold mb-4">Assign Service Package</h3>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-1">Select Customer</label>
          <select
            value={selectedCustomerId}
            onChange={(e) => setSelectedCustomerId(e.target.value)}
            className="border border-gray-300 rounded-md p-2 w-full"
            required
          >
            <option value="">Select a customer</option>
            {customers.map((customer) => (
              <option key={customer.id} value={customer.id}>
                {customer.firstName} {customer.lastName}
              </option>
            ))}
          </select>
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-1">Select Package</label>
          <select
            value={selectedPackage?._id || ''}
            onChange={(e) => setSelectedPackage(servicePackages.find(pkg => pkg._id === e.target.value) || null)}
            className="border border-gray-300 rounded-md p-2 w-full"
            required
          >
            <option value="">Select a Package</option>
            {servicePackages.map((packageItem) => (
              <option key={packageItem._id} value={packageItem._id}>
                {packageItem.packageName}
              </option>
            ))}
          </select>
        </div>

        {packagePrice !== null && (
          <p className="text-gray-800 text-sm mb-4">Service Package Price: INR {packagePrice}</p>
        )}

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-1">Select Start Date</label>
          <input
            type="date"
            value={selectedStartDate}
            onChange={(e) => setSelectedStartDate(e.target.value)}
            className="border border-gray-300 rounded-md p-2 w-full"
            required
          />
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-1">End Date</label>
          <input
            type="date"
            value={calculatedEndDate}
            readOnly
            className="border border-gray-300 rounded-md p-2 w-full bg-gray-100 cursor-not-allowed"
          />
        </div>

        <div className="flex justify-end gap-2 mt-4">
          <button onClick={onClose} className="px-4 py-2 text-gray-600 hover:text-gray-800">Cancel</button>
          <button
            onClick={handleAssignPackage}
            disabled={!isFormValid || isLoading}
            className={`px-4 py-2 bg-darkGreen text-white rounded-md hover:bg-darkGreen-light ${!isFormValid || isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
          >
            {isLoading ? 'Assigning...' : 'Assign Package'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AssignServicePackageModal;
