import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { FaEye } from 'react-icons/fa';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import DistributorLayout from './DistributorLayout';
import AddCustomerModal from './AddCustomerModal';
import { getDistributorCustomers } from '../services/DistributorService';

type PurchaseStatus = 'to_be_purchased' | 'purchased';

const purchaseStatusStyles: Record<PurchaseStatus, string> = {
  to_be_purchased: 'bg-gray-100 text-gray-800',
  purchased: 'bg-green-100 text-green-800'
};

interface Customer {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  cpapMachine?: {
    model: string;
    purchaseDate: string;
  };
  servicePackage?: {
    name: string;
    startDate: string;
  };
}

const getCPAPStatusBadge = (customer: Customer, formatDate: (date: string) => string) => {
  if (customer.cpapMachine) {
    return (
      <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${purchaseStatusStyles.purchased}`}>
        Purchased: {customer.cpapMachine.model} on {formatDate(customer.cpapMachine.purchaseDate)}
      </span>
    );
  }
  return (
    <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${purchaseStatusStyles.to_be_purchased}`}>
      To be purchased
    </span>
  );
};

const getServicePackageStatusBadge = (customer: Customer, formatDate: (date: string) => string) => {
  if (customer.servicePackage) {
    return (
      <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${purchaseStatusStyles.purchased}`}>
        Purchased: {customer.servicePackage.name} on {formatDate(customer.servicePackage.startDate)}
      </span>
    );
  }
  return (
    <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${purchaseStatusStyles.to_be_purchased}`}>
      To be purchased
    </span>
  );
};

const CustomerList: React.FC = () => {
  const [showAddCustomerModal, setShowAddCustomerModal] = useState(false);
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    fetchCustomers();
  }, []);

  const fetchCustomers = async () => {
    try {
      const distributorId = localStorage.getItem('distributorId');
      if (!distributorId) {
        toast.error('Error: Distributor ID not found. Please login again.');
        return;
      }
      const response = await getDistributorCustomers(distributorId);
      setCustomers(response.customers || []);
    } catch (error) {
      console.error('Error fetching customers:', error);
      toast.error('Failed to fetch customers. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddCustomerClick = () => {
    setShowAddCustomerModal(true);
  };

  const handleCustomerAdded = () => {
    setShowAddCustomerModal(false);
    fetchCustomers(); // Refresh the customer list
    toast.success('Customer added/assigned successfully!');
  };

  // Helper function to format dates
  const formatDateFn = (dateString: string) => {
    return format(new Date(dateString), 'MMM dd, yyyy');
  };

  const filteredCustomers = customers.filter((customer) =>
    customer.firstName.toLowerCase().includes(searchQuery.toLowerCase()) ||
    customer.lastName.toLowerCase().includes(searchQuery.toLowerCase()) ||
    customer.email.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <DistributorLayout>
      <ToastContainer />
      <div className="p-6">
        {/* Row 1: Header and Add Customer Button */}
        <div className="flex justify-between items-center mb-2">
          <h2 className="text-2xl font-bold">Customer List</h2>
          <button
            onClick={handleAddCustomerClick}
            className="bg-darkGreen hover:bg-darkGreen-light text-white px-4 py-2 rounded-md"
          >
            Add Customer
          </button>
        </div>
        {/* Row 2: Full-width Search Bar */}
        <div className="mb-4">
          <input
            type="text"
            placeholder="Search customers..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="border border-gray-300 rounded-lg px-3 py-2 w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>

        <div className="bg-white rounded-lg shadow-md p-4">
          {isLoading ? (
            <div className="text-center py-4">Loading customers...</div>
          ) : filteredCustomers.length === 0 ? (
            <p className="text-center py-4">No customers found.</p>
          ) : (
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Name
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    CPAP Device
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Service Package
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {filteredCustomers.map((customer) => (
                  <tr key={customer.id} className="hover:bg-gray-50">
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">
                        {customer.firstName} {customer.lastName}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {getCPAPStatusBadge(customer, formatDateFn)}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {getServicePackageStatusBadge(customer, formatDateFn)}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <Link
                        to={`/distributor/customers/${customer.id}`}
                        className="text-gray-600 hover:text-darkGreen transition-colors"
                        title="View Details"
                      >
                        <FaEye className="w-5 h-5" />
                        <span className="sr-only">View Details</span>
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>

      {showAddCustomerModal && (
        <AddCustomerModal
          onClose={() => setShowAddCustomerModal(false)}
          onCustomerAdded={handleCustomerAdded}
        />
      )}
    </DistributorLayout>
  );
};

export default CustomerList;