// src/api/profileApi.ts
import axiosInstance from '../AxiosInstance';
import { UserProfileType } from '../../features/user/profile/types/UserProfileTypes';

export const fetchProfileData = async (userId: string, token: string) => {
    return axiosInstance.get(`/user/${userId}/user-details`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

export const updateProfile = async (userId: string, profileData: UserProfileType, token: string) => {
    return axiosInstance.put(`/user/update/${userId}`, profileData, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};