// features/admin/components/UserModal.tsx
import React, { useState } from 'react';
import { Admin } from '../types/AdminUserTypes';
import { createUser } from '../services/AdminUserService';
import axios from 'axios';

interface AdminUserModalProps {
    user?: Admin;
    onClose: () => void;
}

const AdminUserModal: React.FC<AdminUserModalProps> = ({ user, onClose }) => {
    const [formData, setFormData] = useState<Admin>({
        id: '',
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        // Add other necessary fields like password, etc.
    });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    };


    const handleCreatePatient = async () => {
        try {
            const response = await createUser(formData);
            const newPatientId = response.data._id;
            await axios.post('/api/doctorPatient/assign', {
                doctorId: user?.id,
                patientId: newPatientId,
            });
            alert('Patient Created and Assigned Successfully');
            onClose();
        } catch (err) {
            console.error('Failed to create and assign the new patient', err);
            alert("Failed to create patient. Please try again.");
        }
    };

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setFormData(prev => ({ ...prev, email: value, firstName: '', lastName: '', phoneNumber: '' })); // Clear other fields
    };



    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white p-6 rounded shadow-md w-96">
                <h2 className="text-xl font-bold mb-4">{user ? 'Edit User' : 'Create New User'}</h2>

                {/* Email Input */}
                <div className="mb-4">
                    <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email:</label>
                    <input
                        type="email"
                        name="email"
                        id="email"
                        value={formData.email}
                        onChange={handleEmailChange}
                        className="mt-1 p-2 border rounded-md w-full"
                        required
                    />
                </div>

                {/* First Name */}
                <div className="mb-4">
                    <label htmlFor="firstName" className="block text-sm font-medium text-gray-700">First Name:</label>
                    <input
                        type="text"
                        name="firstName"
                        id="firstName"
                        value={formData.firstName}
                        onChange={handleChange}
                        className="mt-1 p-2 border rounded-md w-full"
                        required
                    />
                </div>

                {/* Last Name */}
                <div className="mb-4">
                    <label htmlFor="lastName" className="block text-sm font-medium text-gray-700">Last Name:</label>
                    <input
                        type="text"
                        name="lastName"
                        id="lastName"
                        value={formData.lastName}
                        onChange={handleChange}
                        className="mt-1 p-2 border rounded-md w-full"
                        required
                    />
                </div>

                {/* Phone Number */}
                <div className="mb-4">
                    <label htmlFor="phoneNumber" className="block text-sm font-medium text-gray-700">Phone Number:</label>
                    <input
                        type="text"
                        name="phoneNumber"
                        id="phoneNumber"
                        value={formData.phoneNumber}
                        onChange={handleChange}
                        className="mt-1 p-2 border rounded-md w-full"
                        required
                    />
                </div>

                <div className="flex justify-end space-x-2">
                    <button
                        onClick={handleCreatePatient}
                        className="bg-blue-500 text-white px-4 py-2 rounded"
                    >
                        {user ? 'Update' : 'Create'}
                    </button>
                    <button
                        onClick={onClose}
                        className="bg-gray-500 text-white px-4 py-2 rounded"
                    >
                        Cancel
                    </button>
                </div>

            </div>
        </div>
    );
};

export default AdminUserModal;