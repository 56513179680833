// features/distributor/components/DistributorLayout.tsx
import React from 'react';
import DistributorSidebar from '../DistributorSidebar';


interface DistributorLayoutProps {
    children: React.ReactNode;
}

const DistributorLayout: React.FC<DistributorLayoutProps> = ({ children }) => {
    return (
        <div className="flex h-screen bg-gray-100 font-sans">
            <DistributorSidebar />
            <div className="flex-1 overflow-y-auto p-8">
                {children}
            </div>
        </div>
    );
};

export default DistributorLayout;