import React from 'react';
import UserSidebar from '../../UserSidebar';

const UserFAQ: React.FC = () => {
    // Sample FAQ data - Replace with your actual data!
    const faqData = [
        {
            question: "What is sleep apnea?",
            answer: "Sleep apnea is a common sleep disorder characterized by repeated interruptions in breathing during sleep."
        },
        {
            question: "What are the common symptoms of sleep apnea?",
            answer: "Common symptoms include loud snoring, gasping for air during sleep, daytime fatigue, and morning headaches."
        },
        {
            question: "How is sleep apnea diagnosed?",
            answer: "Sleep apnea is typically diagnosed through a sleep study, either at home or in a sleep center."
        },
        {
          question: "What are the treatment options for sleep apnea?",
          answer: "Treatment options include lifestyle changes, CPAP therapy, oral appliances, and in some cases, surgery."
        },
        {
          question: "How can I schedule a consultation with a doctor?",
          answer: "You can schedule a consultation through the 'Consult' section of your user dashboard."
        },

    ];

  return (
    <div className="flex h-screen bg-gradient-to-br from-blue-50 to-indigo-100 font-sans">
      <UserSidebar />
      <div className="flex-1 flex items-center justify-center overflow-y-auto p-8">
        <div className="bg-white shadow-md rounded-lg p-6 max-w-3xl w-full">
            <h1 className="text-3xl font-bold mb-6 text-center">Frequently Asked Questions</h1>
            <div className="space-y-4">
              {faqData.map((faq, index) => (
                <div key={index} className="border-b pb-4">
                  <h2 className="text-xl font-semibold text-gray-800">{faq.question}</h2>
                  <p className="text-gray-600 mt-2">{faq.answer}</p>
                </div>
              ))}
            </div>
        </div>
      </div>
    </div>
  );
};

export default UserFAQ;