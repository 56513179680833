// src/features/blogs/components/BlogDetail.tsx
import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
// Remove dynamic import logic:
// import blogEntries from './BlogEntries.json';
// import { lazy, Suspense } from 'react';
import { BlogPost } from '../types/BlogTypes'; // Import updated type
import { fetchBlogPostBySlug } from '../services/BlogService'; // Import updated service
import { toast } from 'react-toastify'; // Optional for error feedback
import { Helmet } from 'react-helmet'; // For setting title/meta description

// Optional: Import a Markdown renderer if backend stores content as Markdown
// import ReactMarkdown from 'react-markdown';
// import remarkGfm from 'remark-gfm'; // Example plugin for GitHub Flavored Markdown

const BlogDetail: React.FC = () => {
  const { slug } = useParams<{ slug: string }>(); // Get slug from URL params
  const [blogPost, setBlogPost] = useState<BlogPost | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  // Fetch blog post details based on the slug
  useEffect(() => {
    const loadBlogPost = async () => {
      if (!slug) {
        setError('Blog slug is missing.');
        setLoading(false);
        return;
      }
      setLoading(true);
      setError(null);
      try {
        const fetchedPost = await fetchBlogPostBySlug(slug);
        setBlogPost(fetchedPost);
      } catch (err: any) {
        console.error(`Error fetching blog post with slug: ${slug}`, err);
        setError('Blog post not found or failed to load.');
        if (err.response?.status === 404) {
             setError('Blog post not found.');
        } else {
             setError('Failed to load blog post. Please try again later.');
             toast.error('Failed to load blog post.'); // Optional
        }
        setBlogPost(null); // Ensure no stale data is shown
      } finally {
        setLoading(false);
      }
    };

    loadBlogPost();
  }, [slug]); // Re-fetch if slug changes

  // Scroll to top on component mount
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Render loading state
  if (loading) {
    return (
      <div className="min-h-screen bg-gray-100 py-20 flex justify-center items-center">
        <div>Loading blog post...</div>
      </div>
    );
  }

  // Render error state
  if (error) {
    return (
      <div className="min-h-screen bg-gray-100 py-20 flex flex-col justify-center items-center px-4">
        <h1 className="text-2xl font-bold text-red-600 mb-4">Error</h1>
        <p className="text-gray-700 mb-6">{error}</p>
        <Link to="/blogs" className="text-blue-600 hover:underline">
          Back to Blogs
        </Link>
      </div>
    );
  }

  // Render "Not Found" state (though error state might cover this)
  if (!blogPost) {
    return (
      <div className="min-h-screen bg-gray-100 py-20 flex flex-col justify-center items-center px-4">
        <h1 className="text-2xl font-bold text-gray-800 mb-4">Blog Post Not Found</h1>
        <p className="text-gray-700 mb-6">Sorry, the blog post you are looking for does not exist.</p>
        <Link to="/blogs" className="text-blue-600 hover:underline">
          Back to Blogs
        </Link>
      </div>
    );
  }

  // Render the blog post content
  return (
    <div className="min-h-screen bg-gray-100 py-20">
      <Helmet>
          {/* Update title and meta description dynamically */}
          <title>{blogPost.metaTitle || blogPost.title} - YourNidra</title>
          {blogPost.metaDescription && (
              <meta name="description" content={blogPost.metaDescription} />
          )}
          {/* Add other relevant meta tags if needed */}
      </Helmet>
      <div className="container mx-auto px-4 max-w-4xl"> {/* Constrain width */}
        <article className="bg-white shadow-md rounded-lg overflow-hidden">
          {/* Optional Featured Image */}
          {blogPost.featuredImage && (
            <img
              src={blogPost.featuredImage}
              alt={blogPost.title}
              className="w-full h-64 sm:h-80 md:h-96 object-cover" // Responsive height
            />
          )}

          <div className="p-6 md:p-8 lg:p-10"> {/* Responsive padding */}
            {/* Meta Information (Date, Author) */}
            <div className="text-sm text-gray-500 mb-6 flex items-center space-x-4">
              {blogPost.publishedDate && (
                <span>Published on {new Date(blogPost.publishedDate).toLocaleDateString()}</span>
              )}
              {blogPost.author && (
                <span>by {blogPost.author}</span>
              )}
            </div>

            <div
              className="prose prose-lg max-w-none"
              dangerouslySetInnerHTML={{ __html: blogPost.content }}
            />

            {/* Optional: Tags/Categories */}
            {blogPost.tags && (
              <div className="mt-6">
                <h3 className="text-lg font-semibold mb-2">Tags</h3>
                <div className="flex flex-wrap gap-2">
                  {blogPost.tags.map((tag) => (
                    <span key={tag} className="bg-gray-100 px-3 py-1 rounded-full text-sm">
                        {tag}
                    </span>
                  ))}
                </div>
              </div>
            )}

            {/* Back to Blogs Link */}
            <div className="mt-8 pt-6 border-t border-gray-200">
                 <Link to="/blogs" className="text-blue-600 hover:underline">
                    ← Back to Blogs
                 </Link>
            </div>

          </div>
        </article>
      </div>
    </div>
  );
};

export default BlogDetail;