import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import Navbar from './shared/Navbar';
import Hero from './home/Hero';
import Testimonials from './home/Testimonials';
import Footer from './shared/Footer';
import Symptoms from './home/Symptoms';
import Diagnosis from './home/Diagnosis';
import About from './home/About';
import Quiz from './pages/Quiz';
import Blogs from './features/blogs/components/Blogs';
import BlogDetail from './features/blogs/components/BlogDetail';
import BlogsCarousel from './home/BlogsCarousel';
import BlankPage from './pages/BlankPage';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfUse from './pages/TermsOfUse';
import CreateMultipleUsers from './features/admin/components/CreateMultipleUsers';
import UserDashboard from './features/user/dashboard/components/UserDashboard';
import UserLogout from './features/auth/user/UserLogout';
import UserConsultation from './features/user/consultation/components/UserConsultation';
import UserFAQ from './features/user/faq/components/UserFAQ';
import UserProfile from './features/user/profile/components/UserProfile';
import UserQuestionnaire from './features/user/questionnaire/components/UserQuestionnaire';
import UserReferral from './features/user/referral/components/UserReferral';
import UserSleepStudy from './features/user/sleepStudy/components/UserSleepStudy';
import UserDevice from './features/user/device/components/UserDevice';
import FullQuiz from './pages/fullQuiz';
import UserLogin from './features/auth/user/UserLogin';
import UserRegister from './features/auth/user/UserRegister';
import UserForgotPassword from './features/auth/user/UserForgotPassword';
import UserResetPassword from './features/auth/user/UserResetPassword';
import CompanyLogin from './features/auth/company/CompanyLogin';
import CompanyRegister from './features/auth/company/CompanyRegister';
import CompanyForgotPassword from './features/auth/company/CompanyForgotPassword';
import CompanyResetPassword from './features/auth/company/CompanyResetPassword';
import CompanyDashboard from './features/company/components/CompanyDashboard';
import CompanyReports from './features/company/components/CompanyReports';
import CompanySettings from './features/company/components/CompanySettings';
import CompanyLogout from './features/company/components/CompanyLogout';
import DoctorLogin from './features/auth/doctor/DoctorLogin';
import DoctorRegister from './features/auth/doctor/DoctorRegister';
import DoctorResetPassword from './features/auth/doctor/DoctorResetPassword';
import DoctorForgotPassword from './features/auth/doctor/DoctorForgotPassword';
import DoctorDashboard from './features/doctor/components/DoctorDashboard';
import DoctorVerifyOTP from './features/auth/doctor/DoctorVerifyOTP';
import DoctorSchedule from './features/doctor/components/DoctorSchedule';
import DoctorLogout from './features/auth/doctor/DoctorLogout';
import DoctorPatientList from './features/doctor/components/DoctorPatientList';
import DoctorPatientRecord from './features/doctor/components/DoctorPatientRecord';
import DoctorProfile from './features/doctor/components/DoctorProfile';
import DoctorHospitals from './features/doctor/components/DoctorHospitals';
import SleepCenterDashboard from './features/sleepCenter/components/SleepCenterDashboard';
import SleepCenterRegister from './features/auth/sleepCenter/SleepCenterRegister';
import SleepCenterLogout from './features/auth/sleepCenter/SleepCenterLogout';
import SleepCenterLogin from './features/auth/sleepCenter/SleepCenterLogin';
import UploadSleepStudyReport from './features/sleepCenter/components/UploadSleepStudyReport';
import SleepCenterForgotPassword from './features/auth/sleepCenter/SleepCenterForgotPassword';
import SleepCenterOfferings from './features/sleepCenter/components/SleepCenterOfferings';
import SleepCenterPatients from './features/sleepCenter/components/SleepCenterPatients';
import SleepCenterProfile from './features/sleepCenter/components/SleepCenterProfile';
import SleepCenterStudies from './features/sleepCenter/components/SleepCenterStudies';
import SleepCenterPatientRecord from './features/sleepCenter/components/SleepCenterPatientRecord';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DynamicCanonical from './DynamicCanonical';
import StructuredData from './StructuredData';
import DistributorLogin from './features/auth/distributor/DistributorLogin';
import DistributorForgotPassword from './features/auth/distributor/DistributorForgotPassword';
import DistributorLogout from './features/auth/distributor/DistributorLogout';
import DistributorResetPassword from './features/auth/distributor/DistributorResetPassword';
import OrderList from './features/distributor/components/OrderList';
import OrderDetail from './features/distributor/components/OrderDetail';
import DistributorDashboard from './features/distributor/components/DistributorDashboard';
import ProductList from './features/distributor/components/ProductList';
import ProductDetail from './features/distributor/components/ProductDetail';
import ServicePackageList from './features/distributor/components/ServicePackageList';
import CustomerList from './features/distributor/components/CustomerList';
import CustomerDetail from './features/distributor/components/CustomerDetail';
import AddServicePackagePage from './features/distributor/components/AddServicePackagePage';
import ViewServicePackagePage from './features/distributor/components/ViewServicePackage';
import ManageServicePackageOrders from './features/distributor/components/ManageServicePackageOrders';
import AdminBlogCreate from './features/admin/components/AdminBlogCreate';
import AdminDashboard from './features/admin/components/AdminDashboard';
import AdminUserManagement from './features/admin/components/AdminUserManagement';

const Layout: React.FC<{ children: React.ReactNode }> = ({ children }) => (
    <div className="max-w-7xl mx-auto lg:px-8 py-16">
        {children}
    </div>
);

const Home: React.FC = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Layout>
            <Hero />
            <Symptoms />
            <About />
            <Diagnosis />
            <BlogsCarousel />
            <Testimonials />
        </Layout>
    );
};

const BlogPage: React.FC = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Layout>
            <Blogs />
        </Layout>
    );
};

const BlogDetailPage: React.FC = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Layout>
            <BlogDetail />
        </Layout>
    );
};

// A higher-order component to protect routes
const ProtectedRoute: React.FC<{
    element: React.ReactElement,
    requiredRole?: 'user' | 'doctor' | 'sleepCenter' | 'distributor' | 'admin' | 'company' // Added admin and company
}> = ({ element, requiredRole = 'user' }) => {
    const isUserAuthenticated = localStorage.getItem('isUserLoggedIn') === 'true';
    const isDoctorAuthenticated = localStorage.getItem('isDoctorLoggedIn') === 'true';
    const isSleepCenterAuthenticated = localStorage.getItem('isSleepCenterLoggedIn') === 'true';
    const isDistributorAuthenticated = localStorage.getItem('isDistributorLoggedIn') === 'true';
    const isAdminAuthenticated = localStorage.getItem('isAdminLoggedIn') === 'true'; // Check for admin
    const isCompanyAuthenticated = localStorage.getItem('isCompanyLoggedIn') === 'true'; // Check for company

    if (requiredRole === 'doctor') {
        return isDoctorAuthenticated ? element : <Navigate to="/auth/doctor/login" />;
    }
    if (requiredRole === 'sleepCenter') {
        return isSleepCenterAuthenticated ? element : <Navigate to="/auth/sleep-center/login" />;
    }
    if (requiredRole === 'distributor') {
        return isDistributorAuthenticated ? element : <Navigate to="/auth/distributor/login" />;
    }
    if (requiredRole === 'admin') { // Added admin check
        return isAdminAuthenticated ? element : <Navigate to="/auth/admin/login" />;
    }
    if (requiredRole === 'company') { // Added company check
        return isCompanyAuthenticated ? element : <Navigate to="/auth/company/login" />;
    }

    // Default to user check
    return isUserAuthenticated ? element : <Navigate to="/auth/user/login" />;
};


const App: React.FC = () => {
    const location = useLocation();

    useEffect(() => {
        const path = location.pathname;

        // Define a mapping of routes to titles and descriptions
        // Use pattern keys for dynamic routes
        const routeData: Record<string, { title: string; description: string }> = {
            "/": { title: "YourNidra - Platform for Sleep Apnea Diagnosis and Treatment", description: "Discover if you're at risk of sleep apnea and get the help you need. Take our free quiz, schedule consultations, and find treatment options." },
            "/quiz": { title: "YourNidra - Sleep Apnea Quiz", description: "Take our free 2-minute quiz to assess your risk of sleep apnea." },
            "/shell/quiz": { title: "YourNidra - Full Sleep Apnea Quiz", description: "Take our full sleep apnea quiz for a comprehensive assessment." },
            "/blogs": { title: "YourNidra - Sleep Apnea Blog", description: "Read articles and learn more about sleep apnea symptoms, diagnosis, and treatment." },
            "/blog/:slug": { title: "YourNidra - Blog", description: "Read our latest blog post about sleep apnea." }, // Use :slug, title set dynamically in BlogDetail
            "/blank-page": { title: "YourNidra - Blank Page", description: "This is a blank page." },
            "/privacy-policy": { title: "YourNidra - Privacy Policy", description: "Read our privacy policy to understand how we handle your personal information." },
            "/terms-of-use": { title: "YourNidra - Terms of Use", description: "Read our terms of use agreement." },
            "/admin/dashboard": { title: "Admin Dashboard", description: "Manage YourNidra application." },
            "/admin/manage-users": { title: "Manage Users", description: "View and manage admin users." },
            "/admin/blogs/create-blog": { title: "Create Blog Post", description: "Admin panel to create a new blog post." },
            "/admin/blogs/edit/:id": { title: "Edit Blog Post", description: "Admin panel to edit a blog post." },
            "/admin/settings": { title: "Admin Settings", description: "Configure admin settings." },
            "/admin/logout": { title: "Admin Logout", description: "Log out from admin panel." },
            "/auth/user/login": { title: "YourNidra - User Login", description: "Log in to your YourNidra user account." },
            "/auth/user/register": { title: "YourNidra - User Registration", description: "Register for a YourNidra user account." },
            "/auth/user/forgot-password": { title: "YourNidra - Forgot Password", description: "Reset your YourNidra user account password." },
            "/auth/user/reset-password/:token": { title: "YourNidra - Reset Password", description: "Reset your YourNidra user account password." },
            "/auth/user/logout": { title: "YourNidra - User Logout", description: "Log out of your YourNidra user account." },
            "/user/dashboard": { title: "YourNidra - User Dashboard", description: "View your sleep apnea assessment progress and manage your account." },
            "/user/profile": { title: "YourNidra - User Profile", description: "Manage your YourNidra user profile." },
            "/user/questionnaire": { title: "YourNidra - Self Assessment", description: "View your self-assessment quiz results." },
            "/user/sleep-study": { title: "YourNidra - Sleep Study", description: "View your sleep study information." },
            "/user/consult": { title: "YourNidra - Consultations", description: "Manage your doctor consultations." },
            "/user/device": { title: "YourNidra - Devices", description: "Manage your CPAP devices." },
            "/user/referral": { title: "YourNidra - Referrals", description: "Refer a friend to YourNidra." },
            "/user/faq": { title: "YourNidra - FAQ", description: "Frequently asked questions about YourNidra and sleep apnea." },
            "/auth/company/login": { title: "YourNidra - Company Login", description: "Log in to your YourNidra company account." },
            "/auth/company/register": { title: "YourNidra - Company Registration", description: "Register your company with YourNidra." },
            "/auth/company/forgot-password": { title: "YourNidra - Company Forgot Password", description: "Reset your company account password." },
            "/auth/company/reset-password/:token": { title: "YourNidra - Company Reset Password", description: "Reset your company account password." },
            "/company/dashboard": { title: "YourNidra - Company Dashboard", description: "View company-wide sleep apnea data and insights." },
            "/company/reports": { title: "YourNidra - Company Reports", description: "View company reports." },
            "/company/settings": { title: "YourNidra - Company Settings", description: "Manage your company settings." },
            "/company/logout": { title: "YourNidra - Company Logout", description: "Logout of company account" },
            "/auth/doctor/login": { title: "YourNidra - Doctor Login", description: "Log in to your YourNidra doctor account." },
            "/auth/doctor/register": { title: "YourNidra - Doctor Registration", description: "Register for a YourNidra doctor account." },
            "/auth/doctor/verify-otp": { title: "YourNidra - Doctor OTP Verification", description: "Verify your OTP for doctor registration." },
            "/auth/doctor/forgot-password": { title: "YourNidra - Doctor Forgot Password", description: "Reset your YourNidra doctor account password." },
            "/auth/doctor/reset-password/:token": { title: "YourNidra - Doctor Reset Password", description: "Reset your YourNidra doctor account password." },
            "/auth/doctor/logout": { title: "YourNidra - Doctor Logout", description: "Logout of doctor account" },
            "/doctor/dashboard": { title: "YourNidra - Doctor Dashboard", description: "View your doctor dashboard." },
            "/doctor/manage-patients": { title: "YourNidra - Manage Patients", description: "Manage your patients." },
            "/doctor/schedule": { title: "YourNidra - Doctor Schedule", description: "Manage your appointment schedule." },
            "/doctor/patient/:patientId": { title: "YourNidra - Patient Record", description: "View patient records." },
            "/doctor/profile": { title: "YourNidra - Doctor Profile", description: "Manage your doctor profile." },
            "/doctor/manage-hospitals": { title: "YourNidra - Manage Hospitals", description: "Manage your hospital affiliations." },
            "/auth/sleep-center/login": { title: "YourNidra - Sleep Center Login", description: "Log in to your sleep center account." },
            "/auth/sleep-center/register": { title: "YourNidra - Sleep Center Registration", description: "Register your sleep center with YourNidra." },
            "/auth/sleep-center/logout": { title: "YourNidra - Sleep Center Logout", description: "logout of your sleep center account" },
            "/auth/sleep-center/forgot-password": { title: "YourNidra - Sleep Center Forgot Password", description: "Reset your sleep center account password." },
            "/sleep-center/dashboard": { title: "YourNidra - Sleep Center Dashboard", description: "View your sleep center dashboard." },
            "/sleep-center/upload/:studyId": { title: "YourNidra - Upload Sleep Study Report", description: "Upload sleep study reports." },
            "/sleep-center/offerings": { title: "YourNidra - Sleep Center Offerings", description: "Manage your sleep center's offerings." },
            "/sleep-center/patients": { title: "YourNidra - Sleep Center Patients", description: "Manage your sleep center's patients." },
            "/sleep-center/profile": { title: "YourNidra - Sleep Center Profile", description: "Manage your sleep center's profile." },
            "/sleep-center/studies": { title: "YourNidra - Sleep Center Studies", description: "Manage sleep studies." },
            "/sleep-center/patient/:patientId": { title: "YourNidra - Sleep Center Patient Record", description: "View patient records." },
            "/auth/distributor/login": { title: "YourNidra - Distributor Login", description: "Log in to your YourNidra distributor account." },
            "/auth/distributor/forgot-password": { title: "YourNidra - Distributor Forgot Password", description: "Reset your YourNidra distributor account password." },
            "/auth/distributor/reset-password/:token": { title: "YourNidra - Distributor Reset Password", description: "Reset your YourNidra distributor account password." },
            "/auth/distributor/logout": { title: "YourNidra - Distributor Logout", description: "Logout of your YourNidra distributor account." },
            "/distributor/dashboard": { title: "YourNidra - Distributor Dashboard", description: "Manage your distributor operations." },
            "/distributor/orders": { title: "YourNidra - Manage Orders", description: "View and manage distributor orders." },
            "/distributor/orders/:orderId": { title: "YourNidra - Order Details", description: "View details for a specific order." },
            "/distributor/products": { title: "YourNidra - Manage Products", description: "View and manage products." },
            "/distributor/products/:productId": { title: "YourNidra - Product Details", description: "View details for a specific product." },
            "/distributor/service-packages": { title: "YourNidra - Manage Service Packages", description: "View and manage service packages." },
            "/distributor/service-packages/view/:packageId": { title: "YourNidra - View Service Package", description: "View details for a service package." },
            "/distributor/customers": { title: "YourNidra - Manage Customers", description: "View and manage customers." },
            "/distributor/customers/:customerId": { title: "YourNidra - Customer Details", description: "View details for a specific customer." },
            "/distributor/add-service-package": { title: "YourNidra - Add Service Package", description: "Create a new service package." },
            "/distributor/service-packages/orders": { title: "YourNidra - Manage Service Orders", description: "Manage service package orders." },
            // Add more routes here
        };

        // Function to find the matching route pattern key
        const findRouteKey = (currentPath: string): string => {
             // Exact match first
             if (routeData[currentPath]) {
                return currentPath;
             }
             // Check dynamic patterns
             const dynamicPatterns = Object.keys(routeData).filter(key => key.includes(':'));
             for (const pattern of dynamicPatterns) {
                const regex = new RegExp(`^${pattern.replace(/:\w+/g, '[^/]+')}$`);
                if (regex.test(currentPath)) {
                    return pattern;
                }
             }
             if (currentPath.startsWith('/admin/blogs/create-blog')) return "/admin/blogs/create-blog";
             if (currentPath.startsWith('/admin/blogs/edit/:id')) return "/admin/blogs/edit/:id";
             // Fallback to homepage key
             return "/";
        };

        const routeKey = findRouteKey(path);
        const routeInfo = routeData[routeKey] || { title: "YourNidra", description: "YourNidra - Sleep Apnea Diagnosis and Treatment" };

        // Set Title - Don't override if BlogDetail will set it
        if (!path.startsWith('/blog/')) {
            document.title = routeInfo.title;
        }

        // Set Meta Description
        let metaDescription = document.querySelector('meta[name="description"]');
        if (!metaDescription) {
            metaDescription = document.createElement('meta');
            metaDescription.setAttribute('name', 'description');
            document.getElementsByTagName('head')[0].appendChild(metaDescription);
        }
        metaDescription.setAttribute('content', routeInfo.description);

    }, [location]);

    return (
        <div className="font-sans">
            {/* Insert the dynamic canonical component */}
            <DynamicCanonical />
            {/* Structured Data Component */}
            <StructuredData />
            <Navbar />
            {/* Removed outer Layout component - Individual pages/sections manage their layout */}
            {/* Apply Layout only to non-dashboard routes if needed */}
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/quiz" element={<Quiz />} />
                <Route path="/shell/quiz" element={<FullQuiz />} />
                <Route path="/blogs" element={<BlogPage />} />
                <Route path="/blog/:slug" element={<BlogDetailPage />} /> {/* Updated to :slug */}
                <Route path="/blank-page" element={<BlankPage />} />
                <Route path="/privacy-policy" element={<Layout><PrivacyPolicy /></Layout>} /> {/* Wrap static pages if needed */}
                <Route path="/terms-of-use" element={<Layout><TermsOfUse /></Layout>} /> {/* Wrap static pages if needed */}

                {/* Admin routes*/}
                {/* Wrap admin routes with ProtectedRoute and potentially an AdminLayout */}
                <Route path="/admin/dashboard" element={<ProtectedRoute element={<AdminDashboard />} requiredRole="admin" />} />
                <Route path="/admin/manage-users" element={<ProtectedRoute element={<AdminUserManagement />} requiredRole="admin" />} />
                {/* --- Add the new route --- */}
                <Route path="/admin/blogs/create-blog" element={<ProtectedRoute element={<AdminBlogCreate />} requiredRole="admin" />} />
                {/* -------------------------- */}

                {/* Routes for user authentication */}
                <Route path="/auth/user/login" element={<UserLogin />} />
                <Route path="/auth/user/register" element={<UserRegister />} />
                <Route path="/auth/user/forgot-password" element={<UserForgotPassword />} />
                <Route path="/auth/user/reset-password/:token" element={<UserResetPassword />} />
                <Route path="/auth/user/logout" element={<UserLogout />} />

                {/* User Protected routes */}
                {/* User routes likely use their own layout via UserDashboard etc. */}
                <Route path="/user/dashboard" element={<ProtectedRoute element={<UserDashboard />} requiredRole="user" />} />
                <Route path="/user/profile" element={<ProtectedRoute element={<UserProfile />} requiredRole="user" />} />
                <Route path="/user/questionnaire" element={<ProtectedRoute element={<UserQuestionnaire />} requiredRole="user" />} />
                <Route path="/user/sleep-study" element={<ProtectedRoute element={<UserSleepStudy />} requiredRole="user" />} />
                <Route path="/user/consult" element={<ProtectedRoute element={<UserConsultation />} requiredRole="user" />} />
                <Route path="/user/device" element={<ProtectedRoute element={<UserDevice />} requiredRole="user" />} />
                <Route path="/user/referral" element={<ProtectedRoute element={<UserReferral />} requiredRole="user" />} />
                <Route path="/user/faq" element={<ProtectedRoute element={<UserFAQ />} requiredRole="user" />} />

                {/* Routes for company authentication */}
                <Route path="/auth/company/login" element={<CompanyLogin />} />
                <Route path="/auth/company/register" element={<CompanyRegister />} />
                <Route path="/auth/company/forgot-password" element={<CompanyForgotPassword />} />
                <Route path="/auth/company/reset-password/:token" element={<CompanyResetPassword />} />

                {/* Company Protected routes */}
                <Route path="/company/dashboard" element={<ProtectedRoute element={<CompanyDashboard />} requiredRole="company" />} />
                <Route path="/company/reports" element={<ProtectedRoute element={<CompanyReports />} requiredRole="company" />} />
                <Route path="/company/settings" element={<ProtectedRoute element={<CompanySettings />} requiredRole="company" />} />
                <Route path="/company/logout" element={<CompanyLogout />} />

                {/* Routes for doctor authentication */}
                <Route path="/auth/doctor/login" element={<DoctorLogin />} />
                <Route path="/auth/doctor/register" element={<DoctorRegister />} />
                <Route path="/auth/doctor/verify-otp" element={<DoctorVerifyOTP />} />
                <Route path="/auth/doctor/forgot-password" element={<DoctorForgotPassword />} />
                <Route path="/auth/doctor/reset-password/:token" element={<DoctorResetPassword />} />
                <Route path="/auth/doctor/logout" element={<DoctorLogout />} />

                {/* Doctor Protected routes */}
                <Route path="/doctor/dashboard" element={<ProtectedRoute element={<DoctorDashboard />} requiredRole="doctor" />} />
                <Route path="/doctor/manage-patients" element={<ProtectedRoute element={<DoctorPatientList />} requiredRole="doctor" />} />
                <Route path="/doctor/schedule" element={<ProtectedRoute element={<DoctorSchedule />} requiredRole="doctor" />} />
                <Route path="/doctor/patient/:patientId" element={<ProtectedRoute element={<DoctorPatientRecord />} requiredRole="doctor" />} />
                <Route path="/doctor/profile" element={<ProtectedRoute element={<DoctorProfile />} requiredRole="doctor" />} />
                <Route path="/doctor/manage-hospitals" element={<ProtectedRoute element={<DoctorHospitals />} requiredRole="doctor" />} />

                {/* Routes for sleep center authentication */}
                <Route path="/auth/sleep-center/login" element={<SleepCenterLogin />} />
                <Route path="/auth/sleep-center/register" element={<SleepCenterRegister />} />
                <Route path="/auth/sleep-center/logout" element={<SleepCenterLogout />} />
                <Route path="/auth/sleep-center/forgot-password" element={<SleepCenterForgotPassword />} />

                {/* Sleep Center Protected routes */}
                <Route path="/sleep-center/dashboard" element={<ProtectedRoute element={<SleepCenterDashboard />} requiredRole="sleepCenter" />} />
                <Route path="/sleep-center/upload/:studyId" element={<ProtectedRoute element={<UploadSleepStudyReport />} requiredRole="sleepCenter" />} />
                <Route path="/sleep-center/offerings" element={<ProtectedRoute element={<SleepCenterOfferings />} requiredRole="sleepCenter" />} />
                <Route path="/sleep-center/patients" element={<ProtectedRoute element={<SleepCenterPatients />} requiredRole="sleepCenter" />} />
                <Route path="/sleep-center/profile" element={<ProtectedRoute element={<SleepCenterProfile />} requiredRole="sleepCenter" />} />
                <Route path="/sleep-center/studies" element={<ProtectedRoute element={<SleepCenterStudies />} requiredRole="sleepCenter" />} />
                <Route path="/sleep-center/patient/:patientId" element={<ProtectedRoute element={<SleepCenterPatientRecord />} requiredRole="sleepCenter" />} />

                {/* Distributor Authentication Routes */}
                <Route path="/auth/distributor/login" element={<DistributorLogin />} />
                <Route path="/auth/distributor/forgot-password" element={<DistributorForgotPassword />} />
                <Route path="/auth/distributor/reset-password/:token" element={<DistributorResetPassword />} />
                <Route path="/auth/distributor/logout" element={<DistributorLogout />} />

                {/* Distributor Protected Routes */}
                <Route path="/distributor/dashboard" element={<ProtectedRoute element={<DistributorDashboard />} requiredRole="distributor" />} />
                <Route path="/distributor/orders" element={<ProtectedRoute element={<OrderList />} requiredRole="distributor" />} />
                <Route path="/distributor/orders/:orderId" element={<ProtectedRoute element={<OrderDetail />} requiredRole="distributor" />} />
                <Route path="/distributor/products" element={<ProtectedRoute element={<ProductList />} requiredRole="distributor" />} />
                <Route path="/distributor/products/:productId" element={<ProtectedRoute element={<ProductDetail />} requiredRole="distributor" />} />
                <Route path="/distributor/service-packages" element={<ProtectedRoute element={<ServicePackageList />} requiredRole="distributor" />} />
                <Route path="/distributor/service-packages/view/:packageId" element={<ProtectedRoute element={<ViewServicePackagePage />} requiredRole="distributor" />} />
                <Route path="/distributor/customers" element={<ProtectedRoute element={<CustomerList />} requiredRole="distributor" />} />
                <Route path="/distributor/customers/:customerId" element={<ProtectedRoute element={<CustomerDetail />} requiredRole="distributor" />} />
                <Route path="/distributor/add-service-package" element={<ProtectedRoute element={<AddServicePackagePage />} requiredRole="distributor" />} />
                <Route path="/distributor/service-packages/orders" element={<ProtectedRoute element={<ManageServicePackageOrders />} requiredRole="distributor" />} />

                {/* Add a catch-all or 404 route if desired */}
                {/* <Route path="*" element={<NotFoundPage />} /> */}
            </Routes>
            <Footer />
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </div>
    );
};

const AppWrapper: React.FC = () => (
    <Router>
        <App />
    </Router>
);

export default AppWrapper;