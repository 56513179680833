import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import { resetPasswordRequest } from '../../doctor/services/DoctorService';
import { DoctorResetPasswordFormData } from '../../doctor/types/DoctorTypes';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import API_URL from '../../../config/config';


const DoctorResetPassword: React.FC = () => {
    const { token } = useParams<{ token: string }>();
    const [formData, setFormData] = useState<DoctorResetPasswordFormData>({ password: '', confirmPassword: '' });
    const [message, setMessage] = useState<string | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);

        // Verify the token on component mount
        const verifyToken = async () => {
            try {
                await axios.get(`${API_URL}/doctor/verify-reset-token/${token}`); // Replace with your actual API endpoint
            } catch (err: any) {
                let message = 'Invalid or expired token.'
                  if(axios.isAxiosError(err) && err.response){
                      message = err.response.data.message || message
                  }
                // Handle invalid or expired token (e.g., redirect to an error page or show an error message)
                  setError(message);
                  toast.error(message);
              }
        };

        if (token) { // only call verifyToken if token exists.
            verifyToken();
        }

    }, [token]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setMessage(null);
        setError(null);
        setIsLoading(true);

        if (formData.password !== formData.confirmPassword) {
            setError('Passwords do not match');
            setIsLoading(false)
            return;
        }

        try {
            const response = await resetPasswordRequest(token!, formData.password);  // Pass the token to the API request

            if (response.status === 200) {
                // Reset successful
                setMessage('Password reset successfully.');
                toast.success('Password reset successfully.');
                setIsLoading(false);

                setTimeout(() => {
                    navigate('/auth/doctor/login');
                }, 2000)

            }
        } catch (error : any) {
            setIsLoading(false);
             let message = 'An error occurred during reset. Please try again.';
            if(axios.isAxiosError(error) && error.response){
                message = error.response.data.message || message
            }
            setError(message);
            toast.error(message);
            console.error('Password reset error:', error);
        }
    };

    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
            <ToastContainer />

            <div className="max-w-md w-full space-y-8">
                <div>
                    <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Reset your password</h2>

                    <p className="mt-2 text-center text-sm text-gray-600">
                        {" "}
                        <Link to="/doctor/login" className="font-medium text-indigo-600 hover:text-indigo-500">
                            Back to Login
                        </Link>
                    </p>
                </div>

                <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
                    <input type="hidden" name="remember" defaultValue="true" />
                    <div className="rounded-md shadow-sm -space-y-px">
                        {/* Password Input */}
                        <div>
                            <label htmlFor="password" className="sr-only">
                                Password
                            </label>
                            <input
                                id="password"
                                name="password"
                                type="password"
                                autoComplete="password" //Use autoComplete password
                                required
                                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                placeholder="Password"
                                value={formData.password}
                                onChange={handleInputChange}
                            />
                        </div>
                        {/* Confirm Password Input */}
                        <div>
                            <label htmlFor="confirm-password" className="sr-only">
                                Confirm Password
                            </label>
                            <input
                                id="confirm-password"
                                name="confirmPassword"
                                type="password"
                                autoComplete="new-password"
                                required
                                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                placeholder="Confirm Password"
                                value={formData.confirmPassword}
                                onChange={handleInputChange}
                            />
                        </div>
                    </div>

                    <div>
                        <button
                            type="submit"
                            className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            disabled={isLoading}
                        >
                            {isLoading ? 'Resetting...' : 'Reset Password'}

                        </button>
                    </div>

                    {error && <p className="text-red-500 text-sm mt-2">{error}</p>}
                    {message && <p className="text-green-500 text-sm mt-2">{message}</p>}
                </form>

            </div>
        </div>
    );
};

export default DoctorResetPassword;