import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Appointment, PatientDetails } from '../types/DoctorTypes';
import {
    fetchPatientDetails,
    getDashboardStats,
    fetchDoctorAllAppointments} from '../services/DoctorService';
import { format } from 'date-fns';
import 'react-calendar/dist/Calendar.css';
import { QuizData } from '../../user/questionnaire/types/userQuizTypes';
import { SleepStudyData } from '../../user/sleepStudy/types/UserSleepStudyTypes';
import DoctorLayout from './DoctorLayout';
import { FaUserInjured, FaHospital, FaArrowRight } from 'react-icons/fa';
import { Link } from 'react-router-dom';

interface DashboardStats {
    totalPatients: number;
    totalHospitals: number;
    pendingAssessments: number;
    recentPatients: Array<{
        _id: string;
        firstName: string;
        lastName: string;
        lastVisit: string;
        riskLevel: string;
    }>;
    upcomingConsultations: Array<{
        _id: string;
        patientName: string;
        date: string;
        time: string;
    }>;
}

const DoctorDashboard: React.FC = () => {
    const [appointments, setAppointments] = useState<Appointment[]>([]);
    const [selectedPatient, setSelectedPatient] = useState<PatientDetails | null>(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<null | string>(null);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [stats, setStats] = useState<DashboardStats>({
        totalPatients: 0,
        totalHospitals: 0,
        pendingAssessments: 0,
        recentPatients: [],
        upcomingConsultations: []
    });
    const navigate = useNavigate();
    const doctorId = localStorage.getItem('doctorId') || '';
    const doctorFirstName = localStorage.getItem('doctorFirstName') || 'Dr. ';
    const doctorLastName = localStorage.getItem('doctorLastName') || ' ';
    const doctorName = `${doctorFirstName} ${doctorLastName}`;

    const fetchDoctorAppointments = useCallback(async () => {
        try {
            setLoading(true);
            const appointmentsData = await fetchDoctorAllAppointments(doctorId);
            setAppointments(appointmentsData);
            setError(null);
        } catch (error) {
            setAppointments([]);
            console.error("Error fetching appointments:", error);
        } finally {
            setLoading(false);
        }
    }, [doctorId]);

    useEffect(() => {
        fetchDoctorAppointments();
    }, [navigate, doctorId, fetchDoctorAppointments]);

    useEffect(() => {
        const fetchStats = async () => {
            try {
                const data = await getDashboardStats(doctorId);
                setStats(data.stats);
            } catch (error) {
                console.error('Error fetching dashboard stats:', error);
            }
        };

        fetchStats();
    }, [doctorId]);

    const handleViewReport = async (userId: string) => {
        try {
            const patientDetails = await fetchPatientDetails(userId);
            setSelectedPatient(patientDetails);
        } catch (error) {
            setError('Failed to load patient details');
            console.error("Error loading patient details:", error);
        }
    };


    const handleClosePatientDetails = () => {
        setSelectedPatient(null);
    };

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;

    const appointmentsForSelectedDate = appointments.filter(appointment => {
        const appointmentDate = new Date(appointment.date);
        return appointmentDate.getFullYear() === selectedDate.getFullYear() &&
            appointmentDate.getMonth() === selectedDate.getMonth() &&
            appointmentDate.getDate() === selectedDate.getDate();
    });

    const renderQuizDetails = (quizzes: QuizData[]) => (
        <div>
            <h3 className="text-lg font-semibold mb-2">Quizzes</h3>
            {quizzes.length > 0 ? (
                <ul className="list-disc pl-5">
                    {quizzes.map(quiz => (
                        <li key={quiz._id} className="mb-2">
                            <p className="text-gray-700">Date: {new Date(quiz.createdAt).toLocaleDateString()}</p>
                            <p className="text-gray-700">Risk Level: {quiz.riskLevel}</p>
                        </li>
                    ))}
                </ul>
            ) : (
                <p className="text-gray-500">No quizzes taken yet.</p>
            )}
        </div>
    );

    const renderSleepStudyDetails = (sleepStudies: SleepStudyData[]) => (
        <div>
            <h3 className="text-lg font-semibold mb-2">Sleep Studies</h3>
            {sleepStudies.length > 0 ? (
                <ul className="list-disc pl-5">
                    {sleepStudies.map(study => (
                        <li key={study.id} className="mb-2">
                            <p className="text-gray-700">Date: {new Date(study.createdAt).toLocaleDateString()}</p>
                            <p className="text-gray-700">Status: {study.status}</p>
                        </li>
                    ))}
                </ul>
            ) : (
                <p className="text-gray-500">No sleep studies yet.</p>
            )}
        </div>
    );

    return (
        <DoctorLayout>
            <div className="flex flex-col h-full bg-gray-100">
                {/* Fixed Header with permanent shadow */}
                <div className="sticky top-0 bg-gray-100 z-10 px-8 py-6">
                    <h2 className="text-3xl font-bold text-gray-800 mb-6">Welcome, Dr. {doctorName}</h2>

                    {/* Stats Cards */}
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
                        <div className="bg-white p-6 rounded-lg shadow-md">
                            <div className="flex items-center justify-between">
                                <div>
                                    <p className="text-gray-500 text-sm">Total Patients</p>
                                    <p className="text-2xl font-bold text-gray-800">{stats.totalPatients}</p>
                                </div>
                                <FaUserInjured className="text-darkGreen text-3xl" />
                            </div>
                            <Link to="/doctor/manage-patients" className="text-darkGreen text-sm hover:underline mt-4 block">
                                View Patients  <FaArrowRight className='inline' />
                            </Link>
                        </div>

                        <div className="bg-white p-6 rounded-lg shadow-md">
                            <div className="flex items-center justify-between">
                                <div>
                                    <p className="text-gray-500 text-sm">Associated Hospitals</p>
                                    <p className="text-2xl font-bold text-gray-800">{stats.totalHospitals}</p>
                                </div>
                                <FaHospital className="text-darkGreen text-3xl" />
                            </div>
                            <Link to="/doctor/manage-hospitals" className="text-darkGreen text-sm hover:underline mt-4 block">
                                View Hospitals <FaArrowRight className='inline' />
                            </Link>
                        </div>
                    </div>

                    {/* Combined Appointments Card */}
                    <div className="bg-white rounded-lg shadow-lg p-6">
                        <div className="flex justify-between items-center mb-6">
                            <h3 className="text-xl font-semibold text-gray-800">Your Appointments</h3>
                            <input
                                type="date"
                                value={format(selectedDate, 'yyyy-MM-dd')}
                                onChange={(e) => setSelectedDate(new Date(e.target.value))}
                                className="border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-darkGreen"
                            />
                        </div>
                        {appointmentsForSelectedDate.length > 0 ? (
                            <div className="space-y-4">
                                {appointmentsForSelectedDate.map((appointment) => (
                                    <div key={appointment._id} className="bg-gray-100 p-4 rounded-lg border-l-8 border-gray-300">
                                        <p className="font-semibold text-gray-700">Appointment Time: {appointment.time}</p>
                                        <div className="flex flex-col lg:flex-row lg:items-center justify-between mt-2">
                                            <p className="text-gray-600">Patient ID: {typeof appointment.userId === 'object' ? `${appointment.userId.firstName} ${appointment.userId.lastName}` : appointment.userId}</p>
                                            <p className="text-gray-600">Status:
                                                <span className={`ml-1 px-2 py-1 rounded-full text-xs font-medium ${
                                                    appointment.status === 'Scheduled' ? 'bg-blue-100 text-blue-800' :
                                                    appointment.status === 'Completed' ? 'bg-green-100 text-green-800' :
                                                    'bg-red-100 text-red-800'
                                                }`}>{appointment.status}</span>
                                            </p>
                                        </div>
                                        <div className="flex justify-end mt-2">
                                            <button
                                                onClick={() => handleViewReport(typeof appointment.userId === 'object' ? appointment.userId._id : appointment.userId)}
                                                className="text-darkGreen hover:text-darkGreen-light"
                                            >
                                                View Report
                                            </button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <p className="text-gray-500 text-center py-4">No appointments scheduled for {format(selectedDate, 'MMMM do, yyyy')}.</p>
                        )}
                    </div>
                </div>

                {/* Patient Details Modal */}
                {selectedPatient && (
                    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                        <div className="bg-white rounded-lg shadow-md p-6 w-full max-w-3xl overflow-y-auto">
                            <div className='flex justify-between items-center mb-4'>
                                <h2 className="text-2xl font-bold text-gray-800">Patient Details - {selectedPatient.firstName} {selectedPatient.lastName}</h2>
                                <button onClick={handleClosePatientDetails} className="text-gray-600 hover:text-gray-800">
                                    <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                </button>
                            </div>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                <div>
                                    <p className="text-gray-700 font-medium"><strong>Email:</strong> {selectedPatient.email}</p>
                                    <p className="text-gray-700 font-medium"><strong>Phone Number:</strong> {selectedPatient.phoneNumber}</p>
                                    <p className="text-gray-700 font-medium"><strong>Date of Birth:</strong> {selectedPatient.dateOfBirth}</p>
                                    <p className="text-gray-700 font-medium"><strong>Gender:</strong> {selectedPatient.gender}</p>
                                    <p className="text-gray-700 font-medium"><strong>Address:</strong> {selectedPatient.address}</p>
                                </div>
                                <div className="md:pl-4">
                                    {renderQuizDetails(selectedPatient.quizData)}
                                    {renderSleepStudyDetails(selectedPatient.sleepStudyData)}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </DoctorLayout>
    );
};

export default DoctorDashboard;