import React from 'react';
import { format } from 'date-fns';
import { QUIZ_QUESTIONS } from '../../shared/QuizQuestions';

interface QuizDetailsModalProps {
    quiz: any;
    onClose: () => void;
}

const QuizDetailsModal: React.FC<QuizDetailsModalProps> = ({ quiz, onClose }) => {
    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white p-6 rounded-lg shadow-xl max-w-2xl w-full max-h-[80vh] overflow-y-auto">
                <div className="flex justify-between items-center mb-4">
                    <h3 className="text-xl font-semibold">Self Assessment Details</h3>
                    <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
                        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>

                <div className="grid grid-cols-2 gap-4 p-4 bg-gray-50 rounded-lg mb-6">
                    <div>
                        <p className="text-sm text-gray-600">Assessment Date</p>
                        <p className="font-medium">{format(new Date(quiz.createdAt), 'MMM dd, yyyy')}</p>
                    </div>
                    <div>
                        <p className="text-sm text-gray-600">Risk Level</p>
                        <p className={`font-medium ${
                            quiz.riskLevel === 'High Risk' ? 'text-red-600' :
                            quiz.riskLevel === 'Medium Risk' ? 'text-yellow-600' :
                            'text-green-600'
                        }`}>
                            {quiz.riskLevel}
                        </p>
                    </div>
                </div>

                <div className="space-y-4">
                    <h4 className="font-semibold text-gray-800">Assessment Responses</h4>
                    {QUIZ_QUESTIONS.map((question, index) => (
                        <div key={index} className="p-4 bg-gray-50 rounded-lg">
                            <p className="font-medium text-gray-800 mb-2">
                                {index + 1}. {question.question}
                            </p>
                            <div className="mt-2">
                                <p className="text-gray-700">
                                    Answer: <span className="font-medium capitalize">{quiz[question.id]}</span>
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default QuizDetailsModal; 