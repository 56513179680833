// features/auth/sleepCenter/SleepCenterLogout.tsx (Logout functionality)
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const SleepCenterLogout: React.FC = () => {
    const navigate = useNavigate();

    useEffect(() => {
        // Clear localStorage
        localStorage.clear();
        
        navigate('/auth/sleep-center/login');
    }, [navigate]);

    return null;
};

export default SleepCenterLogout;